import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { debounce } from "lodash"; // For debouncing API requests
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css"; //Example style, you can use another
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { ExportJsonCsv } from "react-export-json-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  FileCopyOutlined as FileCopyOutlinedIcon,
  Folder,
} from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import { copyTextToClipboard, useDocumentTitle } from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import ErrorBoundary from "../../ErrorBoundary";
import {
  arraysAreEqual,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";
import AddEditSweeper from "./AddEditSweeper";

const TierEnum = Object.freeze({
  HOLD: "hold",
  TIER_1: "tier_1",
  TIER_2: "tier_2",
  NO_TIER: "no_tier",
});

const companyRevenueFilterRanges = Object.freeze({
  MIN: 0,
  MAX: 1000000000000, // tril
});

const employeeFilterRanges = Object.freeze({
  MIN: 0,
  MAX: 10000000, // 10 mil
});

const getBadgeBgFromTier = (tier) => {
  let badgeBg = "light";
  if (tier === TierEnum.NO_TIER) {
    badgeBg = "light";
  } else if (tier === TierEnum.TIER_1) {
    badgeBg = "success";
  } else if (tier === TierEnum.TIER_2) {
    badgeBg = "warning";
  } else if (tier === TierEnum.HOLD) {
    badgeBg = "danger";
  }
  return badgeBg;
};

const getDisplayNameForTier = (tier) => {
  if (tier === TierEnum.NO_TIER) {
    return "No Tier";
  } else if (tier === TierEnum.TIER_1) {
    return "Tier 1";
  } else if (tier === TierEnum.TIER_2) {
    return "Tier 2";
  } else if (tier === TierEnum.HOLD) {
    return "Hold";
  }
  return "Invalid Tier";
};

const ContactTierUpdatePopup = ({
  showPopup,
  setShowPopup,
  sweeper,
  metadata,
  contact,
  position,
  handleUpdateContactTier,
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    if (showPopup && popupRef.current) {
      // Measure the width of the popup and calculate its left position to center it under the badge.
      const popupWidth = popupRef.current.offsetWidth;
      const popupX = position.x - popupWidth / 2;
      popupRef.current.style.left = `${popupX}px`;
      popupRef.current.style.transform = "scale(0)";

      setTimeout(() => {
        popupRef.current.style.transform = "scale(1)";
      }, 100);
    } else if (popupRef.current) {
      popupRef.current.style.transform = "scale(0)";
    }
  }, [showPopup]);

  const handleBadgeClick = (tier) => {
    handleUpdateContactTier(tier);
  };

  const OptionRequestUpdater = () => {
    return (
      <>
        <div className="badge-options">
          {Object.entries(TierEnum).map(([key, tier]) => {
            if (metadata && tier === metadata[contact.id]?.tier) {
              return null; // option already selected
            }
            return (
              <div className="badge-wrapper" key={key}>
                <Badge
                  bg={getBadgeBgFromTier(tier)}
                  className="status-badge hvr-shrink"
                  onClick={() => handleBadgeClick(tier)}
                >
                  {getDisplayNameForTier(tier)}
                </Badge>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        ref={popupRef}
        className="popup-container"
        style={{
          top: position.y + 10,
          left: position.x,
        }}
        onMouseLeave={() => {
          if (popupRef.current) {
            popupRef.current.style.transform = "scale(0)";
          }
          setTimeout(() => {
            setShowPopup(false);
          }, 150);
        }}
      >
        <OptionRequestUpdater />
      </div>
    </>
  );
};

const FilterInputRange = ({
  title,
  filterKey,
  placeholder,
  handleSetFilter,
  filterConfig,
  rangeMin,
  rangeMax,
}) => {
  const [minValue, setMinValue] = useState(
    filterConfig.fields[filterKey]?.valueMin ?? rangeMin
  );
  const [maxValue, setMaxValue] = useState(
    filterConfig.fields[filterKey]?.valueMax ?? rangeMax
  );

  useEffect(() => {
    if (filterConfig && filterConfig.fields[filterKey]) {
      setMinValue(filterConfig.fields[filterKey].valueMin ?? rangeMin);
      setMaxValue(filterConfig.fields[filterKey].valueMax ?? rangeMax);
    }
  }, [filterConfig, filterKey]); // Runs whenever `filterConfig` updates

  const handleSetValue = (value, max) => {
    console.log(`handleSetValue() - value`, value);
    if (isNaN(value) || value === "") return; // Prevent NaN errors
    value = Math.round(value); // Ensure whole numbers

    if (max) {
      value = Math.min(value, rangeMax);
      value = Math.max(value, minValue); // Ensure max is not less than min
      if (value !== maxValue) {
        handleSetFilter(filterKey, value, max); // Only update parent state
      }
    } else {
      value = Math.max(value, rangeMin);
      value = Math.min(value, maxValue); // Ensure min is not greater than max
      if (value !== minValue) {
        handleSetFilter(filterKey, value, max);
      }
    }
  };

  return (
    <div>
      <div className="filter-option">
        <div className="input-group">
          <span className="input-group-text">
            <div className="option-title">Min {title}: </div>{" "}
          </span>
          <input
            step="1"
            type="number"
            value={minValue ?? rangeMin} // Ensures it's never undefined
            className="form-control filter-input"
            onChange={(e) => handleSetValue(Number(e.target.value), false)}
          />
        </div>
      </div>
      <div className="filter-option">
        <div className="input-group">
          <span className="input-group-text">
            <div className="option-title">Max {title}: </div>{" "}
          </span>
          <input
            step="1"
            type="number"
            value={maxValue ?? rangeMax}
            className="form-control filter-input"
            onChange={(e) => handleSetValue(Number(e.target.value), true)}
          />
        </div>
      </div>
      {/* 
      <div className="filter-range-slider">
        <div className="filter-range-progress"></div>
      </div>
      <div className="filter-range-range-input">
        <input
          type="range"
          min="0"
          max="10000000"
          value={minValue}
          className="filter-range-min-range"
          onChange={(e) => handleSetValue(Number(e.target.value), false)}
        />
        <input
          type="range"
          min="0"
          max="10000000"
          value={maxValue}
          className="filter-range-max-range"
          onChange={(e) => handleSetValue(Number(e.target.value), true)}
        />
      </div> */}
    </div>
  );
};

const FilterInputMinMax = ({
  filterKey,
  placeholder,
  handleSetFilter,
  filterConfig,
  max,
}) => {
  let value;

  // max mode
  if (max) {
    value = filterConfig.fields[filterKey].valueMax
      ? filterConfig.fields[filterKey].valueMax
      : "No Max";
  }
  // min mode
  else {
    value = filterConfig.fields[filterKey].valueMin
      ? filterConfig.fields[filterKey].valueMin
      : "No Min";
  }

  return (
    <input
      onChange={(e) => handleSetFilter(filterKey, e.target.value, max)}
      type="text"
      className="form-control mt-1 filter-input"
      placeholder={placeholder}
      value={value}
    ></input>
  );
};

const FilterInput = ({
  filterKey,
  placeholder,
  handleSetFilter,
  filterConfig,
}) => {
  // console.log(`FilterInput - filterConfig`, filterConfig);
  // console.log(`FilterInput - filterKey`, filterKey);
  const value = filterConfig.fields[filterKey].value
    ? filterConfig.fields[filterKey].value
    : "";
  return (
    <input
      onChange={(e) => handleSetFilter(filterKey, e.target.value)}
      type="text"
      className="form-control mt-1 filter-input"
      placeholder={placeholder}
      value={value}
    ></input>
  );
};

const FilterOption = ({
  metaKey,
  filterKey,
  title,
  optionsEnum,
  handleSetFilter,
  filterConfig,
  noTitleCase = false,
}) => {
  // console.log(`FilterOption - filterConfig`, filterConfig);
  // console.log(`FilterOption - filterKey`, filterKey);
  let value;
  if (metaKey) {
    value = filterConfig.metadata[filterKey].value
      ? filterConfig.metadata[filterKey].value
      : "All";
  } else {
    value = filterConfig.fields[filterKey].value
      ? filterConfig.fields[filterKey].value
      : "All";
  }
  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">
          <div className="option-title">{title}: </div>{" "}
        </span>
        <select
          className="form-control rmono"
          onChange={(e) => handleSetFilter(filterKey, e.target.value, metaKey)}
          value={value}
        >
          <option>All</option>
          {Object.entries(optionsEnum).map(([key, val]) => {
            return (
              <option key={key} value={val}>
                {noTitleCase ? val : toTitleCase(val).replaceAll("_", " ")}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

const FilterCheckbox = ({
  title,
  checked,
  handleChecked,
  filterKey,
  metaKey,
}) => {
  // title can be a string or HTML
  const isHTML = (str) => {
    // Simple check for HTML tags
    return /<\/?[a-z][\s\S]*>/i.test(str);
  };
  const renderTitle = () => {
    if (isHTML(title)) {
      return (
        <div
          className="option-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      );
    }

    return <div className="option-title">{title}</div>;
  };

  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">{renderTitle()}</span>
        <div
          className="form-check custom-checkbox checkbox-success check-lg bs_exam_topper"
          // style={{ marginLeft: "4px" }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id={`FilterCheckbox_${title}`}
            required={true}
            checked={checked}
            onChange={(event) =>
              handleChecked(filterKey, event.target.checked, metaKey)
            }
          />
        </div>
      </div>
    </div>
  );
};

const FiltersSection = ({
  filterConfig,
  setFilterConfig,
  bronzeRelationshipEnum,
  handleTitleSearchBtn,
}) => {
  // update the basic filter values (name, email, etc)
  const handleSetFilter = (filterKey, value, metaKey) => {
    if (metaKey) {
      setFilterConfig((prevConfig) => ({
        ...prevConfig,
        metadata: {
          ...prevConfig.metadata,
          [filterKey]: { value },
        },
      }));
    } else {
      setFilterConfig((prevConfig) => ({
        ...prevConfig,
        fields: {
          ...prevConfig.fields,
          [filterKey]: { value },
        },
      }));
    }
  };

  const handleSetFilterMinMax = (filterKey, value, max) => {
    console.log(
      `handleSetFilterMinMax() - filterKey, value, max`,
      filterKey,
      value,
      max
    );
    // max mode - changes valueMax
    if (max) {
      setFilterConfig((prevConfig) => ({
        ...prevConfig,
        fields: {
          ...prevConfig.fields,
          [filterKey]: { valueMax: value },
        },
      }));
    }
    // min mode - changes valueMin
    else {
      setFilterConfig((prevConfig) => ({
        ...prevConfig,
        fields: {
          ...prevConfig.fields,
          [filterKey]: { valueMin: value },
        },
      }));
    }
  };

  const handleSetFiltersEnabled = (event) => {
    const checked = event.target.checked;

    setFilterConfig((prevConfig) => ({
      ...prevConfig,
      enabled: checked,
    }));
  };

  return (
    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
      <div
        className={`table-wrapper ${
          filterConfig.enabled ? "" : "filters-disabled"
        }`}
      >
        {/* <div className="events-owners-subheading">
      Filters
    </div> */}
        <div className="filters-enable-row">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="filterBypassSwitch"
              checked={filterConfig.enabled}
              onChange={(e) => handleSetFiltersEnabled(e)}
            ></input>
            <label
              className="form-check-label enable-filters-label"
              htmlFor="filterBypassSwitch"
            >
              Enable Filters
            </label>
          </div>
        </div>
        <div className="filters-row">
          <FilterInput
            filterKey="fullName"
            placeholder="Name"
            filterConfig={filterConfig}
            handleSetFilter={handleSetFilter}
          />
          <FilterInput
            filterKey="businessEmail"
            placeholder="Email"
            filterConfig={filterConfig}
            handleSetFilter={handleSetFilter}
          />
          {/* <FilterInput
        filterKey="title"
        placeholder="Job Title"
        filters={filters}
        handleSetFilter={handleSetFilter}
      /> */}
          <FilterInput
            filterKey="accountName"
            placeholder="Company"
            filterConfig={filterConfig}
            handleSetFilter={handleSetFilter}
          />
          <FilterInput
            filterKey="companyIndustry"
            placeholder="Industry"
            filterConfig={filterConfig}
            handleSetFilter={handleSetFilter}
          />
          <FilterOption
            title="Bronze Relationship"
            filterKey="bronzeRelationship"
            optionsEnum={bronzeRelationshipEnum}
            filterConfig={filterConfig}
            handleSetFilter={handleSetFilter}
          />
          {/* {allBronzeRelationshipValues
        .map((val) => {
          return (
          );
        })} */}
          {/* <FilterInput
        filterKey="country"
        placeholder="Country"
        filters={filters}
        handleSetFilter={handleSetFilter}
      />
      <FilterInput
        filterKey="referredBy"
        placeholder="Referred By"
        filters={filters}
        handleSetFilter={handleSetFilter}
      /> */}
          {/* {selectedSweeper.options
        .sort((a, b) => a.startTime - b.startTime)
        .map((option) => {
          const { title, optionId } = option;
          return (
            <FilterOption
              title={title}
              filterKey={optionId}
              // optionsEnum={StatusEnum}
              filters={filters}
              handleSetFilter={handleSetFilter}
            />
          );
        })}
      <FilterOption
        title="Attending"
        filterKey="attendanceStatus"
        //   optionsEnum={AttendanceStatusEnum}
        filters={filters}
        handleSetFilter={handleSetFilter}
      /> */}
        </div>
        <div className="filters-row">
          <FilterInputRange
            title="Revenue"
            filterKey="companyRevenue"
            filterConfig={filterConfig}
            handleSetFilter={handleSetFilterMinMax}
            rangeMin={companyRevenueFilterRanges.MIN}
            rangeMax={companyRevenueFilterRanges.MAX}
          />

          <FilterInputRange
            title="Employees"
            filterKey="employees"
            filterConfig={filterConfig}
            handleSetFilter={handleSetFilterMinMax}
            rangeMin={employeeFilterRanges.MIN}
            rangeMax={employeeFilterRanges.MAX}
          />
          <FilterInput
            filterKey="title"
            placeholder="Title"
            filterConfig={filterConfig}
            handleSetFilter={handleSetFilter}
          />
          <div className="filter-btn" onClick={handleTitleSearchBtn}>
            Apply
          </div>
        </div>
        <div className="filters-row">
          <FilterOption
            title="Tier"
            filterKey="tier"
            metaKey={true}
            optionsEnum={TierEnum}
            filterConfig={filterConfig}
            handleSetFilter={handleSetFilter}
          />
          <FilterCheckbox
            title={`<span class="deleted-col">Show Deleted:</span>`}
            checked={filterConfig.metadata.showDeleted.value}
            handleChecked={handleSetFilter}
            filterKey="showDeleted"
            metaKey={true}
          />
          {filterConfig.metadata.showDeleted.value ? (
            <FilterCheckbox
              title={`<span class="deleted-col">Deleted Only:</span>`}
              checked={filterConfig.metadata.showDeletedOnly.value}
              handleChecked={handleSetFilter}
              filterKey="showDeletedOnly"
              metaKey={true}
            />
          ) : null}
          {/* <FilterOption
        title="All Requested Events"
        filterKey="allRequested"
      //   optionsEnum={StatusEnum}
        filters={filters}
        handleSetFilter={handleSetFilter}
      />

      <FilterCheckbox
        title="Approved (for at least one):"
        checked={approvedFilter}
        handleChecked={handleSetApprovedFilter}
      /> */}

          {/* <FilterCheckbox
        title="Confirmation Email Sent:"
        checked={confirmEmailSentFilter}
        handleChecked={handleSetConfirmEmailSentFilter}
      />
      <FilterCheckbox
        title="Denied Email Sent:"
        checked={deniedEmailSentFilter}
        handleChecked={handleSetDeniedEmailSentFilter}
      /> */}
        </div>
        <div className="filters-row">
          {/* <FilterCheckbox
        title="Duplicate Emails Only:"
        checked={duplicatesOnlyFilter}
        handleChecked={handleSetDuplicatesOnlyFilter}
      /> */}
        </div>
      </div>
    </motion.div>
  );
};

const MasterContactsTable = ({
  password,
  employee,
  getSweepers,
  sweepers,
  selectedSweeper,
  setSelectedSweeper,
  contacts,
  metadata,
  setMetadata,
}) => {
  const [selectedSweeperId, setSelectedSweeperId] = useState(null);
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [rsvpConfirmationEmails, setRsvpConfirmationEmails] = useState([]);
  const [rsvpDeniedEmails, setRsvpDeniedEmails] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);

  const [filterConfig, setFilterConfig] = useState(
    selectedSweeper?.filterConfig || {}
  );

  const [filters, setFilters] = useState({});
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [approvedAttendingFilter, setApprovedAttendingFilter] = useState(false);
  const [approvedFilter, setApprovedFilter] = useState(false);
  const [duplicatesOnlyFilter, setDuplicatesOnlyFilter] = useState(false);
  const [uniqueEmailsFilter, setUniqueEmailsFilter] = useState(false);
  const [deletedFilter, setDeletedFilter] = useState(false);
  const [deletedOnlyFilter, setDeletedOnlyFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  console.log(`MASTER EVENTS TABLE`, selectedSweeper);

  let bulkOnlyAffectOptionId = filters?.bulkRequestEvent || "All";
  if (bulkOnlyAffectOptionId === "All") {
    bulkOnlyAffectOptionId = false;
  } else if (bulkOnlyAffectOptionId) {
    // get optionId from title
    if (
      selectedSweeper &&
      selectedSweeper.options &&
      selectedSweeper.options.length
    ) {
      const option = selectedSweeper.options.find(
        (o) =>
          o.title.toLowerCase().trim() ===
          bulkOnlyAffectOptionId.toLowerCase().trim()
      );
      if (!option) {
        console.error(
          `bulkOnlyAffectOptionId: ERROR: could not retrieve optionId from title: ${bulkOnlyAffectOptionId}`
        );
      } else {
        bulkOnlyAffectOptionId = option.optionId;
      }
    }
  }
  console.log(`bulkOnlyAffectOptionId`, bulkOnlyAffectOptionId);

  const handleSetApprovedAttendingFilter = (event) => {
    const checked = event.target.checked;
    setApprovedAttendingFilter(checked);
  };

  const handleSetApprovedFilter = (event) => {
    const checked = event.target.checked;
    setApprovedFilter(checked);
  };

  const handleSetDuplicatesOnlyFilter = (event) => {
    const checked = event.target.checked;
    setDuplicatesOnlyFilter(checked);
  };

  const handleSetUniqueEmailsFilter = (event) => {
    const checked = event.target.checked;
    setUniqueEmailsFilter(checked);
  };

  const handleSetDeletedFilter = (event) => {
    const checked = event.target.checked;
    setDeletedFilter(checked);
  };

  const handleSetDeletedOnlyFilter = (event) => {
    const checked = event.target.checked;
    setDeletedOnlyFilter(checked);
  };

  const getFilteredContacts = () => {
    console.log(`getFilteredContacts() - filterConfig`, filterConfig);
    function isString(s) {
      return typeof s === "string" || s instanceof String;
    }

    // filter the contacts
    let filtered = contacts;

    // deleted filter
    if (filterConfig.metadata.showDeleted.value) {
      // 'Show Deleted' is checked - don't filter them out
    } else {
      // remove deleted contacts
      try {
        filtered = filtered.filter((m) => !metadata[m.id].deleted);
      } catch (error) {
        console.log(error);
      }
    }

    // bypassed
    if (!filterConfig.enabled) {
      return filtered;
    }

    if (filterConfig.fields) {
      for (const filterKey in filterConfig.fields) {
        const filterValue = filterConfig.fields[filterKey].value;
        const filterValueMin = filterConfig.fields[filterKey].valueMin;
        const filterValueMax = filterConfig.fields[filterKey].valueMax;
        if (typeof filterValue === "string" && filterValue.length > 0) {
          if (filterKey === "title") {
            // title filtering
            // search previous searches
            if (filterConfig.fields[filterKey].previousSearches) {
              const prevSearch = filterConfig.fields[
                filterKey
              ].previousSearches.find(
                (s) =>
                  s.value.toLowerCase().trim() ===
                  filterValue.toLowerCase().trim()
              ); // todo: use the other title filter boolean options to match
              if (prevSearch) {
                console.log(
                  `getFilteredContacts() - filterConfig - previous title search found for query: ${filterValue}`,
                  prevSearch
                );
                console.log(`filtered pre`, filtered);
                console.log("prevSearch.results:", prevSearch.results);
                filtered = filtered.filter(
                  (m) =>
                    m.title &&
                    prevSearch.results.includes(m.title.toLowerCase())
                );
                console.log(`filtered post`, filtered);
              }
              // new search - don't do anything - user must click apply button to run search
              else {
              }
            }
          } else {
            // standard fields
            filtered = filtered.filter(
              (m) =>
                m[filterKey] &&
                m[filterKey].toLowerCase().includes(filterValue.toLowerCase())
            );
          }
        }

        if (filterKey === "companyRevenue") {
          if (
            !isNaN(filterValueMin) &&
            filterValueMin > companyRevenueFilterRanges.MIN
          ) {
            // min revenue is set
            filtered = filtered.filter(
              (m) =>
                m[filterKey] &&
                m[filterKey] !== "" && // ignore companies without a revenue
                parseFloat(m[filterKey]) >= filterValueMin
            );
          }
          if (
            !isNaN(filterValueMax) &&
            filterValueMax < companyRevenueFilterRanges.MAX
          ) {
            // min revenue is set
            filtered = filtered.filter(
              (m) =>
                m[filterKey] &&
                m[filterKey] !== "" && // ignore companies without a revenue
                parseFloat(m[filterKey]) <= filterValueMax
            );
          }
        }

        if (filterKey === "employees") {
          if (
            !isNaN(filterValueMin) &&
            filterValueMin > employeeFilterRanges.MIN
          ) {
            // min employees is set
            filtered = filtered.filter(
              (m) =>
                m[filterKey] &&
                m[filterKey] !== "" && // ignore companies without employees
                parseFloat(m[filterKey]) >= filterValueMin
            );
          }
          if (
            !isNaN(filterValueMax) &&
            filterValueMax < employeeFilterRanges.MAX
          ) {
            // min employees is set
            filtered = filtered.filter(
              (m) =>
                m[filterKey] &&
                m[filterKey] !== "" && // ignore companies without employees
                parseFloat(m[filterKey]) <= filterValueMax
            );
          }
        }
      }
      // console.log(
      //   "filterConfig.fields[filterKey]",
      //   filterConfig.fields[filterKey]
      // );
      //   // attendanceStatus filtering
      //   if (filterKey === "attendanceStatus") {
      //     if (filterValue === "All") {
      //       // no nothing (allow all)
      //     } else {
      //       filtered = filtered.filter(
      //         (rsvp) =>
      //           rsvp.attendanceStatus.toLowerCase() === filterValue.toLowerCase()
      //       );
      //     }
      //   }

      // string filtering
    }

    // // approved filter
    // if (approvedFilter) {
    //   filtered = filtered.filter((rsvp) => {
    //     const approved = rsvp.optionRequests.some(
    //       (or) => or.status === StatusEnum.APPROVED
    //     ); // has at least one approved request
    //     return approved;
    //   });
    // }

    if (filterConfig.metadata.tier.value !== "All") {
      const value = filterConfig.metadata.tier.value.toLowerCase();
      filtered = filtered.filter((m) => metadata[m.id].tier === value);
    }

    // show deleted filter and only deleted filter are both checked
    if (
      filterConfig.metadata.showDeleted.value &&
      filterConfig.metadata.showDeletedOnly.value
    ) {
      try {
        filtered = filtered.filter((m) => metadata[m.id].deleted);
      } catch (error) {
        console.log(error);
      }
    }

    return filtered;
  };
  let filteredContacts = getFilteredContacts();

  // OptionRequest popup
  const [showPopupOR, setShowPopupOR] = useState(false);
  const [showPopupAttendance, setShowPopupAttendance] = useState(false);
  const [popupPositionOR, setPopupPositionOR] = useState({ x: 0, y: 0 });
  const [popupPositionAttendance, setPopupPositionAttendance] = useState({
    x: 0,
    y: 0,
  });
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedOptionRequest, setSelectedOptionRequest] = useState(null);
  const [notRequestedOptionId, setNotRequestedOptionId] = useState(null);

  useEffect(() => {
    setSelectedSweeperId(selectedSweeper.sweeperId);
    console.log("selectedSweeper", selectedSweeper);

    // set filter config
    if (selectedSweeper) {
      setFilterConfig(selectedSweeper.filterConfig);
    }
  }, [selectedSweeper]);

  const [isSaving, setIsSaving] = useState(false);

  // Debounce API requests to avoid too many calls
  const debouncedEditSweeperApiCall = debounce(async (sweeperData) => {
    const api = new ApiService({ password, employee });
    const { success, error, info } = await api.post(
      `landmark/sweeper/editSweeper`,
      { sweeper: sweeperData }
    );

    if (!success) {
      throw new Error(error || "Failed to save filter settings");
    }
  }, 500); // Adjust debounce time based on UX needs

  const handleSetFilterConfig = async (newFilterConfig) => {
    console.log(`handleSetFilterConfig() - newFilterConfig`, newFilterConfig);
    const prevFilterConfig = filterConfig; // Store previous state in case of failure

    try {
      let resolvedFilterConfig = newFilterConfig;

      // If newFilterConfig is a function, call it with the current state
      if (typeof newFilterConfig === "function") {
        resolvedFilterConfig = newFilterConfig(filterConfig);
      }

      // Ensure we are working with a plain object
      const updatedSelectedSweeper = selectedSweeper
        ? { ...JSON.parse(JSON.stringify(selectedSweeper)) }
        : {};

      updatedSelectedSweeper.filterConfig = resolvedFilterConfig
        ? { ...JSON.parse(JSON.stringify(resolvedFilterConfig)) }
        : {};

      // Optimistically update the state before API request
      setFilterConfig(resolvedFilterConfig);

      setIsSaving(true);

      // Optimistically update the state
      // setFilterConfig(newFilterConfig);

      // const updatedSelectedSweeper = {
      //   ...selectedSweeper, // Ensure it’s always an object
      //   filterConfig: newFilterConfig, // Ensure it's always an object
      // };

      console.log(
        `handleSetFilterConfig() - updatedSelectedSweeper`,
        updatedSelectedSweeper
      );

      // Debounce API request
      await debouncedEditSweeperApiCall(updatedSelectedSweeper);

      toast.success(`Filter Settings Saved`);
      return true;
    } catch (error) {
      console.error("Error updating filter config:", error);

      // Revert to previous state if the request fails
      setFilterConfig(prevFilterConfig);
      toast.error(`An error occurred. Your changes were not saved.`);
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const handleBadgeClickOR = (
    event,
    contact,
    optionRequest,
    notRequestedOptionId = null
  ) => {
    const badgeRect = event.currentTarget.getBoundingClientRect();
    const popupX = badgeRect.left + badgeRect.width / 2;
    const popupY = badgeRect.bottom;
    setPopupPositionOR({ x: popupX, y: popupY });
    setSelectedContact(contact);
    // setSelectedOptionRequest(optionRequest);
    setNotRequestedOptionId(notRequestedOptionId);
    setShowPopupOR(true);
    setShowPopupAttendance(false);
  };

  const handleBadgeClickAttendance = (event, rsvp) => {
    const badgeRect = event.currentTarget.getBoundingClientRect();
    const popupX = badgeRect.left + badgeRect.width / 2;
    const popupY = badgeRect.bottom;
    setPopupPositionAttendance({ x: popupX, y: popupY });
    setSelectedContact(rsvp);
    setShowPopupOR(false);
    setShowPopupAttendance(true);
  };

  // allEvents.forEach(element => {
  //   columns.push({
  //     header: element.eventTitle,
  //     accessorKey: outputdata[0].
  //   })
  // });
  // const handleExportRows = (rows) => {
  //   csvExporter.generateCsv(rows.map((row) => row.original));
  // };

  // const handleExportData = () => {
  //   csvExporter.generateCsv(contacts);
  // };

  // const handleMarkContactsAsHandled = async (rows) => {
  //   const eventRsvpIds = rows.map((row) => row.original.rsvpEventId);
  //   // mark all as handled
  //   const handledArr = eventRsvpIds.map((e) => true);
  //   try {
  //     const api = new ApiService({ password });
  //     const { success, error } = await api.post(
  //       `landmark/events/setEventContactsHandled/`,
  //       {
  //         eventRsvpIds,
  //         handledArr,
  //       }
  //     );
  //     if (!success) {
  //       toast.error(`An error occurred: ${error}`);
  //       return false;
  //     } else {
  //       toast.success(`${success}`);
  //       // refresh table
  //       RenderTable();
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  // };

  // const deleteContacts = async (rows) => {
  //   const eventRsvpIds = rows.map((row) => row.original.rsvpEventId);
  //   try {
  //     const api = new ApiService({ password });
  //     const { success, error } = await api.post(
  //       `landmark/events/removeEventContacts`,
  //       {
  //         eventRsvpIds,
  //       }
  //     );
  //     if (!success) {
  //       toast.error(`An error occurred: ${error}`);
  //       return false;
  //     } else {
  //       toast.success(`${success}`);
  //       // refresh table
  //       RenderTable();
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  // };

  // const csvOptions = {
  //   fieldSeparator: ",",
  //   quoteStrings: '"',
  //   decimalSeparator: ".",
  //   showLabels: true,
  //   useBom: true,
  //   useKeysAsHeaders: false,
  //   headers: columns.map((c) => c.header),
  // };

  // const csvExporter = new ExportToCsv(csvOptions);

  const copyText = (text, desc = null) => {
    copyTextToClipboard(text);
    desc = desc ? desc : "text";
    toast.success(`Copied ${desc} to clipboard`);
  };

  let canNextPage = false;
  let canPreviousPage = false;
  const pageCount = Math.ceil(filteredContacts.length / rowsPerPage);
  console.log(`pageIndex`, pageIndex);
  console.log(`pageCount`, pageCount);
  if (pageCount === 0 && pageIndex !== 0) {
    console.log(`resetting pageIndex to 0 (out of bounds)`);
    setPageIndex(0);
  } else if (pageCount > 0 && pageIndex + 1 > pageCount) {
    console.log(`resetting pageIndex to 0 (out of bounds)`);
    setPageIndex(0);
  }
  if (pageIndex + 1 < pageCount) {
    canNextPage = true;
  }
  if (pageIndex > 0) {
    canPreviousPage = true;
  }

  const goToPage = (pageNo) => {
    if (pageNo >= 0 && pageNo < pageCount) {
      setPageIndex(pageNo);
    } else {
    }
  };
  const nextPage = () => {
    if (canNextPage) {
      setPageIndex(pageIndex + 1);
    }
  };
  const previousPage = () => {
    if (canPreviousPage) {
      setPageIndex(pageIndex - 1);
    }
  };
  // Calculate the start index and end index for the current page
  const startIndex = pageIndex * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // get contacts to display on the current page
  const pageContacts = filteredContacts.slice(startIndex, endIndex);

  if (!filteredContacts || !selectedSweeperId) {
    return <>No Contacts or events or selectedSweeper</>;
  }

  const handleUpdateContactTier = async (tier) => {
    try {
      // Create a new metadata object (immutability is important)
      const updatedMetadata = {
        ...metadata,
        [selectedContact.id]: {
          ...metadata[selectedContact.id],
          tier,
        },
      };

      // Optimistically update the state before API request
      setMetadata(updatedMetadata);

      // Make the API request
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/sweeper/editSweeperMetadata`,
        {
          sweeperId: selectedSweeper.sweeperId,
          metadata: updatedMetadata,
        }
      );

      if (!success && !info) {
        // Revert state if the request fails
        setMetadata(metadata);
        toast.error(`An error occurred: ${error}`);
        return false;
      }

      if (success) {
        toast.success(`Tier updated`);
        return true;
      }

      if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      // Revert state on error
      setMetadata(metadata);
      return false;
    }
  };

  const selectContacts = (contactsToSelect) => {
    const newSelectedContactIds = [...selectedContactIds];
    contactsToSelect.forEach((m) => {
      if (!newSelectedContactIds.includes(m.id)) {
        newSelectedContactIds.push(m.id);
      }
    });
    setSelectedContactIds(newSelectedContactIds);
  };

  const unselectContacts = (contactsToUnselect) => {
    const contactIdsToUnselect = contactsToUnselect.map((m) => m.id);
    setSelectedContactIds((prevSelectedContactIds) => {
      return prevSelectedContactIds.filter(
        (m) => !contactIdsToUnselect.includes(m)
      );
    });
  };

  const toggleSelectAllPageContacts = (event) => {
    console.log(`toggleSelectAllPageContacts() - event.target`, event.target);
    const checked = event.target.checked;
    // select all contacts on this page
    if (checked) {
      selectContacts(pageContacts);
    }
    // unselect all contacts on this page
    else {
      unselectContacts(pageContacts);
    }
  };
  const toggleSelectContact = (event, contact) => {
    const checked = event.target.checked;
    console.log(`toggleSelectContact() - checked`, checked);
    if (checked) {
      selectContacts([contact]);
    } else {
      unselectContacts([contact]);
    }
  };

  if (!selectedSweeper) {
    return null;
  }
  console.log(`selectedSweeper`, selectedSweeper);

  let headers = [];
  // Assuming contacts is not an empty array
  if (contacts.length > 0) {
    const headersSet = new Set();
    contacts.forEach((r) => {
      Object.keys(r).forEach((key) => {
        headersSet.add(key);
      });
    });

    headers = Array.from(headersSet).map((h) => {
      return { key: h, name: h };
    });
    // add option headers
    if (selectedSweeper && selectedSweeper.options) {
      selectedSweeper.options
        .sort((a, b) => a.startTime - b.startTime)
        .forEach((option) => {
          const { title, optionId } = option;
          headers.push({ key: optionId, name: title });
        });
    }
    // remove unwanted fields
    const unwanted = [
      "attendanceStatusUpdates",
      "comments",
      "confirmEmails",
      "optionRequests",
      "__v",
      "_id",
      "partnerResponses",
    ];
    headers = headers.filter((h) => !unwanted.includes(h.key));
    console.log(`headers`, headers);
  }

  const prepContactsForCsvExport = () => {
    return contacts;
    return contacts
      .filter((r) => r.eventId === selectedSweeper.eventId)
      .map((r) => {
        const rsvp = JSON.parse(JSON.stringify(r));
        rsvp.optionRequests.forEach((or) => {
          const { optionId, status } = or;
          rsvp[optionId] = status;
        });
        rsvp.createdTime = epochToDateTimeString(rsvp.createdTime);
        // selectedSweeper.options.forEach((option) => {
        //   const { optionId } = option;
        //   if (!rsvp[optionId]) {
        //     rsvp[optionId] = "not requested";
        //   }
        // });
        // delete rsvp.attendanceStatusUpdates;
        // delete rsvp.comments;
        // delete rsvp.confirmEmails;
        // delete rsvp.optionRequests;
        // delete rsvp.__v;
        // delete rsvp._id;
        return rsvp;
      });
  };

  // runs based on the filterOptions values in selectedSweeper
  const runTitleSearch = async () => {
    try {
      // Make the API request
      const api = new ApiService({ password, employee });
      const { success, error, info, sweeper } = await api.post(
        `landmark/sweeper/runTitleSearch`,
        {
          sweeperId: selectedSweeper.sweeperId,
        }
      );

      if (!success && !info) {
        // Revert state if the request fails
        toast.error(`An error occurred: ${error}`);
        return false;
      }

      if (success && sweeper) {
        toast.success(`Smart search completed`);
        setSelectedSweeper(sweeper); // update sweeper object to get new filterConfig which includes previous results - aka search results
        return true;
      }

      if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      // Revert state on error
      // setMetadata(metadata);
      return false;
    }
  };

  const nonPendingStatusesExistForSelectedContactsBulkOnlyAffectOptionId =
    () => {
      if (!bulkOnlyAffectOptionId) {
        // not filtering by optionId so don't need to check
        return;
      } else {
        const selectedContacts = contacts.filter((m) =>
          selectedContactIds.includes(m.id)
        );
        console.log(
          `checkForNonPendingStatusesBulkOnlyAffectOptionId() - selectedContacts.length`,
          selectedContacts.length
        );
        for (let i = 0; i < selectedContacts.length; i++) {
          const rsvp = selectedContacts[i];
          if (rsvp.optionRequests) {
            const relevantOrs = rsvp.optionRequests.filter(
              (or) => or.optionId === bulkOnlyAffectOptionId
            );
            if (
              relevantOrs.some((or) => or.status.toLowerCase() !== "pending")
            ) {
              // non-pending status found - show warning
              return true;
            }
          }
        }
      }
      return false;
    };

  const setSelectedContactsTiers = async (tier) => {
    // if (nonPendingStatusesExistForSelectedContactsBulkOnlyAffectOptionId()) {
    //   window.alert(
    //     "Some of the affected requests are already Approved/Waitlisted/Denied. This is a safeguard to prevent accidentally overwriting those requests. Please use the filters to filter the event to just Pending requests only. No action has been taken. (If this is annoying you then tell mjones and he will remove this safeguard)"
    //   );
    //   return;
    // }

    // set metadata
    try {
      const updatedMetadata = {
        ...metadata,
        ...Object.fromEntries(
          selectedContactIds.map((id) => [id, { ...metadata[id], tier }])
        ),
      };

      // Update state
      setMetadata(updatedMetadata);

      // update in backend
      const api = new ApiService({ password, employee });
      const { success, error, info } = await api.post(
        `landmark/sweeper/editSweeperMetadata`,
        {
          sweeperId: selectedSweeper.sweeperId,
          metadata: updatedMetadata,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        const s = selectedContactIds.length === 1 ? "" : "s";
        const successMsg = `All contact tiers set as ${getDisplayNameForTier(
          tier
        )}`;

        toast.success(successMsg);

        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const deleteSelectedContacts = async (undelete = false) => {
    let deleted;
    // undeleting contacts
    if (undelete) {
      deleted = false;
      // Check if any contacts are selected
      if (selectedContactIds.length === 0) {
        toast.error("No contacts selected for restoration");
        return;
      }
    }
    // deleting contacts
    else {
      deleted = true;

      // Check if any Contacts are selected
      if (selectedContactIds.length === 0) {
        toast.error("No contacts selected for deletion");
        return;
      }

      // Show confirmation prompt
      const confirmDelete = window.confirm(
        `Are you sure you want to delete ${selectedContactIds.length} contact${
          selectedContactIds.length === 1 ? "" : "s"
        }?`
      );

      if (!confirmDelete) {
        // User clicked "Cancel"
        return;
      }
    }

    // set metadata
    try {
      const updatedMetadata = {
        ...metadata,
        ...Object.fromEntries(
          selectedContactIds.map((id) => [id, { ...metadata[id], deleted }])
        ),
      };

      // Update state
      setMetadata(updatedMetadata);

      // update in backend
      const api = new ApiService({ password, employee });
      const { success, error, info, metadata } = await api.post(
        `landmark/sweeper/editSweeperMetadata`,
        {
          sweeperId: selectedSweeper.sweeperId,
          metadata: updatedMetadata,
        }
      );
      if (!success && !info) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        let successMsg;
        const s = selectedContactIds.length === 1 ? "" : "s";
        if (deleted) {
          successMsg = `Deleted ${selectedContactIds.length} contact${s}`;
          setSelectedContactIds([]); // clear selected since we deleted them all
        } else {
          successMsg = `${selectedContactIds.length} contact${s} unmarked as Deleted`;
        }
        toast.success(successMsg);
        return true;
      } else if (info) {
        toast(`${info}`);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const csvAdjustedContacts = prepContactsForCsvExport();
  const filteredCsvAdjustedContacts = csvAdjustedContacts.filter((m) =>
    filteredContacts.some((fm) => fm.id === m.id)
  );
  const selectedCsvAdjustedContacts = csvAdjustedContacts.filter((m) =>
    selectedContactIds.some((id) => id === m.id)
  );
  let csvAdjustedContactsNoDeleted = [];
  try {
    csvAdjustedContactsNoDeleted = csvAdjustedContacts.filter(
      (m) => !metadata[m.id]?.deleted
    );
  } catch (error) {
    console.log(error);
  }

  // // for approved batches
  // Step 1: Filter contacts that have at least one approved and final option request
  // const csvContactsAllApprovedFinal = csvAdjustedContactsNoDeleted.filter((rsvp) => {
  //   const approved = rsvp.optionRequests.some(
  //     (or) => or.status === StatusEnum.APPROVED && or.final
  //   );
  //   return approved;
  // });

  const selectedContactsIncludeDeleted =
    selectedContactIds &&
    selectedContactIds.length &&
    contacts
      .filter((m) => metadata[m.id]?.deleted)
      .some((m) => selectedContactIds.includes(m.id));

  const allBronzeRelationshipValues = Array.from(
    new Set(contacts.map((r) => r.bronzeRelationship))
  ); // todo might not be neccesary
  const bronzeRelationshipEnum = {};
  allBronzeRelationshipValues.forEach((br) => {
    bronzeRelationshipEnum[br] = br;
  });

  return (
    <>
      {contacts && contacts.length ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="event-image-img">
              <img src={selectedSweeper.imageUrl} className="img-fluid" />
            </div>
            <div className="row">
              <div className="outputHeader">Contacts</div>
              <div className="events-owners-subheading">
                Browse, filter, and export contacts for project{" "}
                {selectedSweeper.title}
                {/* Recommended workflow:
                <ul>
                <li>• Filter the data based on event (or company, etc.)</li>
                <li>
                • Click 'Export Current' to export the filtered data to a
                spreadsheet
                </li>
                <li>• Once handled, click 'Mark Current as Handled'</li>
              </ul> */}
              </div>
              <div className="col-12">
                <div className="add-edit-btn-container"></div>
              </div>
              <>
                <></>
                <div className="outputBody">
                  <div className="table-wrapper">
                    {showPopupOR && (
                      <ContactTierUpdatePopup
                        showPopup={showPopupOR}
                        setShowPopup={setShowPopupOR}
                        sweeper={selectedSweeper}
                        metadata={metadata}
                        contact={selectedContact}
                        position={popupPositionOR}
                        handleUpdateContactTier={handleUpdateContactTier}
                      />
                    )}
                    {
                      <FiltersSection
                        filterConfig={filterConfig}
                        setFilterConfig={handleSetFilterConfig}
                        bronzeRelationshipEnum={bronzeRelationshipEnum}
                        handleTitleSearchBtn={runTitleSearch}
                      />
                    }
                    {
                      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                        <div className="table-wrapper">
                          <div
                            className="events-owners-subheading"
                            style={{ marginBottom: "8px" }}
                          >
                            {`Totals${
                              filterConfig.enabled ? ` (filtered)` : ``
                            }`}
                          </div>
                          <div className="totals-row rmono">
                            <div className="total-col">
                              <div className="total-line">
                                <div className="field">Contacts: </div>
                                <div className="value">
                                  {filteredContacts.length}
                                </div>
                              </div>

                              <div className="total-line col-approved">
                                <div className="field">Tier 1: </div>
                                <div className="value">
                                  {
                                    filteredContacts.filter(
                                      (m) =>
                                        metadata[m.id]?.tier === TierEnum.TIER_1
                                    ).length
                                  }
                                </div>
                              </div>

                              <div className="total-line col-waitlisted">
                                <div className="field">Tier 2: </div>
                                <div className="value">
                                  {
                                    filteredContacts.filter(
                                      (m) =>
                                        metadata[m.id]?.tier === TierEnum.TIER_2
                                    ).length
                                  }
                                </div>
                              </div>

                              <div className="total-line col-denied">
                                <div className="field">Hold: </div>
                                <div className="value">
                                  {
                                    filteredContacts.filter(
                                      (m) =>
                                        metadata[m.id]?.tier === TierEnum.HOLD
                                    ).length
                                  }
                                </div>
                              </div>

                              <div className="total-line col-pending">
                                <div className="field">Unassigned: </div>
                                <div className="value">
                                  {
                                    filteredContacts.filter(
                                      (m) =>
                                        metadata[m.id]?.tier ===
                                        TierEnum.NO_TIER
                                    ).length
                                  }
                                </div>
                              </div>
                            </div>
                            {/* <div className="total-col">
                              <div className="total-line option">
                                <div className="field">Total Applied: </div>
                                <div className="value">
                                  {filteredContacts.length}
           
                                </div>
                              </div> */}
                            {/* <div className={`total-line col-approved`}>
                                <div className="field">{"Approved "}</div>
                                <div className={`value`}>
                                  {filteredContacts.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) =>
                                          or.status === StatusEnum.APPROVED
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div> */}
                            {/* <div className={`total-line col-waitlisted`}>
                                <div className="field">{"Waitlisted "}</div>
                                <div className={`value`}>
                                  {filteredContacts.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) =>
                                          or.status === StatusEnum.WAITLISTED
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div>
                              <div className={`total-line col-denied`}>
                                <div className="field">{"Denied "}</div>
                                <div className={`value`}>
                                  {filteredContacts.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) => or.status === StatusEnum.DENIED
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div>
                              <div className={`total-line col-pending`}>
                                <div className="field">{"Pending "}</div>
                                <div className={`value`}>
                                  {filteredContacts.reduce(
                                    (sum, fr) =>
                                      sum +
                                      fr.optionRequests.filter(
                                        (or) => or.status === StatusEnum.PENDING
                                      ).length,
                                    0
                                  )}
                                </div>
                              </div> */}
                            {/* <div className="total-line option">
                                <div className="field">Attending: </div>
                                <div className="value">
                                  {
                                    filteredContacts.filter(
                                      (r) =>
                                        r.attendanceStatus ===
                                        AttendanceStatusEnum.ATTENDING
                                    ).length
                                  }
                                </div>
                              </div>
                              <div className={`total-line col-tentative`}>
                                <div className="field">{"Tentative "}</div>
                                <div className={`value`}>
                                  {
                                    filteredContacts.filter(
                                      (r) =>
                                        r.attendanceStatus ===
                                        AttendanceStatusEnum.TENTATIVE
                                    ).length
                                  }
                                </div>
                              </div>
                              <div className={`total-line col-not-attending`}>
                                <div className="field">{"No "}</div>
                                <div className={`value`}>
                                  {
                                    filteredContacts.filter(
                                      (r) =>
                                        r.attendanceStatus ===
                                        AttendanceStatusEnum.NOT_ATTENDING
                                    ).length
                                  }
                                </div>
                              </div> */}
                            {/* </div> */}
                          </div>
                        </div>
                      </motion.div>
                    }
                    {selectedContactIds && selectedContactIds.length ? (
                      <div className="selected-rsvps-controls-container">
                        <div className="selected-display">{`${
                          selectedContactIds.length
                        } contact${
                          selectedContactIds.length === 1 ? "" : "s"
                        } selected`}</div>
                        <div className="text-center mb-4">
                          <div className="mt-3">
                            <div className="filters-row">
                              {/* <FilterOption
                                title="Only affect requests for event"
                                filterKey="bulkRequestEvent"
                                noTitleCase={true}
                                optionsEnum={selectedSweeper.options.reduce(
                                  (acc, o) => {
                                    acc[o.optionId] = o.title;
                                    return acc;
                                  },
                                  {}
                                )}
                                filters={filters}
                                handleSetFilter={handleSetFilter}
                              /> */}
                            </div>{" "}
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() =>
                                setSelectedContactsTiers(TierEnum.HOLD)
                              }
                              // style={{ marginLeft: "10px" }}
                            >
                              {`Mark all contacts as `}
                              <div className="badge-wrapper inline">
                                <span className="status-badge hvr-shrink badge bg-danger">
                                  Hold
                                </span>
                              </div>
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() =>
                                setSelectedContactsTiers(TierEnum.TIER_1)
                              }
                              style={{ marginLeft: "10px" }}
                            >
                              {`Mark all contacts as `}
                              <div className="badge-wrapper inline">
                                <span className="status-badge hvr-shrink badge bg-success">
                                  Tier 1
                                </span>
                              </div>
                              {/* {`(${selectedContactIds.length} Contacts)`} */}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() =>
                                setSelectedContactsTiers(TierEnum.TIER_2)
                              }
                              style={{ marginLeft: "10px" }}
                            >
                              {`Mark all contacts as `}
                              <div className="badge-wrapper inline">
                                <span className="status-badge hvr-shrink badge bg-warning">
                                  Tier 2
                                </span>
                              </div>
                              {/* {`(${selectedContactIds.length} Contacts)`} */}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>{" "}
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                              onClick={() =>
                                setSelectedContactsTiers(TierEnum.NO_TIER)
                              }
                              style={{ marginLeft: "10px" }}
                            >
                              {`Mark all contacts as `}
                              <div className="badge-wrapper inline">
                                <span className="status-badge hvr-shrink badge bg-light">
                                  No Tier
                                </span>
                              </div>
                              {/* {`(${selectedContactIds.length} Contacts)`} */}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                          </div>
                        </div>
                        <div className="text-center mb-3">
                          <div className="mt-3">
                            <div
                              className={`create-event-btn btn btn-sm btn-primary email-options-btn unselect-btn`}
                              onClick={() => setSelectedContactIds([])}
                              // style={{ marginLeft: "10px" }}
                            >
                              Unselect All
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            <div
                              className={`create-event-btn btn btn-sm btn-danger email-options-btn delete-rsvps-btn`}
                              onClick={() => deleteSelectedContacts()}
                              style={{ marginLeft: "10px" }}
                            >
                              {`Delete Contact${
                                selectedContactIds.length === 1 ? "" : "s"
                              }`}
                              {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                            </div>
                            {selectedContactsIncludeDeleted ? (
                              <div
                                className={`create-event-btn btn btn-sm btn-danger email-options-btn undelete-rsvps-btn`}
                                onClick={() => deleteSelectedContacts(true)}
                                style={{ marginLeft: "10px" }}
                              >
                                {`Restore Deleted Contact${
                                  selectedContactIds.length === 1 ? "" : "s"
                                }`}
                                {/* <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i> */}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <Table responsive className="header-border ">
                      <thead>
                        <tr>
                          <th className="width50 ">
                            <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="checkAll"
                                required
                                checked={
                                  !pageContacts.some(
                                    (m) => !selectedContactIds.includes(m.id)
                                  )
                                }
                                onChange={toggleSelectAllPageContacts}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="checkAll"
                              ></label>
                            </div>
                          </th>
                          <th>Contact</th>
                          <th>Tier</th>
                          <th>Title</th>
                          <th>Company</th>
                          <th>Industry</th>
                          <th>Employees</th>
                          <th>Revenue</th>
                          <th>Bronze+</th>
                          {/* <th></th> */}
                          {/* {event.options
                            .sort((a, b) => a.startTime - b.startTime)
                            .map((option) => {
                              return <th>{option.title}</th>;
                            })} */}
                          {/* <th>Attending</th>
                          <th>Confirmation Email</th>
                          <th>Denied Email</th>
                          <th>Partner Options</th>
                          <th>Business Goals</th>
                          <th>Referred By</th>
                          <th>City</th>
                          <th>Country</th>
                          <th>Comments</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {pageContacts.map((contact, i) => {
                          if (i === 0) {
                            // only log 1
                            console.log(`contact`, contact);
                          }
                          const {
                            id,
                            accountName,
                            activeCampaignCount,
                            activeOpportunities,
                            attendeeTier,
                            bronzeRelationship,
                            businessEmail,
                            email,
                            campaignName,
                            campaignSentAs,
                            comments,
                            companyIndustry,
                            companyRevenue,
                            contactIDLong,
                            contactOwner,
                            customEventsAttended,
                            email2,
                            email3,
                            emailVersion,
                            employees,
                            eventsAttended,
                            firstName,
                            fullName,
                            lastActivityDate,
                            lastName,
                            lastSent,
                            contactStatus,
                            openedEmail,
                            outreachCounter,
                            pendingEvents,
                            relatedRecordID,
                            source,
                            title,
                          } = contact;
                          const _metadata = metadata[id];
                          let deleted, tier, hold;
                          if (_metadata) {
                            deleted = _metadata.deleted;
                            tier = _metadata.tier;
                            hold = _metadata.hold;
                          }
                          // console.log(`metadata`, metadata);
                          return (
                            <tr
                              key={`tr_row_${i}`}
                              className={`${deleted ? "deleted" : ""}`}
                            >
                              <td>
                                <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox2"
                                    required
                                    checked={selectedContactIds.includes(id)}
                                    onChange={(event) =>
                                      toggleSelectContact(event, contact)
                                    }
                                  />
                                </div>
                              </td>
                              <td
                                className="wrap"
                                style={{
                                  maxWidth: "230px",
                                  minWidth: "230px",
                                  width: "230px",
                                }}
                              >
                                <div className="user-container">
                                  {deleted ? (
                                    <div className="deleted">DELETED</div>
                                  ) : null}

                                  <div className="name">
                                    {firstName} {lastName}
                                  </div>
                                  <div className="email">
                                    {businessEmail}{" "}
                                    <span
                                      className="clipboard-icon hvr-shrink"
                                      onClick={() =>
                                        copyText(businessEmail, "email")
                                      }
                                    >
                                      <FileCopyOutlinedIcon
                                        style={{
                                          width: "0.7em",
                                          height: "0.7em",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  {/* <div className="job">
                                    {title} at {accountName}
                                  </div> */}
                                </div>
                              </td>
                              <td>
                                <div className="badge-wrapper">
                                  <Badge
                                    bg={getBadgeBgFromTier(tier)}
                                    className="status-badge hvr-shrink"
                                    onClick={(event) => {
                                      handleBadgeClickOR(event, contact);
                                    }}
                                  >
                                    {getDisplayNameForTier(tier)}
                                  </Badge>
                                  {/* <AnimatePresence>
                                            {final && (
                                              <div className="final-badge-wrapper">
                                                <motion.div
                                                  animate={{
                                                    scale: [0, 1.07, 1],
                                                    rotate: [-45, 3, 0],
                                                    opacity: [0, 1],
                                                  }}
                                                  exit={{
                                                    scale: [1, 1.07, 0],
                                                    rotate: [0, 3, -90],
                                                  }}
                                                >
                                                  <div className="final-badge">
                                                    Final
                                                  </div>
                                                </motion.div>
                                              </div>
                                            )}
                                          </AnimatePresence> */}
                                </div>
                              </td>
                              <td
                                className="wrap"
                                style={{
                                  maxWidth: "300px",
                                  minWidth: "300px",
                                  width: "300px",
                                }}
                              >
                                {title}
                              </td>
                              <td>{accountName}</td>
                              <td>{companyIndustry}</td>
                              <td>{employees}</td>
                              <td>{companyRevenue}</td>
                              <td>{bronzeRelationship}</td>
                              {/* <td> */}
                              {/* <div className="badge-wrapper"> */}
                              {/* <Badge
                                    bg={null}
                                    className={`status-badge hvr-shrink ${getBadgeClassFromAttendanceStatus(
                                      attendanceStatus
                                    )}`}
                                    onClick={(event) => {
                                      handleBadgeClickAttendance(event, rsvp);
                                    }}
                                  >
                                    {getAttendanceStatusDisplayText(
                                      attendanceStatus
                                    )}
                                  </Badge> */}
                              {/* </div> */}
                              {/* </td> */}
                              {/* confirmation email cell */}

                              {/* <td></td> */}

                              {/* <td
                                className="wrap"
                                style={{ maxWidth: "130px", minWidth: "130px" }}
                              >
                                {country}
                              </td> */}
                              {/* <td></td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-between">
                      <span className="table-index">
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageCount}
                        </strong>
                        {""}
                      </span>
                      <span className="table-index">
                        Rows per page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={rowsPerPage}
                          min={1}
                          max={500}
                          onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                          }}
                        />
                      </span>
                      <span className="table-index">
                        Go to page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={pageIndex + 1}
                          min={1}
                          max={pageCount}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            goToPage(pageNumber);
                          }}
                        />
                      </span>
                    </div>
                    <div className="text-center mb-3">
                      <div className="filter-pagination  mt-3">
                        <button
                          className=" previous-button"
                          onClick={() => goToPage(0)}
                          disabled={!canPreviousPage}
                        >
                          {"<<"}
                        </button>

                        <button
                          className="previous-button"
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          Previous
                        </button>
                        <button
                          className="next-button"
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          Next
                        </button>
                        <button
                          className=" next-button"
                          onClick={() => goToPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          {">>"}
                        </button>
                      </div>
                    </div>
                    <div className="text-center mb-3">
                      <div className="mt-3">
                        {/* <button
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                          onClick={handleExportData}
                        >
                          {`Export All Contacts`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </button> */}
                        <ExportJsonCsv
                          headers={headers}
                          items={csvAdjustedContactsNoDeleted}
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                        >
                          {`Export All Contact${
                            csvAdjustedContactsNoDeleted.length === 1 ? "" : "s"
                          } (${csvAdjustedContactsNoDeleted.length})`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </ExportJsonCsv>
                        {filterConfig.enabled ? (
                          <ExportJsonCsv
                            headers={headers}
                            items={filteredCsvAdjustedContacts}
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                            style={{ marginLeft: "10px" }}
                          >
                            {`Export Filtered Contact${
                              filteredCsvAdjustedContacts.length === 1
                                ? ""
                                : "s"
                            } (${filteredCsvAdjustedContacts.length})`}
                            <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                          </ExportJsonCsv>
                        ) : null}
                        {selectedContactIds && selectedContactIds.length ? (
                          <ExportJsonCsv
                            headers={headers}
                            items={selectedCsvAdjustedContacts}
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                            style={{ marginLeft: "10px" }}
                          >
                            {`Export Selected Contact${
                              selectedCsvAdjustedContacts.length === 1
                                ? ""
                                : "s"
                            } (${selectedCsvAdjustedContacts.length})`}
                            <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                          </ExportJsonCsv>
                        ) : null}
                        {/* <ExportJsonCsv
                          headers={headers}
                          items={csvContactsAllApprovedFinal.filter(
                            (r) => r.tempConfirmEmailHtml
                          )}
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                          style={{ marginLeft: "10px" }}
                        >
                          {`Export All Approved Final Contacts ready to send a conf email`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </ExportJsonCsv> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
              {!contacts ? (
                <div className="text-center">
                  <img
                    className="m-auto mt-3 mb-3"
                    width={74}
                    src={loadingCircleImg}
                  />
                </div>
              ) : null}
            </div>
          </Card>
        </motion.div>
      ) : (
        <div className="no-rsvps-msg">Loading List</div>
      )}
    </>
  );
};

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const SelectEvent = ({ password, selectedSweeper, setSelectedSweeper }) => {
  const [events, setEvents] = useState([]);

  async function getEvents() {
    try {
      const api = new ApiService({ password });
      const tag = "global";
      const eventsResponse = await api.get(`landmark/events/getEvents/${tag}`);
      console.log(`OwnerAssign - events`, eventsResponse);
      setEvents(eventsResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">Events</div>
            <>
              <div className="events-owners-subheading">
                Select Event to view Contacts
              </div>
              <div
                className="event-owners-wrapper"
                style={{ marginBottom: "12px" }}
              >
                {events.map((event) => {
                  const selectedClass = selectedSweeper;
                  return (
                    <div key={event.eventId}>
                      <div
                        className={`event-owner-container selectable ${selectedClass}`}
                        onClick={() => setSelectedSweeper(event)}
                      >
                        <img
                          src={event.imageUrl}
                          className="img-fluid event-img"
                        />
                        <div className="event-title">{event.title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};
TablePaginationActions2.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions2(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const EmployeeInfo = ({
  landmarkEmployee,
  handleOnClick = () => {},
  disableHover = false,
}) => {
  if (!landmarkEmployee) {
    return null;
  }
  const { firstName, lastName, email, employeeId } = landmarkEmployee;
  const disableHoverClass = disableHover ? "disable-hover" : "";
  return (
    <div
      className={`account ${disableHoverClass}`}
      onClick={() => handleOnClick(landmarkEmployee)}
    >
      <div
        className="user-icon"
        style={{
          backgroundColor: stringToRGB(employeeId),
        }}
      >
        <PersonIcon className="icon" />
      </div>
      <div>
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="email">{email}</div>
      </div>
    </div>
  );
};

const EmployeeSignIn = ({ password, signInAsEmployee }) => {
  const [employees, setEmployees] = useState([]);
  const [showNewAccount, setShowNewAccount] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  async function getEmployees() {
    try {
      const api = new ApiService({ password });
      const employeesResponse = await api.get(
        `landmark/employees/getEmployees`
      );
      console.log(`employees`, employeesResponse);
      setEmployees(employeesResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEmployees();
  }, []);

  const btnDisabled =
    firstName.length && lastName.length && email.length ? false : true;
  const btnDisabledClass = btnDisabled ? "disabled" : "";

  const handleSignUpBtnClicked = async () => {
    if (btnDisabled) {
      return;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/employees/addEmployee/`,
        {
          firstName,
          lastName,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        // toast.success(`Account Created`);
        const employee = success;
        signInAsEmployee(employee);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className="landmark-sign-in">
      <div className="card">
        <div className="card-body">
          <div className="header-text">Log in as</div>
          <div className="existing-accounts-container">
            {employees
              .sort((a, b) => {
                // If both have 'lastSeenTime', compare by 'lastSeenTime' (descending)
                if (a.lastSeenTime && b.lastSeenTime) {
                  return b.lastSeenTime - a.lastSeenTime;
                }

                // If one has 'lastSeenTime', prioritize that one
                if (a.lastSeenTime && !b.lastSeenTime) {
                  return -1;
                }
                if (!a.lastSeenTime && b.lastSeenTime) {
                  return 1;
                }

                // If neither have 'lastSeenTime', compare by 'firstName' alphabetically
                return a.firstName.localeCompare(b.firstName);
              })
              .map((employee, i) => (
                <EmployeeInfo
                  key={i}
                  landmarkEmployee={employee}
                  handleOnClick={signInAsEmployee}
                />
              ))}
          </div>
        </div>
      </div>
      {!showNewAccount && (
        <div
          className="new-account-btn"
          onClick={() => setShowNewAccount(true)}
        >
          <div className="hvr-shrink text">
            First time here? Create New Account
          </div>
        </div>
      )}
      {showNewAccount && (
        <motion.div
          animate={{
            scale: [0, 1.04, 1],
          }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="card">
            <div className="card-body">
              <div className="header-text">New Account</div>
              <div className="settings-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        value={firstName}
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        className="form-control"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <div className="input-group input-primary">
                      <input
                        type="text"
                        value={email}
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="input-group-text">
                        @landmarkventures.com
                      </span>
                    </div>
                  </div>
                  <div className="sign-up-btn-container">
                    <button
                      className={`sign-up-btn btn btn-primary btn-sm ${btnDisabledClass}`}
                      type="submit"
                      onClick={handleSignUpBtnClicked}
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [selectedSweeper, setSelectedSweeper] = useState(null);
  const [sweepers, setSweepers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [metadata, setMetadata] = useState([]);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function getSweepers() {
    try {
      const api = new ApiService({ password });
      const response = await api.get("landmark/sweeper/getSweepers");
      setSweepers(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getSweepers();
  }, []);

  async function fetchContacts() {
    try {
      if (!selectedSweeper || !selectedSweeper.sweeperId) {
        console.log(`fetchContacts() - invalid selected sweeper`);
        return;
      }
      const api = new ApiService({ password });
      const sweeperId = selectedSweeper.sweeperId;
      const resp = await api.get(`landmark/sweeper/getContacts/${sweeperId}`);
      console.log(`fetchContacts() - contacts`, resp);
      setContacts(resp);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchMetadata() {
    try {
      if (!selectedSweeper || !selectedSweeper.sweeperId) {
        console.log(`fetchMetadata() - invalid selected sweeper`);
        return;
      }
      const api = new ApiService({ password });
      const sweeperId = selectedSweeper.sweeperId;
      const resp = await api.get(`landmark/sweeper/getMetadata/${sweeperId}`);
      console.log(`fetchMetadata() - metadata`, resp);
      setMetadata(resp);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (!selectedSweeper) {
      setContacts([]);
      setMetadata([]);
    } else if (selectedSweeper && selectedSweeper.sweeperId) {
      // fetch contact and metadata info for selected sweeper
      fetchContacts();
      fetchMetadata();
    }
  }, [selectedSweeper]);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  const signInAsEmployee = (employee) => {
    if (!employee) {
      toast.error("Cannot sign in as employee");
      return;
    }
    setIsSignedIn(true);
    setEmployee(employee);

    try {
      const api = new ApiService({ employee });
      // sets lastSeenTime
      api.get(`landmark/employees/login`);
    } catch (error) {
      console.error(`signInAsEmployee() - employee login error`, error);
    }

    toast.success(`Logged in as ${employee.firstName} ${employee.lastName}`);
  };
  useDocumentTitle("Sweeper - The Smart List Cleaner");

  return (
    <Fragment>
      <div className="global-dash-wrapper sweeper">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        {isSignedIn && (
          <div className="landmark-sign-in" style={{ marginBottom: "18px" }}>
            <div className="existing-accounts-container">
              <EmployeeInfo landmarkEmployee={employee} disableHover={true} />
            </div>
          </div>
        )}
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            {
              title: "Sweeper - The Smart List Cleaner",
              url: `/landmark/sweeper`,
            },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated && isSignedIn ? (
          <>
            {/* const AddEditSweeper = ({
  password,
  sweepers,
  setSweepers,
  selectedSweeper,
  setSelectedSweeper,
  getSweepers,
}) => { */}
            <AddEditSweeper
              password={password}
              employee={employee}
              getSweepers={getSweepers}
              sweepers={sweepers}
              setSweepers={setSweepers}
              selectedSweeper={selectedSweeper}
              setSelectedSweeper={setSelectedSweeper}
              contacts={contacts}
              setContacts={setContacts}
            />
            {/* <OwnerAssign password={password} employee={employee} /> */}
            {/* <SelectEvent
              password={password}
              employee={employee}
              selectedSweeper={selectedSweeper}
              setSelectedSweeper={setSelectedSweeper}
            /> */}
            {selectedSweeper && (
              <MasterContactsTable
                selectedSweeper={selectedSweeper}
                setSelectedSweeper={setSelectedSweeper}
                getSweepers={getSweepers}
                sweepers={sweepers}
                password={password}
                employee={employee}
                contacts={contacts}
                metadata={metadata}
                setMetadata={setMetadata}
              />
            )}
          </>
        ) : null}
        {/* show sign in */}
        {isAuthenticated && !isSignedIn ? (
          <>
            <motion.div
              animate={{
                scale: [0, 1.04, 1],
              }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <EmployeeSignIn
                password={password}
                signInAsEmployee={signInAsEmployee}
              />
            </motion.div>
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access dashboard", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
