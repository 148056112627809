import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { Card, Modal, Button } from "react-bootstrap";
import ApiService from "../../../../services/ApiService";
import EventsDynamic from "./EventsClient/EventsDynamic";
import "react-datepicker/dist/react-datepicker.css";
import imageUploadLoader from "../../../../images/misc/loader-light-blue-bg.gif";
import { timeNow } from "../../../utils";

const EventForm = ({
  selectedEvent,
  selectedTemplateEvent,
  password,
  getEvents,
  events,
  employee,
  updateSetShowModal,
  resetModes,
}) => {
  // Default event information
  const defaultEvent = {
    pageUrl: "new-event",
    title: "New Event",
    referralUrls: [],
    googlePolicyOptions: true,
    apolloAutofill: true,
    registrationClosed: false,
    owners:
      !(selectedEvent && selectedEvent.eventId) && employee && employee.email
        ? [{ email: employee.email, assignedTime: timeNow() }]
        : [],
    pageContent: {
      headerLogos: [
        // {
        //   src: "https://betterblocks.landmarkventures.com:7770/uploads/landmark/misc/1724954034-lockup_googlecloud_fullcolor_rgb_2900x512px.png",
        //   scale: 100,
        // },
      ],
      eventImages: ["https://i.ibb.co/92V0V9S/West2.jpg"],
      expectBullets: [
        "6:00 - 7:00 pm: Cocktails & Networking",
        "7:00 - 7:30 pm: Around the Room Introductions",
        "7:30 - 9:00 pm: Dinner & Discussion",
      ],
      expectText: `Upon registering, our team will follow up with a formal confirmation and calendar hold with more details.`,
      heroHeading: "VIP Dinner: GenAI for Marketing",
      heroSubHeading:
        "Join us on September 10th at RPM Events, Chicago's premier private event venue, for an wonderful evening hosted in partnership with Google Cloud and Typeface.",
      heroText:
        "This special VIP Dinner (invitation-only; complimentary) brings together an exclusive group of top 20-25 CMOs and Senior Marketing Leaders for an insightful conversation around the trends, challenges, and opportunities related to harnessing and maximizing Generative AI. Details as follows:",
      eventDate: "Tuesday, September 10th",
      eventTime: "6:00pm - 9:00pm CDT",
      eventLocationFull:
        "RPM Events (Private Room)\n317 N Clark St\nChicago, IL 60654",
      eventLocationShort: "Chicago, IL",
      faqs: [
        {
          question: "Is the dinner complimentary?",
          answer: "As our guest, dinner and drinks are complimentary!",
        },
        {
          question: "Can I bring a plus one?",
          answer:
            "Due to limited capacity, this event is exclusively invitation-only. We are happy to check in on plus ones on an individual basis. If you'd like to inquire about inviting a colleague, please contact jpatterson@landmarkventures.com to check on availability.",
        },
        {
          question: "Is there parking at the venue?",
          answer:
            "As each venue has different parking arrangements, we'll be sure to include all the relevant parking details in the calendar hold and event reminders.",
        },
        {
          question: "Who do I reach out to if I have further questions?",
          answer:
            "Please reach out to jpatterson@landmarkventures.com if you have any questions ahead of the event.",
        },
      ],
      afterSubmittedText: `A member of our team will be in touch shortly confirming your attendance.`,
      footerImages: [],
      hideLandmarkFooter: false,
      themePrimaryColor: "#61999d",
      hideDietaryInput: false,
      showCustomInput: false,
      customInputRequired: false,
      customInputLabel: "Referred By",
    },
  };

  // State initialization
  const [title, setTitle] = useState(
    selectedEvent?.title || selectedTemplateEvent?.title || defaultEvent.title
  );
  const [pageUrl, setPageUrl] = useState(
    selectedEvent?.pageUrl ||
      selectedTemplateEvent?.pageUrl ||
      defaultEvent.pageUrl
  );

  const [referralUrls, setReferralUrls] = useState(
    selectedEvent?.referralUrls ||
      selectedTemplateEvent?.referralUrls ||
      defaultEvent.referralUrls
  );

  const [googlePolicyOptions, setGooglePolicyOptions] = useState(
    selectedEvent
      ? !!selectedEvent.googlePolicyOptions
      : selectedTemplateEvent
      ? !!selectedTemplateEvent.googlePolicyOptions
      : defaultEvent.googlePolicyOptions
  );

  const [apolloAutofill, setApolloAutofill] = useState(
    selectedEvent
      ? !!selectedEvent.apolloAutofill
      : selectedTemplateEvent
      ? !!selectedTemplateEvent.apolloAutofill
      : defaultEvent.apolloAutofill
  );

  const [registrationClosed, setRegistrationClosed] = useState(
    selectedEvent
      ? !!selectedEvent.registrationClosed
      : selectedTemplateEvent
      ? !!selectedTemplateEvent.registrationClosed
      : defaultEvent.registrationClosed
  );

  const [headerLogos, setHeaderLogos] = useState(
    selectedEvent?.pageContent?.headerLogos ||
      selectedTemplateEvent?.pageContent?.headerLogos ||
      defaultEvent.pageContent.headerLogos
  );
  const [heroHeading, setHeroHeading] = useState(
    selectedEvent?.pageContent?.heroHeading ||
      selectedTemplateEvent?.pageContent?.heroHeading ||
      defaultEvent.pageContent.heroHeading
  );
  const [heroSubHeading, setHeroSubHeading] = useState(
    selectedEvent?.pageContent?.heroSubHeading ||
      selectedTemplateEvent?.pageContent?.heroSubHeading ||
      defaultEvent.pageContent.heroSubHeading
  );
  const [heroText, setHeroText] = useState(
    selectedEvent?.pageContent?.heroText ||
      selectedTemplateEvent?.pageContent?.heroText ||
      defaultEvent.pageContent.heroText
  );
  const [eventImages, setEventImages] = useState(
    selectedEvent?.pageContent?.eventImages ||
      selectedTemplateEvent?.pageContent?.eventImages ||
      defaultEvent.pageContent.eventImages
  );
  const [eventDate, setEventDate] = useState(
    selectedEvent?.pageContent?.eventDate ||
      selectedTemplateEvent?.pageContent?.eventDate ||
      defaultEvent.pageContent.eventDate
  );
  const [eventTime, setEventTime] = useState(
    selectedEvent?.pageContent?.eventTime ||
      selectedTemplateEvent?.pageContent?.eventTime ||
      defaultEvent.pageContent.eventTime
  );
  const [eventLocationFull, setEventLocationFull] = useState(
    selectedEvent?.pageContent?.eventLocationFull ||
      selectedTemplateEvent?.pageContent?.eventLocationFull ||
      defaultEvent.pageContent.eventLocationFull
  );
  const [eventLocationShort, setEventLocationShort] = useState(
    selectedEvent?.pageContent?.eventLocationShort ||
      selectedTemplateEvent?.pageContent?.eventLocationShort ||
      defaultEvent.pageContent.eventLocationShort
  );
  const [expectBullets, setExpectBullets] = useState(
    selectedEvent?.pageContent?.expectBullets ||
      selectedTemplateEvent?.pageContent?.expectBullets ||
      defaultEvent.pageContent.expectBullets
  );
  const [expectText, setExpectText] = useState(
    selectedEvent?.pageContent?.expectText ||
      selectedTemplateEvent?.pageContent?.expectText ||
      defaultEvent.pageContent.expectText
  );
  const [faqs, setFaqs] = useState(
    selectedEvent?.pageContent?.faqs ||
      selectedTemplateEvent?.pageContent?.faqs ||
      defaultEvent.pageContent.faqs
  );
  const [afterSubmittedText, setAfterSubmittedText] = useState(
    selectedEvent?.pageContent?.afterSubmittedText ||
      selectedTemplateEvent?.pageContent?.afterSubmittedText ||
      defaultEvent.pageContent.afterSubmittedText
  );
  const [footerImages, setFooterImages] = useState(
    selectedEvent?.pageContent?.footerImages ||
      defaultEvent.pageContent.footerImages
  );
  const [hideLandmarkFooter, setHideLandmarkFooter] = useState(
    selectedEvent?.pageContent?.hideLandmarkFooter ||
      defaultEvent.pageContent.hideLandmarkFooter
  );
  const [themePrimaryColor, setThemePrimaryColor] = useState(
    selectedEvent?.pageContent?.themePrimaryColor ||
      defaultEvent.pageContent.themePrimaryColor
  );
  const [hideDietaryInput, setHideDietaryInput] = useState(
    selectedEvent?.pageContent?.hideDietaryInput ||
      defaultEvent.pageContent.hideDietaryInput
  );
  const [showCustomInput, setShowCustomInput] = useState(
    selectedEvent?.pageContent?.showCustomInput ||
      defaultEvent.pageContent.showCustomInput
  );
  const [customInputRequired, setCustomInputRequired] = useState(
    selectedEvent?.pageContent?.customInputRequired ||
      defaultEvent.pageContent.customInputRequired
  );
  const [customInputLabel, setCustomInputLabel] = useState(
    selectedEvent?.pageContent?.customInputLabel ||
      defaultEvent.pageContent.customInputLabel
  );
  const [owners, setOwners] = useState(
    selectedEvent?.owners ||
      selectedTemplateEvent?.owners ||
      defaultEvent.owners
  );

  // Loading state
  const [loading, setLoading] = useState(false);
  // page preview
  const [showPreview, setShowPreview] = useState(false);
  const [isPageUrlManuallyEdited, setIsPageUrlManuallyEdited] = useState(false);
  // const [previewEvent, setPreviewEvent] = useState(null);

  useEffect(() => {
    if (selectedEvent) {
      setTitle(selectedEvent.title || defaultEvent.title);
      setPageUrl(selectedEvent.pageUrl || defaultEvent.pageUrl);

      setReferralUrls(selectedEvent.referralUrls || defaultEvent.referralUrls);
      setGooglePolicyOptions(!!selectedEvent.googlePolicyOptions);
      setApolloAutofill(!!selectedEvent.apolloAutofill);
      setRegistrationClosed(!!selectedEvent.registrationClosed);

      setHeaderLogos(
        (selectedEvent.pageContent && selectedEvent.pageContent.headerLogos) ||
          defaultEvent.pageContent.headerLogos
      );
      setHeroHeading(
        (selectedEvent.pageContent && selectedEvent.pageContent.heroHeading) ||
          defaultEvent.pageContent.heroHeading
      );
      setHeroSubHeading(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.heroSubHeading) ||
          defaultEvent.pageContent.heroSubHeading
      );
      setHeroText(
        (selectedEvent.pageContent && selectedEvent.pageContent.heroText) ||
          defaultEvent.pageContent.heroText
      );
      setEventImages(
        (selectedEvent.pageContent && selectedEvent.pageContent.eventImages) ||
          defaultEvent.pageContent.eventImages
      );
      setEventDate(
        (selectedEvent.pageContent && selectedEvent.pageContent.eventDate) ||
          defaultEvent.pageContent.eventDate
      );
      setEventTime(
        (selectedEvent.pageContent && selectedEvent.pageContent.eventTime) ||
          defaultEvent.pageContent.eventTime
      );
      setEventLocationFull(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.eventLocationFull) ||
          defaultEvent.pageContent.eventLocationFull
      );
      setEventLocationShort(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.eventLocationShort) ||
          defaultEvent.pageContent.eventLocationShort
      );
      setExpectBullets(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.expectBullets) ||
          defaultEvent.pageContent.expectBullets
      );
      setExpectText(
        (selectedEvent.pageContent && selectedEvent.pageContent.expectText) ||
          defaultEvent.pageContent.expectText
      );
      setFaqs(
        (selectedEvent.pageContent && selectedEvent.pageContent.faqs) ||
          defaultEvent.pageContent.faqs
      );
      setAfterSubmittedText(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.afterSubmittedText) ||
          defaultEvent.pageContent.afterSubmittedText
      );
      setFooterImages(
        (selectedEvent.pageContent && selectedEvent.pageContent.footerImages) ||
          defaultEvent.pageContent.footerImages
      );
      setHideLandmarkFooter(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.hideLandmarkFooter) ||
          defaultEvent.pageContent.hideLandmarkFooter
      );
      setThemePrimaryColor(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.themePrimaryColor) ||
          defaultEvent.pageContent.themePrimaryColor
      );
      setHideDietaryInput(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.hideDietaryInput) ||
          defaultEvent.pageContent.hideDietaryInput
      );
      setShowCustomInput(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.showCustomInput) ||
          defaultEvent.pageContent.showCustomInput
      );
      setCustomInputRequired(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.customInputRequired) ||
          defaultEvent.pageContent.customInputRequired
      );
      setCustomInputLabel(
        (selectedEvent.pageContent &&
          selectedEvent.pageContent.customInputLabel) ||
          defaultEvent.pageContent.customInputLabel
      );
      setOwners((selectedEvent && selectedEvent.owners) || defaultEvent.owners);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedTemplateEvent) {
      setTitle(selectedTemplateEvent.title || defaultEvent.title);
      setPageUrl(selectedTemplateEvent.pageUrl || defaultEvent.pageUrl);

      setReferralUrls(
        selectedTemplateEvent.referralUrls || defaultEvent.referralUrls
      );
      setGooglePolicyOptions(!!selectedTemplateEvent.googlePolicyOptions);
      setApolloAutofill(!!selectedTemplateEvent.apolloAutofill);
      setRegistrationClosed(false); // always start with reg open

      setHeaderLogos(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.headerLogos) ||
          defaultEvent.pageContent.headerLogos
      );
      setHeroHeading(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.heroHeading) ||
          defaultEvent.pageContent.heroHeading
      );
      setHeroSubHeading(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.heroSubHeading) ||
          defaultEvent.pageContent.heroSubHeading
      );
      setHeroText(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.heroText) ||
          defaultEvent.pageContent.heroText
      );
      setEventImages(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.eventImages) ||
          defaultEvent.pageContent.eventImages
      );
      setEventDate(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.eventDate) ||
          defaultEvent.pageContent.eventDate
      );
      setEventTime(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.eventTime) ||
          defaultEvent.pageContent.eventTime
      );
      setEventLocationFull(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.eventLocationFull) ||
          defaultEvent.pageContent.eventLocationFull
      );
      setEventLocationShort(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.eventLocationShort) ||
          defaultEvent.pageContent.eventLocationShort
      );
      setExpectBullets(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.expectBullets) ||
          defaultEvent.pageContent.expectBullets
      );
      setExpectText(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.expectText) ||
          defaultEvent.pageContent.expectText
      );
      setFaqs(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.faqs) ||
          defaultEvent.pageContent.faqs
      );
      setAfterSubmittedText(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.afterSubmittedText) ||
          defaultEvent.pageContent.afterSubmittedText
      );
      setFooterImages(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.footerImages) ||
          defaultEvent.pageContent.footerImages
      );
      setHideLandmarkFooter(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.hideLandmarkFooter) ||
          defaultEvent.pageContent.hideLandmarkFooter
      );
      setThemePrimaryColor(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.themePrimaryColor) ||
          defaultEvent.pageContent.themePrimaryColor
      );
      setHideDietaryInput(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.hideDietaryInput) ||
          defaultEvent.pageContent.hideDietaryInput
      );
      setShowCustomInput(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.showCustomInput) ||
          defaultEvent.pageContent.showCustomInput
      );
      setCustomInputRequired(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.customInputRequired) ||
          defaultEvent.pageContent.customInputRequired
      );
      setCustomInputLabel(
        (selectedTemplateEvent.pageContent &&
          selectedTemplateEvent.pageContent.customInputLabel) ||
          defaultEvent.pageContent.customInputLabel
      );
      setOwners(
        (selectedTemplateEvent && selectedTemplateEvent.owners) ||
          defaultEvent.owners
      );
    }
  }, [selectedTemplateEvent]);

  // Helper functions for dynamic arrays
  const addArrayItem = (setter, array, newItem) => {
    setter([...array, newItem]);
  };

  const removeArrayItem = (setter, array, index) => {
    const updatedArray = array.filter((_, i) => i !== index);
    setter(updatedArray);
  };

  // Calculate rows for textareas based on the approximate word count and newlines
  const calculateRows = (text) => {
    const lines = text.split("\n").length;
    const words = text.split(" ").length;
    return Math.max(lines, Math.ceil(words / 5)); // Adjusting for \n and words per row
  };

  // Calculate dynamic height based on rows
  const calculateHeight = (text) => {
    const rows = calculateRows(text);
    return `${(rows * 65) / 4}px`; // 65px for 2 rows
  };

  // Handle image upload
  const handleImageUpload = async (event, setter, options = {}) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("image", file);
    formData.append("options", JSON.stringify(options));

    try {
      const api = new ApiService({ password });
      const response = await api.post("landmark/upload/image", formData);
      if (response.success && response.imageUrl) {
        if (options.headerLogoMode) {
          setter((prev) => [
            ...prev,
            { src: response.imageUrl, scale: 100, yOffset: 0 },
          ]);
        } else {
          setter((prev) => [...prev, response.imageUrl]);
        }
        toast.success("Image uploaded successfully");
      } else {
        toast.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image");
    } finally {
      setLoading(false); // End loading
    }
  };

  // Page URL sanitization function
  const sanitizePageUrl = (url) => {
    const sanitizedUrl = url
      .replace(/[^a-zA-Z0-9-]/g, "")
      .toLowerCase()
      .trim(); // Remove invalid characters and convert to lowercase
    if (!selectedEvent || !selectedEvent.eventId) {
      if (events && events.length) {
        const isUnique = !events.some(
          (event) => event.pageUrl.trim().toLowerCase() === sanitizedUrl
        );
        if (!isUnique) {
          // toast.error("Page URL must be unique.");
          // return "";
        }
      }
    }
    return sanitizedUrl;
  };

  const handlePageUrlChange = (e) => {
    const newUrl = e.target.value;
    const sanitizedUrl = sanitizePageUrl(newUrl);
    setPageUrl(sanitizedUrl);
    if (!sanitizedUrl.length) {
      setIsPageUrlManuallyEdited(false);
    } else {
      setIsPageUrlManuallyEdited(true); // Mark that the URL has been manually edited
    }
  };

  const handleSetTitle = (newTitle) => {
    setTitle(newTitle);

    // Only update the pageUrl if it hasn't been manually edited && we are not editing an existing event
    if (
      !pageUrl.length ||
      (!isPageUrlManuallyEdited && !(selectedEvent && selectedEvent.eventId))
    ) {
      const updatedPageUrl = sanitizePageUrl(
        newTitle.trim().toLowerCase().replace(/\s+/g, "-")
      );
      setPageUrl(updatedPageUrl);
    }
  };

  // Add or Edit Event Function
  async function addOrEditEvent(event, editMode = false) {
    try {
      const api = new ApiService({ password });
      const url = editMode
        ? "landmark/dynamicevents/editEvent"
        : "landmark/dynamicevents/addEvent";

      console.log(`event being saved`, event);
      const eventsResponse = await api.post(url, { event });
      console.log(`addOrEditEvent() - ${url} - response`, eventsResponse);
      const { success, error } = eventsResponse;
      if (!success) {
        toast.error("An error occurred");
      } else if (error) {
        toast.error(error);
      } else {
        // toast.success(success);
        updateSetShowModal(true, event.pageUrl); // Show the modal when event is saved
        getEvents(); // refresh events
        resetModes(); // exit the edit/create form
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to save event.");
    }
  }

  let pageUrlValid = pageUrl && pageUrl.length;
  let pageUrlInvalidMsg = "Page URL cannot be empty";
  if (
    pageUrl &&
    events &&
    events.length &&
    events.some(
      (e) => e.pageUrl.trim().toLowerCase() === pageUrl.trim().toLowerCase()
    )
  ) {
    // page url exists (but we may be editing an existing event)
    if (
      selectedEvent &&
      selectedEvent.eventId &&
      pageUrl === selectedEvent.pageUrl
    ) {
      // ok because we are editing an existing event
    } else {
      pageUrlValid = false;
      pageUrlInvalidMsg = "Page URL already exists";
    }
  }
  console.log(`referralUrls`, referralUrls);
  let referralUrlInvalidMsg = "";
  let referralUrlValid = true;

  if (referralUrls && !referralUrls.every((r) => r.name.trim().length)) {
    referralUrlValid = false;
    referralUrlInvalidMsg = "Referral Name cannot be empty";
  }
  if (referralUrls && !referralUrls.every((r) => r.url.trim().length)) {
    referralUrlValid = false;
    referralUrlInvalidMsg = "Referral URL cannot be empty";
  }
  const refUrlDupeCount = {};
  let refUrlDupeExists = false;
  referralUrls.forEach((r) => {
    const refUrl = r.url.trim().toLowerCase();
    if (refUrl.length) {
      if (!refUrlDupeCount[refUrl]) {
        refUrlDupeCount[refUrl] = true;
      } else {
        // already true - dupe exists
        refUrlDupeExists = true;
      }
    }
  });
  // no duplicate referral urls allowed
  if (referralUrls && referralUrls.length && refUrlDupeExists) {
    referralUrlValid = false;
    referralUrlInvalidMsg = "Referral URL already exists";
  }

  console.log(`referralUrlValid`, referralUrlValid);
  console.log(`referralUrlInvalidMsg`, referralUrlInvalidMsg);

  let ownerEmailInvalidMsg = "";
  let ownerEmailValid = true;

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (owners && !owners.every((o) => emailRegex.test(o.email))) {
    ownerEmailValid = false;
    ownerEmailInvalidMsg = "Notification email must be a valid email address";
  }
  if (owners && !owners.every((o) => o.email.trim().length)) {
    ownerEmailValid = false;
    ownerEmailInvalidMsg = "Notification email cannot be empty";
  }
  const ownerEmailDupeCount = {};
  let ownerEmailDupeExists = false;
  owners.forEach((o) => {
    const email = o.email.trim().toLowerCase();
    if (email.length) {
      if (!ownerEmailDupeCount[email]) {
        ownerEmailDupeCount[email] = true;
      } else {
        // already true - dupe exists
        ownerEmailDupeExists = true;
      }
    }
  });
  // no duplicate emails allowed
  if (owners && owners.length && ownerEmailDupeExists) {
    ownerEmailValid = false;
    ownerEmailInvalidMsg = "Notification email already exists";
  }

  console.log(`ownerEmailValid`, ownerEmailValid);
  console.log(`ownerEmailInvalidMsg`, ownerEmailInvalidMsg);

  const getEventObj = () => {
    const event = {
      title: title.trim(),
      pageUrl: pageUrl.trim(),
      referralUrls: referralUrls.filter(
        (r) => r.url && r.url.trim().length && r.name && r.name.trim().length
      ),
      googlePolicyOptions,
      apolloAutofill,
      registrationClosed,
      owners: owners.map((o) => {
        return { email: o.email, assignedTime: o.assignedTime }; // strips unwanted __id fields etc
      }),

      pageContent: {
        headerLogos: headerLogos.map((hl) => {
          return { src: hl.src, scale: hl.scale, yOffset: hl.yOffset }; // strips unwanted __id fields etc
        }),
        heroHeading: heroHeading.trim(),
        heroSubHeading: heroSubHeading.trim(),
        heroText: heroText.trim(),
        eventImages,
        eventDate: eventDate.trim(),
        eventTime: eventTime.trim(),
        eventLocationFull: eventLocationFull.trim(),
        eventLocationShort: eventLocationShort.trim(),
        expectBullets: expectBullets.map((b) => b.trim()),
        expectText: expectText.trim(),
        faqs: faqs.map((f) => {
          return { question: f.question.trim(), answer: f.answer.trim() };
        }),
        afterSubmittedText: afterSubmittedText.trim(),
        footerImages,
        hideLandmarkFooter,
        themePrimaryColor,
        hideDietaryInput,
        showCustomInput,
        customInputRequired,
        customInputLabel: customInputLabel ? customInputLabel.trim() : "",
      },
    };
    return event;
  };

  const toggleShowPreview = () => {
    const newShowPreview = !showPreview;
    setShowPreview(newShowPreview);
  };

  const handleSaveEvent = () => {
    const event = getEventObj();

    // hide preview
    setShowPreview(false);

    if (!pageUrlValid) {
      return toast.error("Please enter a valid Page URL");
    }

    if (selectedEvent && selectedEvent.eventId) {
      event.eventId = selectedEvent.eventId;
      addOrEditEvent(event, true); // edit event
    } else {
      addOrEditEvent(event, false); // add event
    }
  };

  let allExistingHeaderLogos = [];
  if (events) {
    const allHeaderLogos = {};
    const otherEvents = selectedEvent
      ? events.filter((e) => e.eventId !== selectedEvent.eventId)
      : events;
    otherEvents.forEach((event) => {
      event.pageContent.headerLogos.forEach((hl) => {
        allHeaderLogos[hl.src] = {
          src: hl.src,
          scale: hl.scale,
          yOffset: hl.yOffset,
        };
      });
    });
    allExistingHeaderLogos = Object.values(allHeaderLogos).filter(
      (hl) => !headerLogos.some((chl) => hl.src === chl.src)
    ); // filter to exclude already added logos
  }

  const scaleHeaderLogoMaxWidth = (scale) => {
    const maxWidth = 156; //.ed-wrapper .header-bar .header-container .header-logos .header-logo
    if (scale === 100) {
      return maxWidth;
    } else {
      // Calculate the width based on the scale and round to the nearest pixel
      return Math.round((scale / 100) * maxWidth);
    }
  };

  // Render form inputs based on new schema
  return (
    <>
      <div className="event-form">
        <div className="row">
          {/* Title input */}
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Title <i>(Internal reference only)</i>
            </label>
            <input
              type="text"
              value={title}
              className="form-control"
              onChange={(e) => handleSetTitle(e.target.value)}
            />
          </div>

          {/* Page URL input with prefix */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">Page URL</label>
            <div className="input-group">
              <span className="input-group-text">
                https://events.landmarkventures.com/
              </span>
              <input
                type="text"
                value={pageUrl}
                className={`form-control page-url ${
                  pageUrlValid ? "" : "invalid"
                }`}
                onChange={handlePageUrlChange}
              />
            </div>
            {!pageUrlValid ? (
              <div className="page-url-invalid-msg">{pageUrlInvalidMsg}</div>
            ) : null}
          </div>

          {/* Page Content Subheading */}
          <div className="col-md-12">
            <h3>Page Content</h3>
          </div>

          {/* Header Logos input */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">Header Logos</label>
            <div className="existing-logos-container">
              <label className="form-label">
                Click to add a previously used logo:
              </label>
              <div className="existing-logos-outer">
                {allExistingHeaderLogos.map((hl, index) => {
                  return (
                    <div
                      onClick={() => {
                        setHeaderLogos([...headerLogos, hl]); // add the new logo object
                      }}
                      key={index}
                      className="existing-logo"
                      style={{
                        maxWidth: `${scaleHeaderLogoMaxWidth(hl.scale)}px`,
                      }}
                    >
                      <img
                        src={hl.src}
                        alt={`Header Logo ${index + 1}`}
                        className="img-fluid"
                        style={{
                          maxWidth: `${scaleHeaderLogoMaxWidth(hl.scale)}px`,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {headerLogos.map((logo, index) => {
              const headerLogoStyle = {
                maxWidth: `${scaleHeaderLogoMaxWidth(logo.scale)}px`,
              };
              if (logo.yOffset && logo.yOffset !== 0) {
                headerLogoStyle.transform = `translateY(${logo.yOffset}px)`;
              }
              return (
                <div
                  key={index}
                  className="d-flex align-items-center mb-2"
                  style={{ marginTop: `${index === 0 ? "0" : "16px"}` }}
                >
                  <div className="header-logo-container img-thumbnail">
                    <img
                      src={logo.src}
                      alt={`Header Logo ${index + 1}`}
                      className="img-fluid"
                      style={headerLogoStyle}
                    />
                  </div>
                  {/* Input to adjust the scale and yOffset of the image */}
                  <div className="controls-container">
                    <div className="scale-container">
                      {/* Scale Input */}
                      <label className="form-label mb-0">Scale %</label>
                      <input
                        type="number"
                        value={logo.scale}
                        onChange={(e) => {
                          const newScale = Math.max(
                            1,
                            Math.min(200, parseInt(e.target.value, 10) || 1)
                          );
                          const updatedLogos = headerLogos.map((l, i) =>
                            i === index ? { ...l, scale: newScale } : l
                          );
                          setHeaderLogos(updatedLogos);
                        }}
                        className="form-control scale-spinner"
                        min="1"
                        max="200"
                      />
                    </div>

                    <div className="yOffset-container">
                      {/* yOffset Input */}
                      <label className="form-label mb-0">Y Offset (px)</label>
                      <input
                        type="number"
                        value={logo.yOffset || 0}
                        onChange={(e) => {
                          const newYOffset = parseInt(e.target.value, 10) || 0;
                          const updatedLogos = headerLogos.map((l, i) =>
                            i === index ? { ...l, yOffset: newYOffset } : l
                          );
                          setHeaderLogos(updatedLogos);
                        }}
                        className="form-control yOffset-spinner"
                      />
                    </div>

                    <button
                      className="btn btn-danger ml-2"
                      onClick={() =>
                        removeArrayItem(setHeaderLogos, headerLogos, index)
                      }
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
            {loading && (
              <img
                src={imageUploadLoader}
                alt="Uploading..."
                width="100"
                className="img-thumbnail"
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleImageUpload(e, setHeaderLogos, {
                  compress: true,
                  headerLogoMode: true,
                })
              }
              className="d-none"
              id="headerLogoUpload"
            />
            <button
              className="btn btn-secondary mt-2"
              onClick={() =>
                document.getElementById("headerLogoUpload").click()
              }
            >
              Upload Logo
            </button>
          </div>

          {/* Hero Heading input (Full Width) */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">Hero Heading</label>
            <input
              type="text"
              value={heroHeading}
              className="form-control"
              onChange={(e) => setHeroHeading(e.target.value)}
            />
          </div>

          {/* Hero Sub Heading input (Full Width) */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">Hero Sub Heading</label>
            <textarea
              value={heroSubHeading}
              className="form-control"
              style={{
                height: "80px",
                lineHeight: "1.3",
              }}
              onChange={(e) => setHeroSubHeading(e.target.value)}
            />
          </div>

          {/* Hero Text input */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">Hero Text</label>
            <textarea
              value={heroText}
              className="form-control"
              style={{ height: "120px", lineHeight: "1.3" }}
              onChange={(e) => setHeroText(e.target.value)}
            />
          </div>

          {/* Expect Bullets input */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">Timetable Bullets</label>
            {expectBullets.map((bullet, index) => (
              <div key={index} className="d-flex align-items-center mb-2">
                <input
                  type="text"
                  value={bullet}
                  className="form-control"
                  onChange={(e) => {
                    const updatedBullets = [...expectBullets];
                    updatedBullets[index] = e.target.value;
                    setExpectBullets(updatedBullets);
                  }}
                />
                <button
                  className="btn btn-danger ml-2"
                  onClick={() =>
                    removeArrayItem(setExpectBullets, expectBullets, index)
                  }
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              className="btn btn-secondary mt-2"
              onClick={() => addArrayItem(setExpectBullets, expectBullets, "")}
            >
              Add Bullet
            </button>
          </div>

          {/* What to Expect Text input */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">What to Expect Text</label>
            <textarea
              value={expectText}
              className="form-control"
              style={{ height: "80px", lineHeight: "1.3" }}
              onChange={(e) => setExpectText(e.target.value)}
            />
          </div>

          {/* Event Images input */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">Event Image</label>
            {eventImages.map((image, index) => (
              <div key={index} className="d-flex align-items-center mb-2">
                <img
                  src={image}
                  alt={`Event Image ${index + 1}`}
                  className="img-thumbnail"
                />
                <button
                  className="btn btn-danger ml-2"
                  onClick={() =>
                    removeArrayItem(setEventImages, eventImages, index)
                  }
                >
                  Remove
                </button>
              </div>
            ))}
            {loading && (
              <img
                src={imageUploadLoader}
                alt="Uploading..."
                width="100"
                className="img-thumbnail"
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleImageUpload(e, setEventImages, {
                  scaleWidth: 1200,
                  compress: true,
                })
              }
              className="d-none"
              id="eventImageUpload"
            />
            {eventImages && eventImages.length ? (
              <></>
            ) : (
              <button
                className="btn btn-secondary mt-2"
                onClick={() =>
                  document.getElementById("eventImageUpload").click()
                }
              >
                Upload Image
              </button>
            )}
          </div>

          {/* Event Location Short input */}
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">Event Location (Short)</label>
            <input
              type="text"
              value={eventLocationShort}
              className="form-control"
              onChange={(e) => setEventLocationShort(e.target.value)}
            />
          </div>

          {/* Event Location Full input */}
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">Event Location (Full)</label>
            <textarea
              value={eventLocationFull}
              className="form-control"
              style={{
                height: "84px",
                lineHeight: "1.3",
              }}
              onChange={(e) => setEventLocationFull(e.target.value)}
            />
          </div>

          {/* Event Date input */}
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">Event Date</label>
            <input
              type="text"
              value={eventDate}
              className="form-control"
              onChange={(e) => setEventDate(e.target.value)}
            />
          </div>

          {/* Event Time input */}
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">Event Time</label>
            <input
              type="text"
              value={eventTime}
              className="form-control"
              onChange={(e) => setEventTime(e.target.value)}
            />
          </div>

          {/* FAQs input */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">FAQs</label>
            {faqs.map((faq, index) => (
              <div key={index} className="d-flex align-items-start mb-2">
                <div className="w-100">
                  <input
                    type="text"
                    placeholder="Question"
                    value={faq.question}
                    className="form-control mb-2"
                    onChange={(e) => {
                      const updatedFaqs = [...faqs];
                      updatedFaqs[index].question = e.target.value;
                      setFaqs(updatedFaqs);
                    }}
                  />
                  <textarea
                    placeholder="Answer"
                    value={faq.answer}
                    className="form-control mb-2"
                    style={{
                      height: "72px",
                      lineHeight: "1.3",
                    }}
                    onChange={(e) => {
                      const updatedFaqs = [...faqs];
                      updatedFaqs[index].answer = e.target.value;
                      setFaqs(updatedFaqs);
                    }}
                  />
                </div>
                <button
                  className="btn btn-danger"
                  onClick={() => removeArrayItem(setFaqs, faqs, index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              className="btn btn-secondary mt-2"
              onClick={() =>
                addArrayItem(setFaqs, faqs, { question: "", answer: "" })
              }
            >
              Add FAQ
            </button>
          </div>
          {/* After RSVP Submitted Text input */}
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">After RSVP Submitted Text</label>
            <textarea
              value={afterSubmittedText}
              className="form-control"
              style={{ height: "80px", lineHeight: "1.3" }}
              onChange={(e) => setAfterSubmittedText(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">Footer Image</label>
            <div className="example-text">
              A full width image to be displayed at the bottom of the page.
            </div>
            {footerImages &&
              footerImages.map((image, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <img
                    src={image}
                    alt={`Footer Image ${index + 1}`}
                    className="img-thumbnail"
                  />
                  <button
                    className="btn btn-danger ml-2"
                    onClick={() =>
                      removeArrayItem(setFooterImages, footerImages, index)
                    }
                  >
                    Remove
                  </button>
                </div>
              ))}
            {loading && (
              <img
                src={imageUploadLoader}
                alt="Uploading..."
                width="100"
                className="img-thumbnail"
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleImageUpload(e, setFooterImages, { compress: true })
              }
              className="d-none"
              id="footerImageUpload"
            />

            {footerImages && footerImages.length ? (
              <></>
            ) : (
              <button
                className="btn btn-secondary mt-2"
                onClick={() =>
                  document.getElementById("footerImageUpload").click()
                }
              >
                Upload Footer Image
              </button>
            )}
          </div>
          <div className="form-group mb-3 mt-3 col-md-12">
            <label className="form-label">Theme Color</label>
            <div className="example-text">
              This background color is used to accent any buttons and dropdown
              toggles.
            </div>
            <input
              type="color"
              value={themePrimaryColor}
              className="form-control"
              onChange={(e) => setThemePrimaryColor(e.target.value)}
              style={{ maxWidth: "100px" }}
            />
          </div>
          <div className="form-group col-md-12 dynamic-check-option-container">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="hideLandmarkFooter"
                checked={!hideLandmarkFooter}
                onChange={(e) => setHideLandmarkFooter(!e.target.checked)}
              />
              <label className="form-check-label" htmlFor="hideLandmarkFooter">
                Show Landmark Footer
              </label>
            </div>
          </div>
          <div className="example-text">
            When checked, the Landmark Ventures footer will be displayed.
          </div>
          <div className="form-group col-md-12 dynamic-check-option-container">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="hideDietaryInput"
                checked={hideDietaryInput}
                onChange={(e) => setHideDietaryInput(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="hideDietaryInput">
                Hide Dietary Restrictions Question
              </label>
            </div>
          </div>
          <div className="example-text">
            When checked, the 'Dietary Restrictions' form question will be
            hidden.
          </div>

          <div className="form-group col-md-12 dynamic-check-option-container">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="hideDietaryInput"
                checked={showCustomInput}
                onChange={(e) => setShowCustomInput(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="hideDietaryInput">
                Show Custom Question
              </label>
            </div>
          </div>
          <div className="example-text">
            When checked, a custom form question will be displayed.
          </div>

          {showCustomInput ? (
            <div className="custom-question-container">
              <div className="form-group col-md-12">
                <label className="form-label">Custom Question</label>
                <input
                  type="text"
                  value={customInputLabel}
                  className="form-control"
                  onChange={(e) => setCustomInputLabel(e.target.value)}
                />
              </div>

              <div className="form-group col-md-12 dynamic-check-option-container">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customInputRequired"
                    checked={customInputRequired}
                    onChange={(e) => setCustomInputRequired(e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customInputRequired"
                  >
                    Make Required
                  </label>
                </div>
              </div>
              <div className="example-text">
                When checked, the user must answer this question to submit the
                form.
              </div>
            </div>
          ) : null}

          {/* Google Policy Options Checkbox */}
          <div className="form-group col-md-12 dynamic-check-option-container">
            {/* <label className="form-label">Google Policy Options</label> */}
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="googlePolicyOptions"
                checked={googlePolicyOptions}
                onChange={(e) => setGooglePolicyOptions(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="googlePolicyOptions">
                Show Google Options
              </label>
            </div>
          </div>
          <div className="example-text">
            When checked, the RSVP form will include the Google Cloud data
            policy disclaimer and the optional 'Receive Marketing from Google'
            checkbox. If your event is not associated with Google then uncheck
            this box.
          </div>

          {/* Apollo Autofill Checkbox */}
          <div className="form-group col-md-12 dynamic-check-option-container">
            {/* <label className="form-label">Apollo Autofill</label> */}
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="apolloAutofill"
                checked={apolloAutofill}
                onChange={(e) => setApolloAutofill(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="apolloAutofill">
                Enable Apollo Autofill
              </label>
            </div>
            <div className="example-text">
              When checked, the RSVP form will attempt to autofill the user's
              data by searching their email on the Apollo database. Each search
              uses an Apollo credit so we recommending disabling this for large
              events where we are expecting 500+ RSVPs.
            </div>
          </div>

          {/* Referral URLs Management */}
          <div
            className="form-group mb-3 col-md-12"
            style={{ marginTop: "20px" }}
          >
            <label className="form-label">Referral URLs</label>
            <div className="example-text">
              When users sign up through these URLs, we can track who referred
              them. The referral URL can be composed of random characters or
              personalized with the client’s name (e.g. 'atomiton'). If you'd
              prefer the URL to be less obvious, you can use generic terms like
              'event' or 'sign-up' to make it appear more discreet and avoid
              revealing the tracking purpose.
            </div>
            {referralUrls.map((referral, index) => {
              let thisReferralUrlValid = referral.url.trim().length;
              let thisReferralNameValid = referral.name.trim().length;

              // check for existing (duplicate) url
              if (
                referralUrls.filter(
                  (r) =>
                    r.url.trim().toLowerCase() ===
                    referral.url.trim().toLowerCase()
                ).length > 1
              ) {
                thisReferralUrlValid = false;
              }

              return (
                <div
                  key={index}
                  className="d-flex align-items-start mb-2 p-relative"
                  style={{ marginTop: `${index === 0 ? "0" : "25px"}` }} // add some margin for each ref url
                >
                  <div className="w-100">
                    {/* URL input with prefix */}
                    {/* <label className="form-label">URL</label> */}

                    <div className="input-group mb-2 ">
                      <span className="input-group-text ref-url">
                        https://events.landmarkventures.com/{pageUrl}/
                      </span>
                      <input
                        type="text"
                        value={referral.url}
                        className={`form-control page-url ${
                          thisReferralUrlValid ? "" : "invalid"
                        }`}
                        onChange={(e) => {
                          const updatedUrls = [...referralUrls];
                          updatedUrls[index].url = sanitizePageUrl(
                            e.target.value
                          );
                          setReferralUrls(updatedUrls);
                        }}
                      />
                    </div>
                    {/* <label className="form-label">Name</label> */}
                    <div className="example-text">
                      Name to appear in the 'Referred By' column in our backend
                      when the URL is used:
                    </div>
                    <input
                      type="text"
                      value={referral.name}
                      className={`form-control ref-name mb-2 ${
                        thisReferralNameValid ? "" : "invalid"
                      }`}
                      placeholder="Referral Name"
                      onChange={(e) => {
                        const updatedUrls = [...referralUrls];
                        updatedUrls[index].name = e.target.value;
                        setReferralUrls(updatedUrls);
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-danger remove-ref-url-btn"
                    onClick={() =>
                      removeArrayItem(setReferralUrls, referralUrls, index)
                    }
                  >
                    Remove
                  </button>
                </div>
              );
            })}
            {/* Add new Referral URL button */}
            <button
              className="btn btn-secondary mt-2"
              onClick={() =>
                addArrayItem(setReferralUrls, referralUrls, {
                  url: "",
                  name: "",
                })
              }
            >
              {referralUrls && referralUrls.length
                ? "Add Another Referral URL"
                : "Add Referral URL"}
            </button>
          </div>

          {/* Event Owners */}
          <div
            className="form-group mb-3 col-md-12"
            style={{ marginTop: "20px" }}
          >
            <label className="form-label">
              Receive Notification on Sign Up
            </label>
            <div className="example-text">
              The following email addresses will receive an email notification
              whenever a form submission is received.
            </div>
            {owners.map((owner, index) => {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              let thisEmailValid = emailRegex.test(owner.email);

              // check for existing (duplicate) email
              if (
                owners.filter(
                  (o) =>
                    o.email.trim().toLowerCase() ===
                    owner.email.trim().toLowerCase()
                ).length > 1
              ) {
                thisEmailValid = false;
              }
              return (
                <div key={index} className="d-flex align-items-center mb-2">
                  <input
                    type="text"
                    value={owner.email}
                    className={`form-control owner-email ${
                      thisEmailValid ? "" : "invalid"
                    }`}
                    onChange={(e) => {
                      const updatedOwners = [...owners];
                      updatedOwners[index] = {
                        email: e.target.value.trim(),
                        assignedTime: timeNow(),
                      };
                      setOwners(updatedOwners);
                    }}
                  />
                  <button
                    className="btn btn-danger ml-2"
                    onClick={() => removeArrayItem(setOwners, owners, index)}
                  >
                    Remove
                  </button>
                </div>
              );
            })}
            <button
              className="btn btn-secondary mt-2"
              onClick={() =>
                addArrayItem(setOwners, owners, {
                  email: "",
                  assignedTime: timeNow(),
                })
              }
            >
              Add Email
            </button>
          </div>

          {/* Apollo Autofill Checkbox */}
          <div className="form-group col-md-12 dynamic-check-option-container">
            {/* <label className="form-label">Apollo Autofill</label> */}
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="registrationClosed"
                checked={registrationClosed}
                onChange={(e) => setRegistrationClosed(e.target.checked)}
              />
              <label
                className="form-check-label close-registrations-txt"
                htmlFor="registrationClosed"
              >
                Close Registrations
              </label>
            </div>
            <div className="example-text">
              When checked, no new RSVPs will be accepted, and the page will
              display a "Registration is now closed" message.
            </div>
          </div>
        </div>

        <div className="divider"></div>
        {!referralUrlValid ? (
          <div className="d-flex align-items-center justify-content-center mb-2">
            <div className="page-url-invalid-msg mb-3">
              {referralUrlInvalidMsg}
            </div>
          </div>
        ) : null}

        {!ownerEmailValid ? (
          <div className="d-flex align-items-center justify-content-center mb-2">
            <div className="page-url-invalid-msg mb-3">
              {ownerEmailInvalidMsg}
            </div>
          </div>
        ) : null}
        {!pageUrlValid ? (
          <div className="d-flex align-items-center justify-content-center mb-2">
            <div className="page-url-invalid-msg mb-3">{pageUrlInvalidMsg}</div>
          </div>
        ) : null}
        <div className="d-flex align-items-center justify-content-center mb-2">
          <button
            className="create-event-btn page-preview-btn btn btn-primary mb-3 "
            onClick={toggleShowPreview}
          >
            {`${showPreview ? "Hide Page Preview" : "Show Page Preview"}`}
            {showPreview ? (
              <i className="event-owner-add fa-sharp fa-solid fa-eye-slash ml-05"></i>
            ) : (
              <i className="event-owner-add fa-sharp fa-solid fa-eye ml-05"></i>
            )}
          </button>
          {referralUrlValid && ownerEmailValid && pageUrlValid ? (
            <button
              className="create-event-btn save-event-btn btn btn-primary mb-3 "
              onClick={handleSaveEvent}
            >
              {selectedEvent === null ? "Save Event" : "Save Changes"}
              <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
            </button>
          ) : null}
        </div>
      </div>
      {showPreview ? (
        <div className="dynamic-event-preview-container">
          <div className="events-client-wrapper">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.7 }}
            >
              <EventsDynamic event={getEventObj()} />
            </motion.div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const AddEditEvent = ({ events, getEvents, password, employee }) => {
  const [showForm, setShowForm] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTemplateEvent, setSelectedTemplateEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalPageUrl, setModalPageUrl] = useState("");

  function updateSetShowModal(show, pageUrl) {
    if (pageUrl) {
      setModalPageUrl(pageUrl);
    }
    setShowModal(show);
  }

  const toggleSetShowForm = () => {
    setShowForm(!showForm);
  };

  const handleSetCreateMode = () => {
    setEditMode(false);
    setCreateMode(true);
    setSelectedEvent(null);
    setSelectedTemplateEvent(null);
  };

  const handleSetEditMode = () => {
    setCreateMode(false);
    setEditMode(true);
    setSelectedTemplateEvent(null);
  };

  const resetModes = () => {
    setCreateMode(false);
    setEditMode(false);
    setSelectedEvent(null);
    setSelectedTemplateEvent(null);
  };

  console.log(`selectedEvent`, selectedEvent);

  return (
    <>
      <div className="add-edit-btn-container">
        {!showForm && (
          <>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetCreateMode}
            >
              {`Create New Event`}
              <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
            </button>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetEditMode}
            >
              {`Edit Existing Event`}
              <i className="event-owner-add fa-sharp fa-solid fa-pencil ml-05"></i>
            </button>
          </>
        )}
      </div>
      {editMode ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="row">
              <div className="outputHeader">Edit Event</div>
              <>
                <div className="events-owners-subheading">
                  Select Event to Edit
                </div>
                <div
                  className="event-owners-wrapper"
                  style={{ marginBottom: "12px" }}
                >
                  {events.map((event) => {
                    const selectedClass =
                      selectedEvent && event.eventId === selectedEvent.eventId
                        ? "selected"
                        : "";
                    return (
                      <div key={event.eventId}>
                        <div
                          className={`event-owner-container selectable ${selectedClass}`}
                          onClick={() => setSelectedEvent(event)}
                        >
                          {/* <img
                            src={event.imageUrl}
                            className="img-fluid event-img"
                          /> */}
                          <div className="event-title">{event.title}</div>
                          <div className="event-title blue-text">
                            {`/${event.pageUrl}`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {selectedEvent ? (
                  <>
                    <EventForm
                      selectedEvent={selectedEvent}
                      password={password}
                      getEvents={getEvents}
                      events={events}
                      employee={employee}
                      updateSetShowModal={updateSetShowModal}
                      resetModes={resetModes}
                    />
                  </>
                ) : null}
              </>
            </div>
          </Card>
        </motion.div>
      ) : null}
      {createMode ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="row">
              <div className="outputHeader">Create VIP Dinner Event</div>
              <>
                {/* <div className="events-owners-subheading">
                </div> */}
                <div className="events-owners-subheading">
                  Load settings from existing event
                </div>
                <div
                  className="event-owners-wrapper"
                  style={{ marginBottom: "12px" }}
                >
                  {events.map((event) => {
                    const selectedClass =
                      selectedTemplateEvent &&
                      event.eventId === selectedTemplateEvent.eventId
                        ? "selected"
                        : "";
                    return (
                      <div key={event.eventId}>
                        <div
                          className={`event-owner-container selectable ${selectedClass}`}
                          onClick={() => setSelectedTemplateEvent(event)}
                        >
                          {/* <img
                            src={event.imageUrl}
                            className="img-fluid event-img"
                          /> */}
                          <div className="event-title">{event.title}</div>
                          <div className="event-title blue-text">
                            {`/${event.pageUrl}`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <>
                  <EventForm
                    selectedEvent={selectedEvent}
                    selectedTemplateEvent={selectedTemplateEvent}
                    password={password}
                    getEvents={getEvents}
                    employee={employee}
                    events={events}
                    updateSetShowModal={updateSetShowModal}
                    resetModes={resetModes}
                  />
                </>
              </>
            </div>
          </Card>
        </motion.div>
      ) : null}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Event Saved</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-event-header-msg">
            {" "}
            Event has been saved successfully!
          </div>

          <br />
          <div className="modal-view-landing-text">
            View the landing page here:{" "}
            <a
              href={`https://events.landmarkventures.com/${modalPageUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`https://events.landmarkventures.com/${modalPageUrl}`}
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="btn btn-primary modal-view-event-close-btn"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">Events</div>
            <>
              <div className="events-owners-subheading">
                Select Event to view RSVPs
              </div>
              <div
                className="event-owners-wrapper"
                style={{ marginBottom: "12px" }}
              >
                {events.map((event) => {
                  const selectedClass =
                    selectedEvent && event.eventId === selectedEvent.eventId
                      ? "selected"
                      : "";
                  return (
                    <div key={event.eventId}>
                      <div
                        className={`event-owner-container selectable ${selectedClass}`}
                        onClick={() => setSelectedEvent(event)}
                      >
                        <img
                          src={event.imageUrl}
                          className="img-fluid event-img"
                        />
                        <div className="event-title">{event.title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

export default AddEditEvent;
