import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import {
  copyTextToClipboard,
  timeNow,
  useDocumentTitle,
  epochToDateObj,
  epochFromDate,
  uniqueGuid,
  toCamelCase,
  fastHash,
} from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";
import imageUploadLoader from "../../../../images/misc/loader-light-blue-bg.gif";
import Papa from "papaparse"; // Ensure PapaParse is installed

const timezoneOptions = ["PT"];

const SweeperForm = ({
  selectedSweeper,
  password,
  getSweepers,
  resetModes,
  contacts,
  setContacts,
}) => {
  const [title, setTitle] = useState(
    selectedSweeper ? selectedSweeper.title : ""
  );
  const [description, setDescription] = useState(
    selectedSweeper ? selectedSweeper.description : ""
  );
  const [headerMap, setHeaderMap] = useState(
    selectedSweeper && selectedSweeper.headerMap
      ? selectedSweeper.headerMap
      : []
  ); // Stores original headers and their camelCase equivalents
  // const [data, setData] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);

  const [loading, setLoading] = useState(false);

  async function addOrEditSweeper(sweeper, editMode = false) {
    try {
      const api = new ApiService({ password });
      const url = editMode
        ? "landmark/sweeper/editSweeper"
        : "landmark/sweeper/addSweeper";
      const sweepersResponse = await api.post(url, {
        sweeper,
      });
      console.log(`addOrEditSweeper() - ${url} - response`, sweepersResponse);
      const { success, error } = sweepersResponse;
      if (!success) {
        toast.error("An error occurred");
      } else if (error) {
        toast.error(error);
      } else {
        // TODO: update data
        // landmark/sweeper/editSweeperContacts
        //sweeperId, data

        toast.success(success);
        // refresh sweepers
        getSweepers();
        resetModes(); // exit the edit/create form
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleCsvUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true); // Start loading

    try {
      // Read the CSV file
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvText = e.target.result;

        Papa.parse(csvText, {
          header: true, // Ensures first row is treated as headers
          skipEmptyLines: true,
          complete: (result) => {
            if (result.errors.length) {
              toast.error("Error parsing CSV file");
              console.error("CSV Parsing Errors:", result.errors);
              setLoading(false);
              return;
            }

            const rawData = result.data.map((row) => ({
              ...row,
              id: row["Business Email"]
                ? fastHash(row["Business Email"].toLowerCase().trim())
                : "", // Add id field
            }));

            const newHeaders = [...result.meta.fields, "id"]; // Ensure 'email' is included in headers

            setHeaderMap((prevHeaderMap) => {
              // Convert existing headers to a Set for quick lookup
              let existingHeaders = new Set();
              if (prevHeaderMap) {
                prevHeaderMap.forEach((h) => existingHeaders.add(h.original));
              } else {
                prevHeaderMap = [];
              }

              // Append only new headers that don’t already exist
              const newMappings = newHeaders
                .filter((header) => !existingHeaders.has(header)) // Only add if not already in list
                .map((header) => ({
                  original: header,
                  camelCase: toCamelCase(header),
                }));

              return [...prevHeaderMap, ...newMappings];
            });

            // Convert rawData keys to camelCase using the updated headerMap
            setHeaderMap((updatedHeaderMap) => {
              const formattedData = rawData.map((row) => {
                let newRow = {};
                updatedHeaderMap.forEach(({ original, camelCase }) => {
                  newRow[camelCase] = row[original]; // Assign values using camelCase keys
                });
                return newRow;
              });

              setContacts((prevData) => {
                // Convert existing email hashes to a Set for quick lookup
                const existingEmails = new Set(prevData.map((item) => item.id));

                // Filter out duplicates based on email hash
                const filteredData = formattedData.filter(
                  (item) => item.id && !existingEmails.has(item.id)
                );

                return [...prevData, ...filteredData]; // Append unique entries
              });

              toast.success("CSV uploaded successfully");
            });
          },
        });
      };

      reader.readAsText(file);
    } catch (error) {
      console.error("Error processing CSV file:", error);
      toast.error("Error processing CSV file");
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleSaveSweeper = () => {
    // scrape details from state
    const sweeper = {
      title,
      description,
      data: contacts,
      headerMap,
    };
    // saving an existing sweeper - use edit endpoint
    if (selectedSweeper && selectedSweeper.sweeperId) {
      sweeper.sweeperId = selectedSweeper.sweeperId;
      // edit sweeper
      addOrEditSweeper(sweeper, true);
    } else {
      // add sweeper
      addOrEditSweeper(sweeper, false);
    }
  };

  useEffect(() => {
    // new sweeper created
    if (!selectedSweeper || !selectedSweeper.sweeperId) {
      setTitle("");
      setDescription("");
    }
    // existing sweeper obj
    else {
      setTitle(selectedSweeper.title);
      setDescription(selectedSweeper.description);
    }
  }, [selectedSweeper]);

  // called when user has saved an option

  const newOptionMode =
    selectedOption !== null &&
    !selectedOption.optionId &&
    !selectedOption.tempOptionId
      ? true
      : false; // user is creating a new option or editing existing

  const saveBtnText =
    selectedSweeper === null ? "Save Project" : "Save Changes";

  // show save btn if in create new project mode, or the selected sweeper has been edited
  const showSaveBtn =
    !selectedSweeper ||
    selectedSweeper.title !== title ||
    selectedSweeper.description !== description ||
    selectedSweeper.data !== contacts;

  return (
    <div className="event-form">
      <div className="row">
        <div className="form-group mb-3 col-md-12">
          <label className="form-label">
            Title <span className="example-text">(e.g. Jasper clients)</span>
          </label>
          <input
            type="text"
            value={title}
            className="form-control"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group mb-3 col-md-12">
          <label className="form-label">
            Description{" "}
            <span className="example-text">
              (e.g. Cleaning list for Jasper. Banking clients only)
            </span>
          </label>
          <input
            type="text"
            value={description}
            className="form-control"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="form-group mb-3 col-md-12">
          <label className="form-label">
            Contacts <br />
            <span className="example-text">Upload exported Apollo lists</span>
            {contacts && contacts.length ? (
              <>
                <br />
                <span className="example-text">
                  You can upload multiple lists, duplicates will be
                  automatically filtered out
                </span>
              </>
            ) : null}
          </label>
          {contacts && contacts.length ? (
            <>
              <br />
              <label className="form-label">{contacts.length} entries</label>
            </>
          ) : null}

          {loading && (
            <img
              src={imageUploadLoader}
              alt="Uploading..."
              width="100"
              className="img-thumbnail"
            />
          )}

          <>
            {" "}
            <input
              type="file"
              accept=".csv"
              className="d-none"
              id="csvFileUpload"
              onChange={(e) => handleCsvUpload(e)}
            />
            <div className="d-flex flex-row">
              <button
                className="btn btn-secondary btn-sm mt-2"
                onClick={() => document.getElementById("csvFileUpload").click()}
              >
                Upload List
                <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
              </button>
              {contacts && contacts.length ? (
                <>
                  <button
                    className="btn btn-danger btn-sm mt-2"
                    onClick={() => setContacts([])}
                  >
                    Delete Data
                    {/* <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i> */}
                  </button>
                </>
              ) : null}
            </div>
            {/* <label className="form-label">List should be exported in </label> */}
          </>
          {/* <input
            type="text"
            value={imageUrl}
            className="form-control"
            onChange={(e) => setImageUrl(e.target.value)}
          /> */}
        </div>

        {/* <div className="form-group mb-3 col-md-12">
          <div className="options-wrapper">
            <label className="form-label">
              Sweeper options which the attendee can request to attend<br></br>
              <span className="example-text">
                (e.g. VIP Brunch or Cocktail Reception)
              </span>
            </label>
            <label className="form-label">
              Click on an existing option to edit it or add a new option.
            </label>
            <div className="edit-options-container">
              {options.map((o) => {
                const selClass = o === selectedOption ? "selected" : "";
                return (
                  <div
                    className={`option-container ${selClass}`}
                    onClick={() => {
                      console.log("selectedOption", o);
                      setSelectedOption(o);
                    }}
                  >
                    <div className="title">{o.title}</div>
                    <div className="description">{o.description}</div>
                  </div>
                );
              })}
              <div
                className={`option-container new text-center ${
                  newOptionMode ? "selected" : ""
                }`}
                onClick={() => {
                  console.log("selectedOption", {});
                  setSelectedOption({});
                }}
              >
                <div className="title text-center">
                  New Option{" "}
                  <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {showSaveBtn ? (
        <div className="centered-row">
          <button
            className={`create-event-btn btn btn-sm btn-primary mb-3 save-sweeper-btn`}
            onClick={handleSaveSweeper}
          >
            {saveBtnText}
            <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
          </button>
        </div>
      ) : null}
    </div>
  );
};

const AddEditSweeper = ({
  password,
  // getSweepers,
  // sweepers,
  // setSweepers,
  selectedSweeper,
  setSelectedSweeper,
  contacts,
  setContacts,
}) => {
  const [sweepers, setSweepers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // const [selectedSweeper, setSelectedSweeper] = useState(null);

  async function getSweepers() {
    try {
      const api = new ApiService({ password });
      const tag = "global";
      const sweepersResponse = await api.get(`landmark/sweeper/getSweepers`);
      console.log(`getSweepers()`, sweepersResponse);
      setSweepers(sweepersResponse);
      setSelectedSweeper(null);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getSweepers();
  }, []);

  const toggleSetShowForm = () => {
    setShowForm(!showForm);
  };

  const handleSetCreateMode = () => {
    setEditMode(false);
    setCreateMode(true);
    setSelectedSweeper(null);
  };

  const handleSetEditMode = () => {
    setCreateMode(false);
    setEditMode(true);
  };

  const resetModes = () => {
    setCreateMode(false);
    setEditMode(false);
    setSelectedSweeper(null);
  };

  return (
    <>
      <div className="add-edit-btn-container">
        {!showForm && (
          <>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetCreateMode}
            >
              {`Create New Project`}
              <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
            </button>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetEditMode}
            >
              {`Edit Existing Project`}
              <i className="event-owner-add fa-sharp fa-solid fa-pencil ml-05"></i>
            </button>
          </>
        )}
      </div>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">Projects</div>
            <>
              <div className="events-owners-subheading">
                Select an existing project or create a new one
              </div>
              <div
                className="event-owners-wrapper"
                style={{ marginBottom: "12px" }}
              >
                {sweepers.map((sweeper) => {
                  const selectedClass =
                    selectedSweeper &&
                    sweeper.sweeperId === selectedSweeper.sweeperId
                      ? "selected"
                      : "";
                  return (
                    <div key={sweeper.sweeperId}>
                      <div
                        className={`event-owner-container selectable ${selectedClass}`}
                        onClick={() => setSelectedSweeper(sweeper)}
                      >
                        <img
                          src={sweeper.imageUrl}
                          className="img-fluid event-img"
                        />
                        <div className="event-title">{sweeper.title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {selectedSweeper ? (
                <>
                  <SweeperForm
                    selectedSweeper={selectedSweeper}
                    password={password}
                    getSweepers={getSweepers}
                    resetModes={resetModes}
                    contacts={contacts}
                    setContacts={setContacts}
                  />
                </>
              ) : null}
              {createMode ? (
                <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                  <Card className="mb-3 h-fc bot-table">
                    <div className="row">
                      <div className="outputHeader">New Project</div>
                      <>
                        {/* <div className="events-owners-subheading">
                </div> */}

                        <>
                          <SweeperForm
                            selectedSweeper={selectedSweeper}
                            password={password}
                            getSweepers={getSweepers}
                            // employee={employee}
                            sweepers={sweepers}
                            // updateSetShowModal={updateSetShowModal}
                            resetModes={resetModes}
                            contacts={contacts}
                            setContacts={setContacts}
                          />
                        </>
                      </>
                    </div>
                  </Card>
                </motion.div>
              ) : null}
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

export default AddEditSweeper;
