import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import CodeEditor from "@uiw/react-textarea-code-editor";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { ExportJsonCsv } from "react-export-json-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import {
  areSetsEqual,
  copyTextToClipboard,
  useDocumentTitle,
} from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  addOneMonth,
  arraysAreEqual,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";

const StatusEnum = Object.freeze({
  PENDING: "pending",
  APPROVED: "approved",
  DENIED: "denied",
  WAITLISTED: "waitlisted",
});

function reorderStatusEnum(
  order = ["APPROVED", "WAITLISTED", "DENIED", "PENDING"]
) {
  const reorderedEnum = {};
  for (const status of order) {
    if (status in StatusEnum) {
      reorderedEnum[status] = StatusEnum[status];
    }
  }
  return reorderedEnum;
}

// Define the possible values for the 'attendanceStatus' enum
const AttendanceStatusEnum = Object.freeze({
  ATTENDING: "attending",
  TENTATIVE: "tentative",
  NOT_ATTENDING: "not_attending",
});

const getAttendanceStatusDisplayText = (attendanceStatus) => {
  let badgeText = "";
  if (attendanceStatus === AttendanceStatusEnum.ATTENDING) {
    badgeText = "Yes";
  } else if (attendanceStatus === AttendanceStatusEnum.TENTATIVE) {
    badgeText = "Tentative";
  } else if (attendanceStatus === AttendanceStatusEnum.NOT_ATTENDING) {
    badgeText = "No";
  }
  return badgeText;
};

const getBadgeBgFromStatus = (status) => {
  let badgeBg = "light";
  if (status === StatusEnum.PENDING) {
    badgeBg = "light";
  } else if (status === StatusEnum.APPROVED) {
    badgeBg = "success";
  } else if (status === StatusEnum.DENIED) {
    badgeBg = "danger";
  } else if (status === StatusEnum.WAITLISTED) {
    badgeBg = "warning";
  }
  return badgeBg;
};

const getBadgeClassFromAttendanceStatus = (attendanceStatus) => {
  let badgeClass = "badge-outline-light";
  if (attendanceStatus === AttendanceStatusEnum.ATTENDING) {
    badgeClass = "badge-outline-success";
  } else if (attendanceStatus === AttendanceStatusEnum.TENTATIVE) {
    badgeClass = "badge-outline-warning";
  } else if (attendanceStatus === AttendanceStatusEnum.NOT_ATTENDING) {
    badgeClass = "badge-outline-danger";
  }
  return badgeClass;
};

const ContactStatusUpdatePopup = ({
  attendanceMode = false,
  showPopup,
  setShowPopup,
  contact,
  optionRequest = {},
  position,
  handleUpdateContact,
}) => {
  const [final, setFinal] = useState(optionRequest.final || false);
  const { attendanceStatus, attendanceStatusUpdates } = contact;
  const popupRef = useRef(null);

  useEffect(() => {
    if (showPopup && popupRef.current) {
      // Measure the width of the popup and calculate its left position to center it under the badge.
      const popupWidth = popupRef.current.offsetWidth;
      const popupX = position.x - popupWidth / 2;
      popupRef.current.style.left = `${popupX}px`;
      popupRef.current.style.transform = "scale(0)";

      setTimeout(() => {
        popupRef.current.style.transform = "scale(1)";
      }, 100);
    } else if (popupRef.current) {
      popupRef.current.style.transform = "scale(0)";
    }
  }, [showPopup]);

  const handleBadgeClick = (newStatus) => {
    handleUpdateContact(newStatus, final);
  };

  const handleFinalChange = (event) => {
    setFinal(event.target.checked);
    handleUpdateContact(optionRequest.status, event.target.checked);
  };

  // option updates
  const optionUpdateHistory = { status: null, final: null };
  if (!attendanceMode && optionRequest && optionRequest.statusUpdates) {
    // reverse list so most recent updates are at the start
    const statusUpdates = [...optionRequest.statusUpdates].reverse();
    // marked as final so find most recent final mark
    if (optionRequest.final) {
      let lastFinalUpdate = null;
      // look for a status request which has final: true and remember it, then look for a final: false, when a final: false is found, break
      for (let i = 0; i < statusUpdates.length; i++) {
        const update = statusUpdates[i];
        if (update.final) {
          lastFinalUpdate = update;
        } else if (!update.final) {
          break;
        }
      }
      optionUpdateHistory.final = lastFinalUpdate;
    }
    // get last status change
    let lastStatusUpdate = null;
    for (let i = 0; i < statusUpdates.length; i++) {
      const update = statusUpdates[i];
      if (update.status === optionRequest.status) {
        lastStatusUpdate = update;
      } else if (update.status !== optionRequest.status) {
        break;
      }
    }
    optionUpdateHistory.status = lastStatusUpdate;
  }

  // attendance updates
  const attendanceUpdateHistory = { status: null };
  if (
    attendanceMode &&
    attendanceStatusUpdates &&
    attendanceStatusUpdates.length
  ) {
    // reverse list so most recent updates are at the start
    const statusUpdates = [...attendanceStatusUpdates].reverse();
    // get last status change
    let lastStatusUpdate = null;
    for (let i = 0; i < statusUpdates.length; i++) {
      const update = statusUpdates[i];
      if (update.attendanceStatus === attendanceStatus) {
        lastStatusUpdate = update;
      } else if (update.attendanceStatus !== attendanceStatus) {
        break;
      }
    }
    attendanceUpdateHistory.status = lastStatusUpdate;
  }

  const AttendanceUpdater = () => {
    return (
      <>
        <div className="badge-options">
          {Object.entries(AttendanceStatusEnum).map(([key, status]) => {
            if (status === attendanceStatus) {
              return null; // option already selected
            }
            console.log(`status`, status);
            return (
              <div className="badge-wrapper" key={key}>
                <Badge
                  bg={null}
                  className={`status-badge hvr-shrink ${getBadgeClassFromAttendanceStatus(
                    status
                  )}`}
                  onClick={() => handleBadgeClick(status)}
                >
                  {getAttendanceStatusDisplayText(status)}
                </Badge>
              </div>
            );
          })}
        </div>
        {attendanceUpdateHistory.status ? (
          <div className="option-update-history rmono">
            {attendanceUpdateHistory.status && (
              <>
                <div className="update-line">
                  <span className="">
                    {getAttendanceStatusDisplayText(
                      attendanceUpdateHistory.status.attendanceStatus
                    )}
                  </span>{" "}
                  by {attendanceUpdateHistory.status.employeeName}
                </div>
                <div className="update-line-time rmono">
                  {epochToDateTimeString(
                    attendanceUpdateHistory.status.updateTime
                  )}
                </div>
              </>
            )}
          </div>
        ) : null}
      </>
    );
  };

  const OptionRequestUpdater = () => {
    return (
      <>
        <div className="checkbox-wrapper d-flex justify-content-center align-items-center">
          <label className="d-flex justify-content-center align-items-center">
            <input
              type="checkbox"
              checked={final}
              onChange={handleFinalChange}
              style={{ marginRight: `4px` }}
            />
            Mark as Final
          </label>
        </div>
        <div className="badge-options">
          {Object.entries(StatusEnum).map(([key, status]) => {
            if (status === optionRequest.status) {
              return null; // option already selected
            }
            return (
              <div className="badge-wrapper" key={key}>
                <Badge
                  bg={getBadgeBgFromStatus(status)}
                  className="status-badge hvr-shrink"
                  onClick={() => handleBadgeClick(status)}
                >
                  {toTitleCase(status)}
                </Badge>
              </div>
            );
          })}
        </div>
        {optionUpdateHistory.status || optionUpdateHistory.final ? (
          <div className="option-update-history rmono">
            {optionUpdateHistory.final && (
              <>
                <div className="update-line">
                  <span className="">Final</span> by{" "}
                  {optionUpdateHistory.final.employeeName}
                </div>
                <div className="update-line-time rmono">
                  {epochToDateTimeString(optionUpdateHistory.final.updateTime)}
                </div>
              </>
            )}
            {optionUpdateHistory.status && (
              <>
                <div className="update-line">
                  <span className="">
                    {toTitleCase(optionUpdateHistory.status.status)}
                  </span>{" "}
                  by {optionUpdateHistory.status.employeeName}
                </div>
                <div className="update-line-time rmono">
                  {epochToDateTimeString(optionUpdateHistory.status.updateTime)}
                </div>
              </>
            )}
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <div
        ref={popupRef}
        className="popup-container"
        style={{
          top: position.y + 10,
          left: position.x,
        }}
        onMouseLeave={() => {
          if (popupRef.current) {
            popupRef.current.style.transform = "scale(0)";
          }
          setTimeout(() => {
            setShowPopup(false);
          }, 150);
        }}
      >
        {attendanceMode && <AttendanceUpdater />}
        {!attendanceMode && <OptionRequestUpdater />}
      </div>
    </>
  );
};

const CommentPopup = ({ contact, onSubmitComment }) => {
  const [commentText, setCommentText] = useState("");
  const [replyText, setReplyText] = useState("");
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [unsortedComments, setUnsortedComments] = useState(
    contact ? contact.comments : []
  );

  useEffect(() => {
    if (contact && contact.comments) {
      setUnsortedComments(contact.comments);
    }
  }, [contact]);

  function sortComments(comments) {
    if (!comments || !comments.length) {
      return [];
    }
    const orderedComments = comments
      .slice()
      .sort((a, b) => b.createdTime - a.createdTime);
    const rootComments = orderedComments.filter((c) => !c.isReply);
    const replyComments = orderedComments.filter((c) => c.isReply).reverse(); // reverse order so replies are shown cronologically
    const sortedRootComments = rootComments.map((c) => {
      // find replies to this comment and append them
      const replies = replyComments.filter(
        (r) => r.replyCommentId === c.commentId
      );
      c.replyComments = replies;
      return c;
    });
    const sorted = [];
    sortedRootComments.forEach((c) => {
      // push original comment
      sorted.push(c);
      if (c.replyComments && c.replyComments.length) {
        // push all replies
        c.replyComments.forEach((r) => sorted.push(r));
      }
    });
    return sorted;
  }
  // sort comments
  const comments = sortComments(unsortedComments);

  // const [comments, setComments] = useState([
  //   {
  //     commentId: "afghuifai",
  //     employeeId: "523251dd",
  //     employeeName: "Zeev Klein",
  //     content:
  //       "Test comment with any random content wish I had a lorem ipsum generator but als no I do not.",
  //     createdTime: 1693046478,
  //     isReply: false,
  //     replyCommentId: null,
  //     // seenBy: [
  //     //   {
  //     //     employeeId: String,
  //     //     employeeName: String,
  //     //     seenTime: Number,
  //     //   },
  //     // ],
  //   },
  //   ])

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleReplyChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleCreateComment = () => {
    if (commentText) {
      const success = onSubmitComment(
        contact.contactInvoiceId,
        commentText,
        false,
        null
      );
      if (success) {
        setCommentText("");
      }
    }
  };

  const handleCancel = () => {
    // onClose();
  };

  const handleReply = (commentIndex) => {
    setShowReplyInput(commentIndex);
  };

  const handleCreateReply = (replyCommentId) => {
    if (replyText) {
      const success = onSubmitComment(
        contact.contactInvoiceId,
        replyText,
        true,
        replyCommentId
      );
      if (success) {
        setReplyText("");
        setShowReplyInput(false);
      }
    }
  };

  const handleCancelReply = () => {
    setReplyText("");
    setShowReplyInput(false);
  };

  return (
    <div className="comments-popup">
      <div className="add-comment-container">
        <textarea
          placeholder="Write your comment..."
          value={commentText}
          onChange={handleCommentChange}
        />
        <div className="button-container">
          {/* <button className="cancel-button" onClick={handleCancel}>
            Cancel
          </button> */}
          <button className="create-button" onClick={handleCreateComment}>
            Comment
          </button>
        </div>
      </div>
      <div className="horizontal-bar"></div>
      <div className="comments-container">
        {comments.map((comment, index) => (
          <div
            className={`comment ${comment.isReply ? ` reply` : ``}`}
            key={index}
          >
            <div
              className="user-icon"
              style={{
                backgroundColor: stringToRGB(comment.employeeId),
              }}
            >
              <PersonIcon className="icon" />
            </div>
            <div className="comment-content">
              <div className="comment-header">
                <span className="commenter-name">{comment.employeeName}</span>
                <span className="comment-date">
                  {epochToDateTimeString(comment.createdTime)}
                </span>
              </div>
              <div className="comment-text">{comment.content}</div>
              <button
                className="reply-button"
                onClick={() => handleReply(index)}
              >
                Reply
              </button>
              {showReplyInput === index && (
                <div className="add-reply-container">
                  <textarea
                    placeholder="Write your reply..."
                    value={replyText}
                    onChange={handleReplyChange}
                  />
                  <div className="button-container">
                    <button
                      className="cancel-button"
                      onClick={handleCancelReply}
                    >
                      Cancel
                    </button>
                    <button
                      className="create-button"
                      onClick={() => handleCreateReply(comment.commentId)}
                    >
                      Reply
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        {!comments.length ? (
          <div className="no-comments-text">No Comments Yet</div>
        ) : null}
      </div>
    </div>
  );
};

const FilterInput = ({ filterKey, placeholder, handleSetFilter, filters }) => {
  const value = filters[filterKey] ? filters[filterKey] : "";
  return (
    <input
      onChange={(e) => handleSetFilter(filterKey, e.target.value)}
      type="text"
      className="form-control mt-1 filter-input"
      placeholder={placeholder}
      value={value}
    ></input>
  );
};

const FilterOption = ({
  filterKey,
  title,
  optionsEnum,
  handleSetFilter,
  filters,
}) => {
  const value = filters[filterKey] ? filters[filterKey] : "All";
  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">
          <div className="option-title">{title}: </div>{" "}
        </span>
        <select
          defaultValue={"All"}
          className="form-control rmono"
          onChange={(e) => handleSetFilter(filterKey, e.target.value)}
          value={value}
        >
          <option>All</option>
          {Object.entries(optionsEnum).map(([key, status]) => {
            return <option>{toTitleCase(status)}</option>;
          })}
        </select>
      </div>
    </div>
  );
};

const FilterCheckbox = ({ title, checked, handleChecked }) => {
  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">
          <div className="option-title">{title}</div>
        </span>
        <div
          className="form-check custom-checkbox checkbox-success check-lg bs_exam_topper"
          // style={{ marginLeft: "4px" }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id={`FilterCheckbox_${title}`}
            required
            checked={checked}
            onChange={(event) => handleChecked(event)}
          />
        </div>
      </div>
    </div>
  );
};

const ConfirmEmailTemplateEditor = ({ template, getTemplates, password }) => {
  const [title, setTitle] = useState(template ? template.title : "");
  const [emailSubject, setEmailSubject] = useState(
    template ? template.emailSubject : ""
  );
  const [emailBody, setEmailBody] = useState(
    template ? template.emailBody : ""
  );
  const [emailSendFrom, setEmailSendFrom] = useState(
    template ? template.emailSendFrom : ""
  );
  const [emailSendFromName, setEmailSendFromName] = useState(
    template ? template.emailSendFromName : ""
  );

  const [sendFromOptions, setSendFromOptions] = useState([]);

  useEffect(() => {
    // new option created
    if (!template) {
      setTitle("");
      setEmailSubject("");
      setEmailBody("");
      setEmailSendFrom("");
      setEmailSendFromName("");
    }
    // existing option obj
    else {
      setTitle(template.title);
      setEmailSubject(template.emailSubject);
      setEmailBody(template.emailBody);
      setEmailSendFrom(template.emailSendFrom);
      setEmailSendFromName(template.emailSendFromName);
    }
  }, [template]);

  async function getSendFromOptions() {
    try {
      const api = new ApiService({ password });
      const response = await api.get(
        `landmark/emailTemplates/getSendFromOptions`
      );
      console.log(`sendFromOptions`, response);
      setSendFromOptions(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getSendFromOptions();
  }, []);

  const emailHtml = `${emailBody}`;
  //.replace(`{firstName}`)}`;

  // const handleSetOptions = (optionId, emailHtml) => {
  //   const newOptions = options.map((o) => {
  //     if (o.optionId === optionId) {
  //       o.emailHtml = emailHtml;
  //     }
  //     return o;
  //   });
  //   setOptions(newOptions);
  // };

  async function editTemplateEmail() {
    try {
      const api = new ApiService({ password });
      const response = await api.post("landmark/emailTemplates/editTemplate", {
        templateId: template.templateId,
        title,
        emailSubject,
        emailBody,
        emailSendFrom,
        emailSendFromName,
      });
      console.log(
        `editTemplateEmail() - landmark/emailTemplates/editTemplate - response`,
        response
      );
      const { success, error } = response;
      if (!success) {
        toast.error("An error occurred");
      } else if (error) {
        toast.error(error);
      } else {
        toast.success(success);
        // refresh templates
        getTemplates();
      }
    } catch (error) {
      console.error(error);
    }
  }

  function handleSetEmailSendFrom(value) {
    const { name } = sendFromOptions.find((o) => o.value === value);
    // update name when new value is selected
    if (value !== emailSendFrom && name) {
      setEmailSendFromName(name);
    }
    setEmailSendFrom(value);
  }

  return (
    <div className="confirm-email-editor-container">
      <div className="title">Email Template Editor</div>
      <div className="row">
        <div className="col-6">
          <div className="subtitle">Edit Template</div>
          <div className="template-form">
            <div className="row">
              <div className="col-12">
                <button
                  className={`create-event-btn save-changes-btn btn btn-sm btn-primary`}
                  onClick={editTemplateEmail}
                >
                  {`Save Changes`}
                  <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
                </button>
              </div>
              <div className="form-group mb-3 col-12">
                <label className="form-label">
                  Template Title
                  {/* <span className="example-text">e.g. Andrew Kalaigian</span> */}
                </label>
                <input
                  type="text"
                  value={title}
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-12">
                <label className="form-label">
                  Send From Email <br></br>
                  <span className="example-text" style={{ fontSize: "12px" }}>
                    (Contact <i>mjones@landmarkventures.com</i> to configure new
                    emails)
                  </span>
                </label>
                <select
                  className="form-control lh-1"
                  onChange={(e) => handleSetEmailSendFrom(e.target.value)}
                  value={emailSendFrom}
                >
                  {sendFromOptions.map((item, key) => (
                    <option key={`sf_option_${key}`} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </select>
                {/* <input
                  type="text"
                  value={emailSendFrom}
                  className="form-control"
                  onChange={(e) => setEmailSendFrom(e.target.value)}
                /> */}
              </div>
              <div className="form-group mb-3 col-12">
                <label className="form-label">
                  Send From Name{" "}
                  {/* <span className="example-text">e.g. Andrew Kalaigian</span> */}
                </label>
                <input
                  type="text"
                  value={emailSendFromName}
                  className="form-control"
                  onChange={(e) => setEmailSendFromName(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-12">
                <label className="form-label">
                  Subject <span className="example-text"></span>
                </label>
                <input
                  type="text"
                  value={emailSubject}
                  className="form-control"
                  onChange={(e) => setEmailSubject(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-12">
                <label className="form-label">
                  Body<br></br>{" "}
                  <span className="example-text" style={{ fontSize: "12px" }}>
                    Available variables:<br></br>
                    <i>
                      firstName, lastName, fullName, company, title, department,
                      city, state, country
                    </i>
                  </span>
                  <br></br>{" "}
                  <span className="example-text" style={{ fontSize: "12px" }}>
                    To use a variable simply wrap it in curly braces e.g.
                    {` {`}
                    <i>firstName</i>
                    {`}`}
                  </span>
                  <br></br>{" "}
                  <span className="example-text" style={{ fontSize: "12px" }}>
                    Please note that data other than{" "}
                    <i>firstName, lastName, fullName</i> is not guaranteed to be
                    available for every contact. It's therefore recommended you
                    use these variables sparingly.
                  </span>
                </label>
                {/* <textarea
                  value={emailBody}
                  className="form-control textarea rmono"
                  rows="20"
                  onChange={(e) => setEmailBody(e.target.value)}
                /> */}
                <CodeEditor
                  value={emailBody}
                  language="html"
                  onChange={(evn) => setEmailBody(evn.target.value)}
                  padding={15}
                  data-color-mode="light"
                  style={{
                    color: "#000",
                    fontSize: 11,
                    backgroundColor: "#fff",
                    border: "1px #0072ff solid",
                    borderRadius: "0.625rem",
                    fontFamily:
                      "ui-monospace,Roboto Mono,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="subtitle">Viewer</div>
          <div className="viewer">
            <div className="subject">{emailSubject}</div>
            <div className="divider"></div>
            <div className="body">{ReactHtmlParser(emailHtml)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const PostContentDisplay = ({ postContent }) => {
//   const maxLength = 100;
//   const [showFullContent, setShowFullContent] = useState(false);

//   const truncatedContent = postContent.slice(0, maxLength);
//   const shouldTruncate = postContent.length > maxLength;

//   const toggleShowMore = () => {
//     setShowFullContent(!showFullContent);
//   };

//   const showMoreLessButton = (
//     <>
//       {" "}
//       <span
//         style={{
//           textDecoration: "underline",
//           cursor: "pointer",
//           color: "blue",
//         }}
//         onClick={toggleShowMore}
//       >
//         {showFullContent ? "Show less" : "Show more"}
//       </span>
//     </>
//   );

//   return (
//     <div>
//       <p>
//         {showFullContent ? postContent : truncatedContent}
//         {shouldTruncate && showMoreLessButton}
//       </p>
//     </div>
//   );
// };

const MasterEventsTable = ({
  password,
  employee,
  invoices,
  customers,
  getInvoices,
  nextInvoiceNo,
  setNextInvoiceNo,
  submitNextInvoiceNo,
  addCustomer,
  handleUpdateInvoiceCustomerName,
}) => {
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [addCustomerName, setAddCustomerName] = useState("");
  const [contactConfirmationEmails, setContactConfirmationEmails] = useState(
    []
  );
  const [pageIndex, setPageIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [filtersEnabled, setFiltersEnabled] = useState(true);
  const [approvedAttendingFilter, setApprovedAttendingFilter] = useState(false);
  const [notHandledFilter, setNotHandledFilter] = useState(true);
  const [handledFilter, setHandledFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleSetUnhandledFilter = (event) => {
    const checked = event.target.checked;
    setNotHandledFilter(checked);
  };

  const handleSetCreatedFilter = (event) => {
    const checked = event.target.checked;
    setHandledFilter(checked);
  };

  const handleSetFiltersEnabled = (event) => {
    const checked = event.target.checked;
    setFiltersEnabled(checked);
  };

  const getFilteredInvoices = () => {
    function isString(s) {
      return typeof s === "string" || s instanceof String;
    }

    // filter just the invoices for the selected event
    let filtered = invoices;

    // bypassed
    if (!filtersEnabled) {
      return filtered;
    }

    for (const filterKey in filters) {
      const filterValue = filters[filterKey];
      // string filtering
      if (isString(filterValue) && filterValue.length > 0) {
        filtered = filtered.filter((invoice) =>
          invoice[filterKey].toLowerCase().includes(filterValue.toLowerCase())
        );
      }
    }

    if (notHandledFilter && handledFilter) {
      // show all (no added filtering)
    }
    // uncontacted only filter
    else if (notHandledFilter && !handledFilter) {
      filtered = filtered.filter((invoice) => !invoice.quickbooksHandled);
    }
    // contacted only filter
    else if (handledFilter && !notHandledFilter) {
      filtered = filtered.filter((invoice) => invoice.quickbooksHandled);
    } else {
      // show all (no added filtering)
    }

    return filtered;
  };
  let filteredInvoices = getFilteredInvoices();

  // OptionRequest popup
  const [showPopupOR, setShowPopupOR] = useState(false);
  const [showPopupAttendance, setShowPopupAttendance] = useState(false);
  const [popupPositionOR, setPopupPositionOR] = useState({ x: 0, y: 0 });
  const [popupPositionAttendance, setPopupPositionAttendance] = useState({
    x: 0,
    y: 0,
  });
  const [selectedContactEvent, setSelectedContactEvent] = useState(null);
  const [selectedOptionRequest, setSelectedOptionRequest] = useState(null);
  const [showEditTemplate, setShowEditTemplate] = useState(false);

  const handleBadgeClickOR = (event, contact, optionRequest) => {
    const badgeRect = event.currentTarget.getBoundingClientRect();
    const popupX = badgeRect.left + badgeRect.width / 2;
    const popupY = badgeRect.bottom;
    setPopupPositionOR({ x: popupX, y: popupY });
    setSelectedContactEvent(contact);
    setSelectedOptionRequest(optionRequest);
    setShowPopupOR(true);
    setShowPopupAttendance(false);
  };

  const handleBadgeClickAttendance = (event, contact) => {
    const badgeRect = event.currentTarget.getBoundingClientRect();
    const popupX = badgeRect.left + badgeRect.width / 2;
    const popupY = badgeRect.bottom;
    setPopupPositionAttendance({ x: popupX, y: popupY });
    setSelectedContactEvent(contact);
    setShowPopupOR(false);
    setShowPopupAttendance(true);
  };

  const copyText = (text, desc = null) => {
    copyTextToClipboard(text);
    desc = desc ? desc : "text";
    toast.success(`Copied ${desc} to clipboard`);
  };

  let canNextPage = false;
  let canPreviousPage = false;
  const pageCount = Math.ceil(filteredInvoices.length / rowsPerPage);
  console.log(`pageIndex`, pageIndex);
  console.log(`pageCount`, pageCount);
  if (pageIndex + 1 < pageCount) {
    canNextPage = true;
  }
  if (pageIndex > 0) {
    canPreviousPage = true;
  }

  const goToPage = (pageNo) => {
    if (pageNo >= 0 && pageNo < pageCount) {
      setPageIndex(pageNo);
    } else {
    }
  };
  const nextPage = () => {
    if (canNextPage) {
      setPageIndex(pageIndex + 1);
    }
  };
  const previousPage = () => {
    if (canPreviousPage) {
      setPageIndex(pageIndex - 1);
    }
  };
  // Calculate the start index and end index for the current page
  const startIndex = pageIndex * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // get invoices to display on the current page
  const pageInvoices = filteredInvoices.slice(startIndex, endIndex);

  if (!filteredInvoices || !invoices || !invoices.length) {
    return <>No Invoices</>;
  }

  // const handleSubmitContactComment = async (
  //   contactInvoiceId,
  //   content,
  //   isReply,
  //   replyCommentId
  // ) => {
  //   try {
  //     if (!employee) {
  //       toast.error(`An error occurred: employee not logged in`);
  //       return;
  //     }
  //     const api = new ApiService({ password, employee });
  //     const { success, error, info } = await api.post(
  //       `landmark/invoices/submitEventContactComment`,
  //       {
  //         contactInvoiceId,
  //         employee,
  //         content,
  //         isReply,
  //         replyCommentId,
  //       }
  //     );
  //     if (!success && !info) {
  //       toast.error(`An error occurred: ${error}`);
  //       return false;
  //     } else if (success) {
  //       toast.success(`${success}`);
  //       return true;
  //     } else if (info) {
  //       toast(`${info}`);
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return false;
  //   }
  // };

  const selectInvoices = (invoicesToSelect) => {
    const newSelectedInvoices = [...selectedInvoices];
    invoicesToSelect.forEach((inv) => {
      if (!newSelectedInvoices.includes(inv.opportunityId)) {
        newSelectedInvoices.push(inv.opportunityId);
      }
    });
    setSelectedInvoices(newSelectedInvoices);
  };

  const unselectInvoices = (invoicesToUnselect) => {
    invoicesToUnselect = invoicesToUnselect.map((r) => r.opportunityId);
    setSelectedInvoices((prevSelectedInvoices) => {
      return prevSelectedInvoices.filter(
        (r) => !invoicesToUnselect.includes(r)
      );
    });
  };

  const toggleSelectAllPageInvoices = (event) => {
    console.log(`toggleSelectAllPageInvoices() - event.target`, event.target);
    const checked = event.target.checked;
    // select all invoices on this page
    if (checked) {
      selectInvoices(pageInvoices);
    }
    // unselect all invoices on this page
    else {
      unselectInvoices(pageInvoices);
    }
  };
  const toggleSelectInvoice = (event, invoice) => {
    const checked = event.target.checked;
    console.log(`toggleSelectInvoice() - checked`, checked);
    if (checked) {
      selectInvoices([invoice]);
    } else {
      unselectInvoices([invoice]);
    }
  };

  const handleSetFilter = (filterKey, value) => {
    const newFilters = { ...filters };
    newFilters[filterKey] = value;
    setFilters(newFilters);
  };

  let headers = [];
  // Assuming invoices is not an empty array
  if (invoices.length > 0) {
    const headersSet = new Set();
    invoices.forEach((r) => {
      Object.keys(r).forEach((key) => {
        headersSet.add(key);
      });
    });

    headers = Array.from(headersSet).map((h) => {
      return { key: h, name: h };
    });
    // remove unwanted fields
    const unwanted = [
      "attendanceStatusUpdates",
      "comments",
      "confirmEmails",
      "optionRequests",
      "__v",
      "_id",
      "partnerResponses",
    ];
    headers = headers.filter((h) => !unwanted.includes(h.key));
    console.log(`headers`, headers);
  }

  const selectAllBtn = () => {
    // unselect all
    unselectInvoices(invoices);
    // selected all filtered
    selectInvoices(filteredInvoices);
    toast.success(`${filteredInvoices.length} invoices selected`);
  };

  const unselectAllBtn = () => {
    // unselect all
    unselectInvoices(invoices);
  };

  // const sendEmailToSelectedContactsBtn = () => {
  //   sendContactEmail(selectedInvoice.invoiceId, selectedInvoices);
  //   unselectInvoices(invoices);
  // };

  const prepContactsForCsvExport = () => {
    try {
      return invoices.map((r) => {
        const contact = JSON.parse(JSON.stringify(r));
        // contact.optionRequests.forEach((or) => {
        //   const { optionId, status } = or;
        //   contact[optionId] = status;
        // });
        // selectedInvoice.options.forEach((option) => {
        //   const { optionId } = option;
        //   if (!contact[optionId]) {
        //     contact[optionId] = "not requested";
        //   }
        // });
        // delete contact.attendanceStatusUpdates;
        // delete contact.comments;
        // delete contact.confirmEmails;
        // delete contact.optionRequests;
        // delete contact.__v;
        // delete contact._id;
        return contact;
      });
    } catch (error) {
      console.log(`prepContactsForCsvExport() error`, error);
    }
  };

  const csvAdjustedContacts = prepContactsForCsvExport();

  const exportMultipleInvoices = () => {
    // filtered by quickbooks customer name set
    const selectedInvoicesArr = selectedInvoices.map((id) => {
      return invoices.find((invoice) => invoice.opportunityId === id);
    });
    exportInvoices(selectedInvoicesArr);
  };

  const exportInvoices = (invoiceArr) => {
    const header = [
      { id: "InvoiceNo", title: "InvoiceNo" },
      { id: "Customer", title: "Customer" },
      { id: "InvoiceDate", title: "InvoiceDate" },
      { id: "DueDate", title: "DueDate" },
      { id: "Item", title: "Item(Product/Service)" },
      { id: "ItemDescription", title: "ItemDescription" },
      { id: "ItemQuantity", title: "ItemQuantity" },
      { id: "ItemRate", title: "ItemRate" },
      { id: "ItemAmount", title: "ItemAmount" },
    ];

    let csv = header.map((item) => item.title).join(",") + "\n";

    let invoiceStartNo = parseInt(nextInvoiceNo - 1);

    function calculateCommission(commissionRateString, amountString) {
      const amount = parseFloat(amountString.replace("$", "").replace(",", ""));
      // no commission rate - return amount
      if (!commissionRateString || !commissionRateString.length) {
        return amount;
      }
      // Remove the '%' sign from the commission rate string and convert it to a number
      const commissionRate =
        parseFloat(commissionRateString.replace("%", "")) / 100;
      // Calculate the commission amount
      const commissionAmount = amount * commissionRate;
      return commissionAmount;
    }

    invoiceArr.forEach((invoice, i) => {
      const adjustedAmount = calculateCommission(
        invoice.commissionRate,
        invoice.amount
      );
      const fields = [
        invoiceStartNo++,
        invoice.quickbooksCustomerName || "",
        invoice.closeDate || "",
        addOneMonth(invoice.closeDate),
        "", // item
        "", // description
        1, // qty
        adjustedAmount, // rate
        adjustedAmount, // amount (qty x rate = amount)
      ];

      const row = fields
        .map((field) => {
          // If the field contains a comma, enclose it within double quotes
          if (`${field}`.includes(",")) {
            return `"${field}"`;
          }
          return field;
        })
        .join(",");

      csv += row + "\n";
    });

    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "invoices.csv");
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Cleanup
    document.body.removeChild(link);
  };

  const markSelectedOpportunitiesAsHandled = async () => {
    // mark all as handled
    const handledArr = selectedInvoices.map((e) => true);
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/salesforce/setOpportunitiesQuickbooksHandled`,
        {
          opportunityIds: selectedInvoices,
          handledArr,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        // refresh table
        getInvoices();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const markSelectedOpportunitiesAsNotHandled = async () => {
    // mark all as handled
    const handledArr = selectedInvoices.map((e) => false);
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/salesforce/setOpportunitiesQuickbooksHandled`,
        {
          opportunityIds: selectedInvoices,
          handledArr,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        // refresh table
        getInvoices();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <>
      {invoices && invoices.length ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            {/* <div className="event-image-img">
              <img src={selectedInvoice.imageUrl} className="img-fluid" />
            </div> */}
            <div className="row">
              <div className="outputHeader">Salesforce Invoices</div>
              <div className="events-owners-subheading">
                Browse and export loaded invoices for Quickbooks
                {/* Recommended workflow:
                <ul>
                <li>• Filter the data based on event (or company, etc.)</li>
                <li>
                • Click 'Export Current' to export the filtered data to a
                spreadsheet
                </li>
                <li>• Once handled, click 'Mark Current as Handled'</li>
              </ul> */}
              </div>
              <>
                <></>
                <div className="outputBody">
                  <div className="table-wrapper">
                    {
                      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                        <div
                          className={`table-wrapper ${
                            filtersEnabled ? "" : "filters-disabled"
                          }`}
                        >
                          <div className="filters-enable-row">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="filterBypassSwitch"
                                checked={filtersEnabled}
                                onChange={(e) => handleSetFiltersEnabled(e)}
                              ></input>
                              <label
                                className="form-check-label enable-filters-label"
                                for="filterBypassSwitch"
                              >
                                Enable Filters
                              </label>
                            </div>
                          </div>
                          <div className="filters-row">
                            <FilterInput
                              filterKey="opportunityTitle"
                              placeholder="Title"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="accountName"
                              placeholder="Account Name"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="leadSource"
                              placeholder="Lead Source"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="clientOwner"
                              placeholder="Client Owner"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="openDate"
                              placeholder="Open Date"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="closeDate"
                              placeholder="Close Date"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="stage"
                              placeholder="Stage"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              filterKey="commissionRate"
                              placeholder="Commission Rate"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                            <FilterInput
                              placeholder="Amount"
                              filterKey="amount"
                              filters={filters}
                              handleSetFilter={handleSetFilter}
                            />
                          </div>
                          <div className="filters-row">
                            {/* <FilterCheckbox
                              title="Approved and Attending (includes Tentative):"
                              checked={approvedAttendingFilter}
                              handleChecked={handleSetApprovedAttendingFilter}
                            /> */}
                            <FilterCheckbox
                              title="Not Handled:"
                              checked={notHandledFilter}
                              handleChecked={handleSetUnhandledFilter}
                            />
                            <FilterCheckbox
                              title="Handled:"
                              checked={handledFilter}
                              handleChecked={handleSetCreatedFilter}
                            />
                          </div>
                        </div>
                      </motion.div>
                    }
                    {
                      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                        <div className="table-wrapper">
                          <div
                            className="events-owners-subheading"
                            style={{ marginBottom: "8px" }}
                          >
                            {`Totals${filtersEnabled ? ` (filtered)` : ``}`}
                          </div>
                          <div className="totals-row rmono">
                            <div className="total-col">
                              <div className="total-line">
                                <div className="field">Invoices: </div>
                                <div className="value">
                                  {filteredInvoices.length}
                                </div>
                              </div>
                              <div className="total-line option col-black fw-500">
                                <div className="field">Selected: </div>
                                <div className="value">
                                  {selectedInvoices.length}
                                </div>
                              </div>
                            </div>
                            {/* {selectedInvoice.options.map((option) => {
                              const { shortTitle, optionId } = option;
                              const reorderedStatusEnum = reorderStatusEnum([
                                "APPROVED",
                                "WAITLISTED",
                                "DENIED",
                                "PENDING",
                              ]);
                              return (
                                <div className="total-col">
                                  <div className="total-line option">
                                    <div className="field">{shortTitle}: </div>
                                    <div className="value">
                                      {
                                        filteredInvoices.filter((r) =>
                                          r.optionRequests.some(
                                            (or) => or.optionId === optionId
                                          )
                                        ).length
                                      }
                                    </div>
                                  </div>
                                  {Object.entries(reorderedStatusEnum).map(
                                    ([key, status]) => {
                                      return (
                                        <div
                                          className={`total-line col-${status}`}
                                        >
                                          <div className="field">
                                            {toTitleCase(status)}{" "}
                                          </div>
                                          <div className={`value`}>
                                            {
                                              filteredInvoices.filter((r) =>
                                                r.optionRequests.some(
                                                  (or) =>
                                                    or.optionId === optionId &&
                                                    or.status === status
                                                )
                                              ).length
                                            }
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            })} */}
                            {/* <div className="total-col">
                              <div className="total-line option col-approved fw-500">
                                <div className="field">Contacted: </div>
                                <div className="value">
                                  {
                                    filteredInvoices.filter((c) => {
                                      const existingConfirmEmail =
                                        sentEmails.find(
                                          (e) =>
                                            e.contactEmail.toLowerCase() ===
                                            c.email.toLowerCase()
                                        );
                                      return existingConfirmEmail;
                                    }).length
                                  }
                                </div>
                              </div>
                              <div
                                className={`total-line col-tentative fw-500`}
                              >
                                <div className="field">Uncontacted: </div>
                                <div className={`value`}>
                                  {
                                    filteredInvoices.filter((c) => {
                                      const existingConfirmEmail =
                                        sentEmails.find(
                                          (e) =>
                                            e.contactEmail.toLowerCase() ===
                                            c.email.toLowerCase()
                                        );
                                      return !existingConfirmEmail;
                                    }).length
                                  }
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </motion.div>
                    }
                    {
                      <div className="text-center mb-3">
                        <div className="qb-settings">
                          <div className="qb-settings-group">
                            <label className="form-label">
                              <div className="qb-settings-title">
                                Set Invoice No.
                              </div>
                              <span className="example-text">
                                Navigate to{" "}
                                <a
                                  target={"_blank"}
                                  className="inv-link"
                                  href="https://app.qbo.intuit.com/app/invoice"
                                >
                                  Quickbooks Create Invoice
                                </a>{" "}
                                and enter the number at the top (e.g. if it says
                                Invoice #6122 then enter '6122' and click
                                confirm){" "}
                              </span>
                            </label>
                            <div className="qb-settings-inputs">
                              <input
                                type="text"
                                value={nextInvoiceNo}
                                className="form-control"
                                onChange={(e) =>
                                  setNextInvoiceNo(e.target.value)
                                }
                              />
                              <button
                                className={`create-event-btn btn btn-sm btn-primary email-options-btn mr-10`}
                                onClick={submitNextInvoiceNo}
                              >
                                {`Confirm`}
                                {/* <i className="event-owner-add fa-sharp fa-solid fa-user ml-05"></i> */}
                              </button>
                            </div>
                          </div>
                          <div className="qb-settings-group">
                            <label className="form-label">
                              <div className="qb-settings-title">
                                Add Quickbooks Customer
                              </div>
                              <span className="example-text">
                                Enter the Customer Display Name exactly as shown
                                on Quickbooks and click confirm
                              </span>
                            </label>
                            <div className="qb-settings-inputs">
                              <input
                                type="text"
                                value={addCustomerName}
                                className="form-control"
                                onChange={(e) =>
                                  setAddCustomerName(e.target.value)
                                }
                              />
                              <button
                                className={`create-event-btn btn btn-sm btn-primary email-options-btn mr-10`}
                                onClick={() => addCustomer(addCustomerName)}
                              >
                                {`Confirm`}
                                {/* <i className="event-owner-add fa-sharp fa-solid fa-user ml-05"></i> */}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <button
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn mr-10`}
                            onClick={selectAllBtn}
                          >
                            {`Select All`}
                            {/* <i className="event-owner-add fa-sharp fa-solid fa-user ml-05"></i> */}
                          </button>
                          <button
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn bulk-email-btn mr-10 ${
                              selectedInvoices.length ? `` : ` disabled`
                            }`}
                            onClick={unselectAllBtn}
                          >
                            {`Unselect All`}
                          </button>
                          <button
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn bulk-email-btn mr-10 ${
                              selectedInvoices.length ? `` : ` disabled`
                            }`}
                            onClick={exportMultipleInvoices}
                          >
                            {`Export Selected Invoice${
                              selectedInvoices.length === 1 ? "" : "s"
                            } as CSV (${selectedInvoices.length} invoices)`}
                            <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                          </button>
                          <button
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn bulk-email-btn handled-btn mr-10${
                              selectedInvoices.length ? `` : ` disabled`
                            }`}
                            onClick={markSelectedOpportunitiesAsHandled}
                          >
                            {`Mark Selected as Handled`}
                            <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
                          </button>
                          {/* <ExportJsonCsv
                            headers={headers}
                            items={csvAdjustedContacts}
                            className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                          >
                            {`Export All Contacts`}
                            <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                          </ExportJsonCsv> */}
                        </div>
                      </div>
                    }
                    {/* <div className="progress-bar-container">
                      {emailJobs &&
                      emailJobs.some(
                        (ej) =>
                          ej.invoiceId === selectedInvoice.invoiceId &&
                          !ej.completed
                      ) ? (
                        <>
                          {emailJobs
                            .filter(
                              (ej) =>
                                ej.invoiceId === selectedInvoice.invoiceId &&
                                !ej.completed
                            )
                            .map((ej, key) => {
                              const {
                                totalEmails,
                                totalSent,
                                totalSuccessful,
                                totalFailed,
                                sentInvoices,
                              } = ej;
                              return (
                                <div className="w-100" key={key}>
                                  <ProgressBar>
                                    <ProgressBar
                                      animated
                                      striped
                                      variant="success"
                                      max={totalEmails}
                                      now={totalSuccessful}
                                      key={1}
                                    />
                                    <ProgressBar
                                      animated
                                      striped
                                      variant="danger"
                                      max={totalEmails}
                                      now={totalFailed}
                                      key={2}
                                    />
                                  </ProgressBar>
                                </div>
                              );
                            })}
                        </>
                      ) : null}
                    </div> */}
                    <Table
                      responsive
                      className="invoice-dashboard-table header-border "
                    >
                      <thead>
                        <tr>
                          <th className="width50 ">
                            <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="checkAll"
                                required
                                defaultChecked={false}
                                checked={
                                  !pageInvoices.some(
                                    (pr) =>
                                      !selectedInvoices.includes(
                                        pr.opportunityId
                                      )
                                  )
                                }
                                onChange={toggleSelectAllPageInvoices}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="checkAll"
                              ></label>
                            </div>
                          </th>
                          <th>Title</th>
                          <th>Account Name</th>
                          <th>Quickbooks Customer</th>
                          <th>Lead Source</th>
                          <th>Client Owner</th>
                          <th>Open Date</th>
                          <th>Close Date</th>
                          <th>Stage</th>
                          <th>Commission Rate</th>
                          <th>Amount</th>
                          <th>Invoice Created</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pageInvoices.map((invoice, i) => {
                          const {
                            opportunityId,
                            opportunityUrl,
                            opportunityTitle,
                            accountName,
                            accountUrl,
                            accountId,
                            nextMeetingDate,
                            contactName,
                            contactTitle,
                            contactEmail,
                            website,
                            websiteSecondary,
                            annualRevenue,
                            employees,
                            industry,
                            description,
                            isInvoice,
                            leadSource,
                            clientOwner,
                            openDate,
                            closeDate,
                            stage,
                            amount,
                            commissionRate,
                            quickbooksCustomerName,
                            quickbooksHandled,
                            quickbooksHandledTime,
                            invoiceCreated,
                            invoiceCreatedTime,
                            invoiceUrl,
                            nextMeetingDateEpoch,
                            emailNotificationSent,
                            createdTime,
                          } = invoice;

                          return (
                            <tr
                              key={`tr_row_${i}`}
                              className={`${
                                quickbooksHandled ? "handled" : ""
                              }`}
                            >
                              <td>
                                <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customCheckBox2"
                                    required
                                    defaultChecked={false}
                                    checked={selectedInvoices.includes(
                                      invoice.opportunityId
                                    )}
                                    onChange={(event) =>
                                      toggleSelectInvoice(event, invoice)
                                    }
                                  />
                                </div>
                              </td>
                              <td>
                                <a
                                  className="inv-link"
                                  href={opportunityUrl}
                                  target="_blank"
                                >
                                  <b>{opportunityTitle}</b>
                                </a>
                              </td>
                              <td>
                                {" "}
                                <a
                                  className="inv-link"
                                  href={accountUrl}
                                  target="_blank"
                                >
                                  {accountName}
                                </a>
                              </td>
                              <td>
                                <select
                                  defaultValue={"All"}
                                  className="quickbooks-select"
                                  onChange={(e) =>
                                    handleUpdateInvoiceCustomerName(
                                      opportunityId,
                                      e.target.value
                                    )
                                  }
                                  value={quickbooksCustomerName}
                                >
                                  <option>{``}</option>
                                  {customers.map((c) => (
                                    <option>{c}</option>
                                  ))}
                                </select>
                              </td>
                              <td>{leadSource}</td>
                              <td>{clientOwner}</td>
                              <td>{openDate}</td>
                              <td>{closeDate}</td>
                              <td>{stage}</td>
                              <td>{commissionRate}</td>
                              <td>{amount}</td>
                              {/* <td>
                                <PostContentDisplay postContent={postContent} />
                              </td> */}
                              {/* confirmation email cell */}
                              <td>
                                {quickbooksHandled ? (
                                  <div className="text-centerxxxx">
                                    <div>
                                      Handled on{" "}
                                      {epochToDateString(quickbooksHandledTime)}
                                    </div>
                                    <div
                                      className="export-csv-text"
                                      onClick={() => exportInvoices([invoice])}
                                    >
                                      Export Invoice CSV
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {quickbooksCustomerName ? (
                                      <div className="confirm-email-cell-container">
                                        <button
                                          className={`sign-up-btn btn btn-sm btn-primary export-csv-btn`}
                                          onClick={() =>
                                            exportInvoices([invoice])
                                          }
                                        >
                                          {`Export Invoice CSV`}
                                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                                        </button>
                                        {/* {!allFinal ? (
                                        <div className="not-all-final-msg">
                                          Warning: Not all requests are final
                                        </div>
                                      ) : null} */}
                                      </div>
                                    ) : (
                                      <div className="">
                                        Enter a Quickbooks Customer
                                      </div>
                                    )}
                                  </>
                                )}
                              </td>
                              {/* <td>
                                <div className="view-comments-btn-container">
                                  <OverlayTrigger
                                    trigger="click"
                                    key={i}
                                    placement="left"
                                    responsive={true}
                                    rootClose={true}
                                    overlay={
                                      <Tooltip
                                        className="toltip-popover"
                                        id={`popover-positioned-left`}
                                      >
                                        <CommentPopup
                                          contact={contact}
                                          onSubmitComment={
                                            handleSubmitContactComment
                                          }
                                        />
                                      </Tooltip>
                                    }
                                  >
                                    <button
                                      className={`sign-up-btn btn btn-sm ${
                                        comments.length === 0
                                          ? "btn-grey"
                                          : "btn-primary"
                                      }`}
                                    >
                                      {`View Comments (${comments.length})`}
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-between">
                      <span className="table-index">
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageCount}
                        </strong>
                        {""}
                      </span>
                      <span className="table-index">
                        Rows per page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={rowsPerPage}
                          min={1}
                          max={500}
                          onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                          }}
                        />
                      </span>
                      <span className="table-index">
                        Go to page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={pageIndex + 1}
                          min={1}
                          max={pageCount}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            goToPage(pageNumber);
                          }}
                        />
                      </span>
                    </div>
                    <div className="text-center mb-3">
                      <div className="filter-pagination  mt-3">
                        <button
                          className=" previous-button"
                          onClick={() => goToPage(0)}
                          disabled={!canPreviousPage}
                        >
                          {"<<"}
                        </button>

                        <button
                          className="previous-button"
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          Previous
                        </button>
                        <button
                          className="next-button"
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          Next
                        </button>
                        <button
                          className=" next-button"
                          onClick={() => goToPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          {">>"}
                        </button>
                      </div>
                    </div>
                    <div className="text-center mb-3">
                      <div className="mt-3">
                        {/* <button
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                          onClick={handleExportData}
                        >
                          {`Export All RSVPs`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </button> */}
                        <ExportJsonCsv
                          headers={headers}
                          items={csvAdjustedContacts}
                          className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                        >
                          {`Export All Contacts`}
                          <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                        </ExportJsonCsv>
                      </div>
                    </div>
                  </div>
                </div>
              </>
              {!invoices ? (
                <div className="text-center">
                  <img
                    className="m-auto mt-3 mb-3"
                    width={74}
                    src={loadingCircleImg}
                  />
                </div>
              ) : null}
            </div>
          </Card>
        </motion.div>
      ) : (
        <div className="no-invoices-msg">No invoices found for this event</div>
      )}
    </>
  );
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const SelectTasks = ({ password, selectedInvoice, refreshInvoice }) => {
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);

  async function getTasks() {
    try {
      const api = new ApiService({ password });
      const response = await api.get(
        `landmark/seamlessbot/getValidKeywordTasks`
      );
      console.log(`landmark/seamlessbot/getValidKeywordTask`, response);
      setTasks(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function saveSeamlessTaskChanges() {
    try {
      const api = new ApiService({ password });
      const response = await api.post(
        `landmark/invoices/editInvoiceSeamlessTasks`,
        {
          invoiceId: selectedInvoice.invoiceId,
          seamlessBotTaskIds: selectedTasks,
        }
      );
      console.log(`landmark/invoices/editInvoiceSeamlessTasks`, response);
      const { success, error } = response;
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else if (success) {
        toast.success(`${success}`);
        // update invoice object
        refreshInvoice(selectedInvoice.invoiceId);
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getTasks();
  }, []);

  useEffect(() => {
    setSelectedTasks(selectedInvoice.seamlessBotTaskIds);
  }, [selectedInvoice]);

  function toggleSelectTask(taskId) {
    if (selectedTasks.includes(taskId)) {
      setSelectedTasks(selectedTasks.filter((t) => t !== taskId)); // remove task
    } else {
      const newSelectedTasks = selectedTasks.filter((t) => true); // clone list
      newSelectedTasks.push(taskId); // add task
      setSelectedTasks(newSelectedTasks);
    }
  }

  const existingTaskIds = new Set(selectedInvoice.seamlessBotTaskIds);
  const newTaskIds = new Set(selectedTasks);
  const tasksHaveChanged = !areSetsEqual(existingTaskIds, newTaskIds);

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">Import Contacts</div>
            <>
              <div className="events-owners-subheading">
                Select Keyword Bot Tasks to import invoices
              </div>
              <div
                className="event-owners-wrapper"
                style={{ marginBottom: "12px" }}
              >
                {tasks.map((task) => {
                  const selectedClass =
                    selectedInvoice && selectedTasks.includes(task.taskId)
                      ? "selected"
                      : "";
                  return (
                    <div key={task.taskId}>
                      <div
                        className={`event-owner-container selectable ${selectedClass}`}
                        onClick={() => toggleSelectTask(task.taskId)}
                      >
                        {/* <img
                          src={event.imageUrl}
                          className="img-fluid event-img"
                        /> */}
                        <div className="event-title">{task.keyword}</div>
                        <div className="event-description">
                          {`${task.invoicesCount} invoices`} <br></br>
                          {task.lastRunTime
                            ? epochToDateString(task.lastRunTime)
                            : epochToDateString(task.createdTime)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {tasksHaveChanged ? (
                <div className="centered-flex-row ">
                  <button
                    className={`create-event-btn save-changes-btn btn btn-sm btn-primary mb-4`}
                    onClick={saveSeamlessTaskChanges}
                  >
                    {`Save Changes`}
                    <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
                  </button>
                </div>
              ) : null}
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

const SalesforceScanner = ({
  password,
  employee,
  getSalesforceOpportunityScan,
}) => {
  const [showEditTemplate, setShowEditTemplate] = useState(false);

  // async function createTemplate() {
  //   try {
  //     const api = new ApiService({ password, employee });
  //     const newTemplate = await api.post(
  //       `landmark/salesforce/createTemplate`,
  //       {
  //         employee,
  //         invoiceId: selectedInvoice.invoiceId,
  //       }
  //     );
  //     // refresh templates
  //     await getTemplates();
  //     setSelectedEmailTemplate(newTemplate);
  //     setShowEditTemplate(true);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">Salesforce Scanner</div>
            <>
              <div className="events-owners-subheading">
                Load Invoices from Salesforce{" "}
                <a
                  className="inv-link"
                  href="https://landmarkventures.lightning.force.com/lightning/o/Opportunity/list?filterName=00BEX000000TwYi2AK"
                  target={"_blank"}
                >
                  Closed DB Deals for Invoicing
                </a>
                <div className="example-text-alt">
                  • Must have an Invoice Status: '<i>Needs to be invoiced</i>'
                </div>
                <div className="example-text-alt">• Must have an Amount</div>
              </div>
              <div className="event-owners-wrapper"></div>
              <div className="email-template-button-container">
                <div className="add-edit-btn-container">
                  <button
                    className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                    // onClick={() => getSalesforceOpportunityScan()}
                  >
                    {`Load Invoices`}
                    <i className="event-owner-add fa-sharp fa-solid fa-magnifying-glass ml-05"></i>
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-center"></div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

TablePaginationActions2.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions2(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const EmployeeInfo = ({
  landmarkEmployee,
  handleOnClick = () => {},
  disableHover = false,
}) => {
  if (!landmarkEmployee) {
    return null;
  }
  const { firstName, lastName, email, employeeId } = landmarkEmployee;
  const disableHoverClass = disableHover ? "disable-hover" : "";
  return (
    <div
      className={`account ${disableHoverClass}`}
      onClick={() => handleOnClick(landmarkEmployee)}
    >
      <div
        className="user-icon"
        style={{
          backgroundColor: stringToRGB(employeeId),
        }}
      >
        <PersonIcon className="icon" />
      </div>
      <div>
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="email">{email}</div>
      </div>
    </div>
  );
};

const EmployeeSignIn = ({ password, signInAsEmployee }) => {
  const [employees, setEmployees] = useState([]);
  const [showNewAccount, setShowNewAccount] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  async function getEmployees() {
    try {
      const api = new ApiService({ password });
      const employeesResponse = await api.get(
        `landmark/employees/getEmployees`
      );
      console.log(`employees`, employeesResponse);
      setEmployees(employeesResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEmployees();
  }, []);

  const btnDisabled =
    firstName.length && lastName.length && email.length ? false : true;
  const btnDisabledClass = btnDisabled ? "disabled" : "";

  const handleSignUpBtnClicked = async () => {
    if (btnDisabled) {
      return;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/employees/addEmployee/`,
        {
          firstName,
          lastName,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        // toast.success(`Account Created`);
        const employee = success;
        signInAsEmployee(employee);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className="landmark-sign-in">
      <div className="card">
        <div className="card-body">
          <div className="header-text">Log in as</div>
          <div className="existing-accounts-container">
            {employees.map((employee, i) => (
              <EmployeeInfo
                key={i}
                landmarkEmployee={employee}
                handleOnClick={signInAsEmployee}
              />
            ))}
          </div>
        </div>
      </div>
      {!showNewAccount && (
        <div
          className="new-account-btn"
          onClick={() => setShowNewAccount(true)}
        >
          <div className="hvr-shrink text">
            First time here? Create New Account
          </div>
        </div>
      )}
      {showNewAccount && (
        <motion.div
          animate={{
            scale: [0, 1.04, 1],
          }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="card">
            <div className="card-body">
              <div className="header-text">New Account</div>
              <div className="settings-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        value={firstName}
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        className="form-control"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <div className="input-group input-primary">
                      <input
                        type="text"
                        value={email}
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="input-group-text">
                        @landmarkventures.com
                      </span>
                    </div>
                  </div>
                  <div className="sign-up-btn-container">
                    <button
                      className={`sign-up-btn btn btn-primary btn-sm ${btnDisabledClass}`}
                      type="submit"
                      onClick={handleSignUpBtnClicked}
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [socket, setSocket] = useState(null);
  const [nextInvoiceNo, setNextInvoiceNo] = useState(6122);
  const [triggerSendEmailsRefresh, setTriggerSentEmailsRefresh] =
    useState(false);

  function createWebsocket() {
    // Create a WebSocket connection
    const isLocalhost =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1";
    const WS_URL_PROD = "wss://betterblocks.landmarkventures.com:7769";
    const WS_URL = "wss://localhost:7769";
    const websocketUrl = isLocalhost ? WS_URL : WS_URL_PROD; // force prod url when not running locally
    const ws = new WebSocket(websocketUrl);

    // Set up event listeners for the WebSocket connection
    ws.onopen = () => {
      console.log("WebSocket connection opened");
      // attempt to authenticate
      if (isAuthenticated && password) {
        authenticateWebSocket(ws);
      }
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("Received message:", data);
      if (data) {
        console.log(`data`, data);
        if (data.triggerEmailRefresh) {
          setTriggerSentEmailsRefresh(true);
          // triggered on completion of a job
          setTimeout(() => {
            // setEmailJobs([]); // when multiple jobs running this will bug
          }, 2000); // some overlap so the sentEmails is finished running before we clear the job data
          toast.success("Email Sending Completed");
        } else if (data.emailJobs) {
          // setEmailJobs(data.emailJobs);
          console.log(`emailJobs`, data.emailJobs);
        }
      }
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Save the WebSocket instance in state
    setSocket(ws);

    // Cleanup on component unmount
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }

  const authenticateWebSocket = (ws) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({ password });
      ws.send(message);
      console.log("authenticateWebSocket()");
    }
  };

  useEffect(() => {
    if (isAuthenticated && password && !socket) {
      createWebsocket();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (triggerSendEmailsRefresh) {
      // getSentEmails();
      setTriggerSentEmailsRefresh(false);
    }
  }, [triggerSendEmailsRefresh]);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function getInvoices() {
    try {
      const api = new ApiService({ password });
      const response = await api.get(`landmark/salesforce/getInvoices`);
      console.log(`invoices`, response);
      setInvoices(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function getCustomers() {
    try {
      const api = new ApiService({ password });
      const response = await api.get(
        `landmark/salesforce/getQuickbooksCustomers`
      );
      console.log(`customers`, response);
      setCustomers(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function addCustomer(customerName) {
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/salesforce/addQuickbooksCustomer`,
        { customerName }
      );
      console.log(`addCustomer() - ${customerName}`);
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        getCustomers(); // refresh list
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function submitNextInvoiceNo() {
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/salesforce/setQuickbooksInvoiceNo`,
        { invoiceNo: nextInvoiceNo }
      );
      console.log(`submitNextInvoiceNo() - ${nextInvoiceNo}`);
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        getNextInvoiceNo();
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getNextInvoiceNo() {
    try {
      const api = new ApiService({ password });
      const newNextInvoiceNo = await api.get(
        `landmark/salesforce/getQuickbooksInvoiceNo`
      );
      console.log(`getNextInvoiceNo() - nextInvoiceNo`, newNextInvoiceNo);
      setNextInvoiceNo(newNextInvoiceNo);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (isSignedIn) {
      getInvoices();
      getCustomers();
      getNextInvoiceNo();
    }
  }, [isSignedIn]);

  async function handleUpdateInvoiceCustomerName(opportunityId, customerName) {
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/salesforce/setOpportunityQuickbooksCustomerName`,
        { opportunityId, customerName }
      );
      console.log(`handleUpdateInvoiceCustomerName() - ${customerName}`);
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        getInvoices();
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/campaigns/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  const signInAsEmployee = (employee) => {
    if (!employee) {
      toast.error("Cannot sign in as employee");
      return;
    }
    setIsSignedIn(true);
    setEmployee(employee);

    try {
      const api = new ApiService({ employee });
      // sets lastSeenTime
      api.get(`landmark/employees/login`);
    } catch (error) {
      console.error(`signInAsEmployee() - employee login error`, error);
    }

    toast.success(`Logged in as ${employee.firstName} ${employee.lastName}`);
  };
  useDocumentTitle("Invoice Dashboard - Landmark Ventures");

  // async function editInvoice(invoice) {
  //   try {
  //     // Create a shallow copy of the invoice object without the 'invoices' field
  //     const editedInvoice = { ...invoice, invoices: undefined };
  //     const api = new ApiService({ password });
  //     const url = "landmark/invoices/editInvoice";
  //     const response = await api.post(url, {
  //       invoice: editedInvoice,
  //     });
  //     console.log(`editInvoice() - ${url} - response`, response);
  //     // refresh invoices
  //     refreshInvoice(invoice.invoiceId);
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  return (
    <Fragment>
      <div className="global-dash-wrapper invoice-dash">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        {isSignedIn && (
          <div className="landmark-sign-in" style={{ marginBottom: "18px" }}>
            <div className="existing-accounts-container">
              <EmployeeInfo landmarkEmployee={employee} disableHover={true} />
            </div>
          </div>
        )}
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            {
              title: "Invoice Dashboard",
              url: `/landmark/invoice/dashboard`,
            },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated && isSignedIn ? (
          <>
            <SalesforceScanner password={password} employee={employee} />
            <MasterEventsTable
              password={password}
              employee={employee}
              invoices={invoices}
              customers={customers}
              nextInvoiceNo={nextInvoiceNo}
              setNextInvoiceNo={setNextInvoiceNo}
              submitNextInvoiceNo={submitNextInvoiceNo}
              addCustomer={addCustomer}
              getInvoices={getInvoices}
              handleUpdateInvoiceCustomerName={handleUpdateInvoiceCustomerName}
            />
          </>
        ) : null}
        {/* show sign in */}
        {isAuthenticated && !isSignedIn ? (
          <>
            <motion.div
              animate={{
                scale: [0, 1.04, 1],
              }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <EmployeeSignIn
                password={password}
                signInAsEmployee={signInAsEmployee}
              />
            </motion.div>
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access dashboard", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
