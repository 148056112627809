import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css"; //Example style, you can use another
import ErrorBoundary from "../../ErrorBoundary";
import ReactHtmlParser from "react-html-parser";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import { copyTextToClipboard, timeNow, useDocumentTitle } from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  addCommas,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  timeAgo,
  toTitleCase,
} from "../../../../utils";

import DeDuperImg from "../../../../images/misc/quickcsv-logo.png";
import BlueLoading from "../../../../images/misc/blue-goo-loading.svg";
import { Parser } from "@json2csv/plainjs";
// import { timeNow, useDocumentTitle } from "../../../utils";

function mapEmails(arr, emailKey, setEmailKey = null) {
  const emailsSet = new Set();

  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (
        typeof obj[key] === "string" &&
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/.test(obj[key])
      ) {
        const email = obj[key].toLowerCase().trim();
        emailsSet.add(email);
        if (setEmailKey && emailKey !== key) {
          setEmailKey(key);
        }
      }
    });
  });

  return Array.from(emailsSet);
}

const FilterOption = ({
  filterKey,
  title,
  optionsEnum,
  handleSetFilter,
  filters,
  noTitleCase = false,
}) => {
  const value = filters[filterKey] || Object.values(optionsEnum)[0]; // Default to first option
  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">
          <div className="option-title">{title}: </div>{" "}
        </span>
        <select
          className="form-control rmono"
          onChange={(e) => handleSetFilter(filterKey, e.target.value)}
          value={value}
        >
          {Object.entries(optionsEnum).map(([key, status]) => (
            <option key={key}>
              {noTitleCase ? status : toTitleCase(status)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const FilterCheckbox = ({ title, checked, handleChecked }) => {
  // title can be a string or HTML
  const isHTML = (str) => {
    // Simple check for HTML tags
    return /<\/?[a-z][\s\S]*>/i.test(str);
  };
  const renderTitle = () => {
    if (isHTML(title)) {
      return (
        <div
          className="option-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      );
    }

    return <div className="option-title">{title}</div>;
  };

  return (
    <div className="filter-option">
      <div className="input-group">
        <span className="input-group-text">{renderTitle()}</span>
        <div
          className="form-check custom-checkbox checkbox-success check-lg bs_exam_topper"
          // style={{ marginLeft: "4px" }}
        >
          <input
            type="checkbox"
            className="form-check-input"
            id={`FilterCheckbox_${title}`}
            required
            defaultChecked={false}
            checked={checked}
            onChange={(event) => handleChecked(event)}
          />
        </div>
      </div>
    </div>
  );
};
const allowedHeaders = ["Name", "Title", "Company", "Email"];

const DeDuperForm = ({ password }) => {
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [filters, setFilters] = useState({
    style: "Attendee", // Default value
    customFormat: false, // Checkbox state
  });
  const [customLayout, setCustomLayout] = useState(
    "Name(b), Title(i) – Company"
  );
  const [headers, setHeaders] = useState([]); // Store allowed headers found in CSV
  const [html, setHtml] = useState("");
  const outputRef = useRef(null);

  // Default formatting templates
  const defaultFormats = {
    Attendee: "Name(b), Title(i) – Company",
    Attendance: "Name(b)\nTitle | Company(b)\nEmail(u)",
  };

  useEffect(() => {
    if (filters.style && defaultFormats[filters.style]) {
      setCustomLayout(defaultFormats[filters.style]); // Reset to default when switching styles
    }
  }, [filters.style]);

  const parseCustomLayout = (row) => {
    const name = row["Name"]?.trim() || "";
    const title = row["Title"]?.trim() || "";
    const company = row["Company"]?.trim() || "";
    const email = row["Email"]?.trim() || "";

    console.log("row", row);
    console.log(`customLayout`, customLayout);

    try {
      // if(customLayout.)
      // if (customLayout.includes(")"))
      const customLayoutHtml = customLayout
        // normalize case
        .replaceAll("name", "Name")
        .replaceAll("title", "Title")
        .replaceAll("company", "Company")
        .replaceAll("email", "Email")
        .replaceAll("NAME", "Name")
        .replaceAll("TITLE", "Title")
        .replaceAll("COMPANY", "Company")
        .replaceAll("EMAIL", "Email")
        // replace styles with html tags
        .replaceAll(/Name\((.*?)\)/g, (_, styles) =>
          applyStyles("Name", styles)
        )
        .replaceAll(/Title\((.*?)\)/g, (_, styles) =>
          applyStyles("Title", styles)
        )
        .replaceAll(/Company\((.*?)\)/g, (_, styles) =>
          applyStyles("Company", styles)
        )
        .replaceAll(/Email\((.*?)\)/g, (_, styles) =>
          applyStyles("Email", styles)
        )
        // make unique so it doesn't clash when actual data is replaced in (e.g. if someone's title is TitleExample)
        .replaceAll("Name", "__Name")
        .replaceAll("Title", "__Title")
        .replaceAll("Company", "__Company")
        .replaceAll("Email", "__Email");

      console.log(`customLayoutHtml`, customLayoutHtml);
      // swap placeholders for values
      return customLayoutHtml
        .replaceAll("__Name", name)
        .replaceAll("__Title", title)
        .replaceAll("__Company", company)
        .replaceAll("__Email", email)
        .replaceAll("\n", "<br>");
    } catch (error) {
      console.log(`customLayout replace error`, error);
      return row;
    }
  };

  const applyStyles = (text, styles) => {
    if (!text) return "";
    if (styles.includes("b")) text = `<b>${text}</b>`;
    if (styles.includes("i")) text = `<i>${text}</i>`;
    if (styles.includes("u")) text = `<u>${text}</u>`;
    return text;
  };

  const generateHtml = () => {
    if (!csvData || csvData.length === 0) {
      setHtml("<div>No data available</div>");
      return;
    }

    const formattedRows = csvData
      .map((row) => {
        return `<div>${
          filters.customFormat ? parseCustomLayout(row) : defaultGenerate(row)
        }</div>`;
      })
      .join("\n");

    setHtml(formattedRows);
  };

  const defaultGenerate = (row) => {
    const name = row["Name"]?.trim() || "";
    const title = row["Title"]?.trim() || "";
    const company = row["Company"]?.trim() || "";
    const email = row["Email"]?.trim() || "";

    // if (filters.style === "Attendance") {
    //   return `
    //     ${filters.Name ? `<b>${name}</b><br>` : ""}
    //     ${
    //       filters.Title || filters.Company
    //         ? `${title} | <b>${company}</b><br>`
    //         : ""
    //     }
    //     ${filters.Email && email ? `<u>${email}</u>` : ""}`;
    // }

    // return `
    //   ${filters.Name ? `<b>${name}</b>` : ""}
    //   ${
    //     filters.Title || filters.Company ? `, <i>${title}</i> - ${company}` : ""
    //   }`;

    let formatted = "";

    if (filters.style === "Attendance") {
      // Attendance formatting (structured display)
      if (filters["Name"]) {
        formatted += `<div><b>${name}</b></div>`;
      }

      if (filters["Title"] || filters["Company"]) {
        formatted += `<div>`;
        if (filters["Title"]) formatted += `${title}`;
        if (filters["Title"] && filters["Company"]) formatted += " | ";
        if (filters["Company"]) formatted += `<b>${company}</b>`;
        formatted += `</div>`;
      }

      if (filters["Email"] && email) {
        formatted += `<div><u>${email}</u></div>`;
      }
    } else {
      // Attendee formatting (inline display)
      const attendeeParts = [];

      if (filters["Name"]) attendeeParts.push(`<b>${name}</b>`);

      if (filters["Title"] || filters["Company"]) {
        const titleCompany = [];
        if (filters["Title"]) titleCompany.push(`<i>${title}</i>`);
        if (filters["Company"]) titleCompany.push(company);

        attendeeParts.push(titleCompany.join(" – ")); // Dash only between Title and Company
      }

      formatted = attendeeParts.join(", "); // Comma between Name and (Title – Company)
    }

    return `${formatted}`;
  };

  useEffect(() => {
    generateHtml();
  }, [filters, customLayout]);

  // const generateHtml = () => {
  //   if (!csvData || csvData.length === 0) {
  //     setHtml("<div>No data available</div>");
  //     return;
  //   }

  //   const mode = filters["style"]; // Determines "Attendee" or "Attendance" mode

  //   const formattedRows = csvData
  //     .map((row) => {
  //       const name = row["Name"]?.trim() || "";
  //       const title = row["Title"]?.trim() || "";
  //       const company = row["Company"]?.trim() || "";
  //       const email = row["Email"]?.trim() || "";

  //       let formatted = "";

  //       if (mode === "Attendance") {
  //         // Attendance formatting (structured display)
  //         if (filters["Name"]) {
  //           formatted += `<div><b>${name}</b></div>`;
  //         }

  //         if (filters["Title"] || filters["Company"]) {
  //           formatted += `<div>`;
  //           if (filters["Title"]) formatted += `${title}`;
  //           if (filters["Title"] && filters["Company"]) formatted += " | ";
  //           if (filters["Company"]) formatted += `<b>${company}</b>`;
  //           formatted += `</div>`;
  //         }

  //         if (filters["Email"] && email) {
  //           formatted += `<div><u>${email}</u></div>`;
  //         }
  //       } else {
  //         // Attendee formatting (inline display)
  //         const attendeeParts = [];

  //         if (filters["Name"]) attendeeParts.push(`<b>${name}</b>`);

  //         if (filters["Title"] || filters["Company"]) {
  //           const titleCompany = [];
  //           if (filters["Title"]) titleCompany.push(`<i>${title}</i>`);
  //           if (filters["Company"]) titleCompany.push(company);

  //           attendeeParts.push(titleCompany.join(" – ")); // Dash only between Title and Company
  //         }

  //         formatted = attendeeParts.join(", "); // Comma between Name and (Title – Company)
  //       }

  //       return `<div>${formatted}</div>`;
  //     })
  //     .join("\n"); // Ensures a new line after each row, except the last one

  //   setHtml(formattedRows);
  //   console.log("setHtml");
  // };

  // // Ensure generateHtml is called whenever filters change
  // useEffect(() => {
  //   generateHtml();
  // }, [filters]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setCsvFileName(file.name);

    Papa.parse(file, {
      complete: (result) => {
        // Normalize headers to always match allowedHeaders
        if (result.meta && result.meta.fields) {
          const csvHeaders = result.meta.fields.map((h) =>
            h.trim().toLowerCase()
          );

          // Create a mapping from lowercase header names to original headers
          const headerMap = {};
          allowedHeaders.forEach((header) => {
            const lowerHeader = header.toLowerCase();
            const matchingCsvHeader = csvHeaders.find((h) => h === lowerHeader);
            if (matchingCsvHeader) {
              headerMap[matchingCsvHeader] = header; // Map lowercase to standard name
            }
          });

          // Detect the case-insensitive "Name" column key
          const nameColumnKey = Object.keys(headerMap).find(
            (key) => key.toLowerCase() === "name"
          );

          // Process data rows with normalized headers
          const cleanedData = result.data
            .map((row) => {
              const normalizedRow = {};
              Object.keys(row).forEach((key) => {
                const normalizedKey =
                  headerMap[key.toLowerCase().trim()] || key.trim(); // Use mapped key if available
                normalizedRow[normalizedKey] = row[key]?.trim() || ""; // Trim whitespace
              });

              return Object.values(normalizedRow).some((val) => val !== "")
                ? normalizedRow
                : null;
            })
            .filter(Boolean); // Remove empty rows

          // Sort cleanedData alphabetically by detected "Name" column (case-insensitive)
          if (cleanedData.length > 0 && nameColumnKey) {
            cleanedData.sort((a, b) =>
              (a[nameColumnKey] || "").localeCompare(
                b[nameColumnKey] || "",
                undefined,
                { sensitivity: "base" }
              )
            );
          }

          setCsvData(cleanedData);
          console.log("csvData", cleanedData);

          // Extract headers that match allowedHeaders
          const matchingHeaders = allowedHeaders.filter((h) =>
            csvHeaders.includes(h.toLowerCase())
          );
          setHeaders(matchingHeaders);

          // Initialize filters (all checked by default)
          const initialFilters = {};
          matchingHeaders.forEach((header) => {
            initialFilters[header] = true;
          });
          setFilters(initialFilters);
        }
      },

      header: true,
      skipEmptyLines: true,
    });
  };

  const handleChecked = (event, header) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [header]: event.target.checked,
    }));
  };

  const outputCopyText = () => {
    if (outputRef.current) {
      const htmlContent = outputRef.current.innerHTML; // Get styled HTML content

      // Create a temporary element to hold the formatted content
      const blob = new Blob([htmlContent], { type: "text/html" });
      const data = [new ClipboardItem({ "text/html": blob })];

      navigator.clipboard
        .write(data)
        .then(() => {
          toast.success("Copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy content:", err);
          toast.error("Failed to copy content");
        });
    }
  };

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="logo-container">
            <img src={DeDuperImg} className="img-fluid" />
          </div>
          <div className="row">
            <>
              <div className="dedupe-form-wrapper">
                <div className="form-section">
                  {/* <div className="events-owners-subheading">
                    Upload your CSV file
                  </div> */}
                  <div className="events-owners-subheading">
                    Upload a CSV file which includes the columns:
                    <br />
                    <i>Name, Title, Company, Email</i>
                  </div>
                  <button
                    className={`upload-csv-btn btn btn-sm btn-primary`}
                    onClick={() =>
                      document.getElementById("csv-file-input").click()
                    }
                  >
                    {`Upload CSV`}
                    <i className="event-owner-add fa-sharp fa-solid fa-upload ml-05"></i>
                  </button>
                  <input
                    type="file"
                    accept=".csv"
                    id="csv-file-input"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                  {csvData && csvData.length > 0 ? (
                    <div>
                      <div className="file-name">{csvFileName}</div>
                      <div className="file-name">
                        Rows: {addCommas(csvData.length)}
                      </div>
                      {/* <div className="file-name sm">{`${JSON.stringify(csvData)
                        .replace(/[\[\]"]/g, "")
                        .substring(0, 120)}...`}</div> */}
                    </div>
                  ) : null}
                </div>

                {csvData.length > 0 && (
                  <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                    <div className="form-section">
                      <div className="events-owners-subheading">
                        Formatting Options
                      </div>
                      <div className="filters-row">
                        <FilterOption
                          filterKey="style"
                          title="Style"
                          optionsEnum={{
                            attendee: "Attendee",
                            attendance: "Attendance",
                          }}
                          handleSetFilter={(key, value) =>
                            setFilters((prev) => ({ ...prev, [key]: value }))
                          }
                          filters={filters}
                        />
                      </div>
                      <div
                        className={`filters-row ${
                          filters.customFormat ? `disabled` : ``
                        }`}
                      >
                        {/* Dynamically generate checkboxes for each matched header */}
                        {headers.map((header) => (
                          <FilterCheckbox
                            key={header}
                            title={header}
                            checked={filters[header]}
                            handleChecked={(event) =>
                              handleChecked(event, header)
                            }
                          />
                        ))}
                      </div>
                      <div className="filters-row">
                        <div className="form-check form-switch custom-formatting-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="filterBypassSwitch"
                            checked={filters.customFormat}
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              setFilters((prev) => ({
                                ...prev,
                                customFormat: isChecked,
                              }));
                            }}
                          />

                          <label
                            className="custom-formatting-label"
                            for="filterBypassSwitch"
                          >
                            Custom Formatting
                          </label>
                        </div>
                        {/* <FilterCheckbox
                          title="Custom"
                          checked={filters.customFormat}
                          handleChecked={(event) =>
                            setFilters((prev) => ({
                              ...prev,
                              customFormat: event.target.checked,
                            }))
                          }
                        />{" "} */}
                        {filters.customFormat && (
                          <>
                            <div className="example-text">
                              Styling options are specified in brackets after
                              the field. Example:
                              <br />
                              (b) to <b>bold</b>
                              <br />
                              (i) to <i>italicize</i>
                              <br />
                              (u) to <u>underline</u>
                              <br />
                              (b,i,u) to{" "}
                              <b>
                                <i>
                                  <u>bold, italicize, and underline</u>
                                </i>
                              </b>
                            </div>
                            <textarea
                              value={customLayout}
                              onChange={(e) => setCustomLayout(e.target.value)}
                              className="form-control mt-2 custom-formatting-textarea"
                              rows="4"
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </motion.div>
                )}
              </div>
            </>
          </div>
        </Card>
        {csvData.length > 0 && (
          <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
            <div className="result-display">
              <div className="row">
                <div className="col-6">
                  <div className="subtitle">HTML</div>
                  <div className="template-form">
                    <div className="row">
                      <div className="form-group mb-3 col-12">
                        <ErrorBoundary>
                          <Editor
                            value={html}
                            onValueChange={(code) => setHtml(code)}
                            highlight={(code) => {
                              try {
                                return highlight(code, languages.markup);
                              } catch (error) {
                                console.error("Highlighting error:", error);
                                return code; // Return unformatted code as a fallback
                              }
                            }}
                            padding={15}
                            className="code-editor"
                            style={{
                              color: "#000",
                              fontSize: 11,
                              backgroundColor: "#fff",
                              border: "1px #0072ff solid",
                              borderRadius: "0.625rem",
                              fontFamily:
                                "ui-monospace,Roboto Mono,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                            }}
                          />
                        </ErrorBoundary>
                      </div>{" "}
                      {/* <-- Moved this closing div here to properly close form-group */}
                    </div>{" "}
                    {/* <-- Closes .row inside .template-form */}
                  </div>{" "}
                  {/* <-- Closes .template-form */}
                </div>{" "}
                {/* <-- Closes .col-6 (Edit Template) */}
                <div className="col-6">
                  <div className="subtitle">Output</div>
                  <div className="viewer">
                    <div className="body" ref={outputRef}>
                      {ReactHtmlParser(html)}
                    </div>
                    <div className="clipboard" onClick={outputCopyText}>
                      <i class="fa-regular fa-clipboard"></i>
                    </div>
                  </div>
                </div>{" "}
                {/* <-- Closes .col-6 (Viewer) */}
              </div>{" "}
              {/* <-- Closes .row inside .result-display */}
            </div>{" "}
            {/* <-- Closes .result-display */}
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  useDocumentTitle("QuickCSV - Landmark Ventures");

  return (
    <Fragment>
      <div className="global-dash-wrapper dedupe quickcsv">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            { title: "QuickCSV", url: `/landmark/quickcsv` },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated ? (
          <>
            <DeDuperForm password={password} />
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access tool", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
